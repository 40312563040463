import axios from 'axios';
// import router from '@/router/router.js';
// import Swal from "sweetalert2";
const apiUrl = 'https://api.nolimitadsmanager.com/api';
// const apiUrl = 'https://localhost:7001/api';
// function decodeJwt(token) {
//   let base64Url = token.split('.')[1];
//   let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
//   let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
//     return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
//   }).join(''));

//   return JSON.parse(jsonPayload);
// }
let currentRoute = '';
// async function checkToken() {
//   try {
//     // Lấy token từ local storage
//     let token = window.localStorage.getItem('id-token');

//     // Kiểm tra nếu token không tồn tại
//     if (!token) {
//       console.error('Token not found');
//       return false;
//     }

//     // Gửi yêu cầu kiểm tra token đến server
//     const response = await axios.get('https://localhost:7001/api/User/UserDetails', {
//       headers: { Authorization: token }
//     });

//     // Lấy token từ phản hồi của server
//     const serverToken = response.data.data.token;

//     // So sánh token từ local storage và token từ server
//     if (token !== serverToken) {
//       return true;
//     }
//     // Tokens khớp nhau
    
//   } catch (error) {
//     // Xử lý lỗi nếu có
//     return false;
//   }
// }
export default {
  
  async checkTokenValid() {
    // currentRoute = '';
    // let token = window.localStorage.getItem('id-token');
    // if (token) {
    //   // Decode token để lấy thông tin về thời gian hết hạn
    // let decodedToken = await decodeJwt(token);
    // let expirationTime = decodedToken.exp * 1000; // Convert seconds to milliseconds
    //   // Token tồn tại, cho phép truy cập trang
    //   if (expirationTime > Date.now()) {
    // const tokenIsValid = await checkToken();
    // if(tokenIsValid){
    //   currentRoute = 'SignIn';
    //   router.push({ name: 'SignIn'}).then(()=>{
    //     Swal.fire({
    //       text: "Tài khoản đã bị đăng nhập ở nơi khác", 
    //       icon: "error",
    //       buttonsStyling: false,
    //       confirmButtonText: "Try again!",
    //       heightAuto: false,
    //       customClass: {
    //       confirmButton: "btn fw-semobold btn-light-danger",
    //       },
    //     })
    //   });
    // }
    //      else{
    //         await this.verifyToken().then((res)=>window.localStorage.setItem('id-token',res.data.data.token)).catch((res)=>{
              
    //           currentRoute = 'SignIn';
    //           router.push({ name: 'SignIn'});
    //           Swal.fire({
    //             text: res.response.data.message, 
    //             icon: "error",
    //             buttonsStyling: false,
    //             confirmButtonText: "Thử lại!",
    //             heightAuto: false,
    //             customClass: {
    //             confirmButton: "btn fw-semobold btn-light-danger",
    //             },
    //           })
    //           // Token đã hết hạn, chuyển hướng đến trang SignIn
             
    //          })
    //       }
    //   }else {
    //     // Token đã hết hạn, chuyển hướng đến trang SignIn
       
    //     currentRoute = 'SignIn';
    //     router.push({ name: 'SignIn'});
    //   } 
    // }else {
    //   // Token không tồn tại, chuyển hướng đến trang SignIn
    //   currentRoute = 'SignIn';
    //   router.push({ name: 'SignIn'});
    // }  
    
  },
/**
 * API đăng nhập
 */
 async signIn(user) {
    var res = await axios.post(`${apiUrl}/User/SignIn`,user)
    return res;
  },
/**
 * API đăng ký
 */
async signUp(user) {
    var res = await axios.post(`${apiUrl}/User/SignUp`,user)
    return res;
  },
  /**
 * API lấy thông tin user cá nhân
 */
async getUserProfile() {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/User/UserDetails`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
  /**
 * API lấy thông tin user cá nhân
 */
  async verifyToken() {
    var res = await axios.get(`${apiUrl}/User/verify_token`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

/**
 * API lấy thông tin biến động tài khoản cá nhân
 */
  async getBalanceHistoryById(id) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/BalanceHistories/GetByUserId/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },


  /**
 * API lấy tổng nạp của user theo thời gian
 */
  async getRechargeByDate(id,start,end) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/BalanceHistories/GetRechargeByDate?userId=${id}&start=${start}&end=${end}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },


  /**
 * API lấy thông tin biến động tài khoản cá nhân phan trang
 */
  async getBalanceHistoryByIdPaging(pageNumber,pageSize,id) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/BalanceHistories/GetPageListByUserId/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

    /**
 * API lấy thông tin biến động tài khoản cá nhân phan trang search
 */
    async getBalanceHistoryByIdPagingSearch(pageNumber,pageSize,id,keyword) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/BalanceHistories/GetPageListByUserId/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

 /**
 * API get all balancehistory sort desc theo user
 */
 async getBalanceHistorySortDescByUser(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/BalanceHistories/SortDesc?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API get all balancehistory sort asc theo user
 */
 async getBalanceHistorySortAscByUser(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/BalanceHistories/SortAsc?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},



 /**
 * API lấy thông tin biến động tài khoản cá nhân phan trang
 */
 async getBalanceHistoryByIdPagingByAdmin(pageNumber,pageSize) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/BalanceHistories/GetPageListByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

  /**
* API lấy thông tin biến động tài khoản cá nhân phan trang search
*/
  async getBalanceHistoryByIdPagingSearchByAdmin(pageNumber,pageSize,keyword) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/BalanceHistories/GetPageListByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

/**
* API get all balancehistory sort desc theo user
*/
async getBalanceHistorySortDescByAdmin(pageNumber,pageSize,property) {
await this.checkTokenValid();
var res = await axios.get(`${apiUrl}/BalanceHistories/SortDescByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
return res;
},

/**
* API get all balancehistory sort asc theo user
*/
async getBalanceHistorySortAscByAdmin(pageNumber,pageSize,property) {
await this.checkTokenValid();
var res = await axios.get(`${apiUrl}/BalanceHistories/SortAscByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
return res;
},


          /**
 * API get all ads by user filter
 */
async getAllBalanceHistoriesByAdminFilter(pageNumber,pageSize,status,dateStart,dateEnd,keyword,property,modeSort) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/BalanceHistories/FilterBalanceHistoryAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&start=${dateStart}&end=${dateEnd}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
/**
 * API get all ads by user filter status
 */
async getAllBalanceHistoriesByAdminFilterStatus(pageNumber,pageSize,status,keyword,property,modeSort) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/BalanceHistories/FilterBalanceHistoryAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

  /**
 * API lấy thông tin tất cả biến động tài khoản 
 */
  async getAllBalanceHistory() {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/BalanceHistories/GetAll`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

  /**
 * API thêm biến động tài khoản cá nhân
 */
  async addBalanceHistoryById(balanceHistory) {
    await this.checkTokenValid();
    var res = await axios.post(`${apiUrl}/BalanceHistories/AddBalanceHistoryForUserId`,balanceHistory,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

    /**
 * API thêm biến động tài khoản cá nhân
 */
    async changeBalanceHistoryById(balanceHistory) {
      await this.checkTokenValid();
      var res = await axios.post(`${apiUrl}/BalanceHistories/ChangeBalanceHistoryForUserId`,balanceHistory,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },
  /**
 * API cập nhật thông tin cá nhân của người dùng
 */
  async updateUserProfile(user) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/User/Update`,user,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },
    /**
 * API admin cập nhật thông tin cá nhân của người dùng
 */
    async adminUpdateUserProfile(id,user) {
      await this.checkTokenValid();
      var res = await axios.put(`${apiUrl}/User/Admin/Update/${id}`,user,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },
  /**
 * API lấy danh sách người dùng
 */
    async getAllUser() {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/User/GetAll`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },
      /**
       *  /**
 * API lấy danh sách người dùng phân trang
 */
    async getAllUserPanigation(pageNumber,pageSize) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/User?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },


    
/**
 *  /**
 * API lấy danh sách người dùng phân trang tìm kiếm
 */
async getAllUserPanigationSearch(pageNumber,pageSize,keyword) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/User?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},    


 /**
* API lấy danh sách người dùng 
*/
   async getAllUserNoPaging() {
     await this.checkTokenValid();
     var res = await axios.get(`${apiUrl}/User/GetUserNoPaging`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
     return res;
   },


     /**
   * API lấy danh sách người dùng phân trang
   */
      async getAllUserNoPagingSearch(keyword) {
        await this.checkTokenValid();
        var res = await axios.get(`${apiUrl}/User/GetUserNoPaging?keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
        return res;
      },
/**
 * API lấy thông tin user cá nhân theo id
 */
async getUserById(id) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/User/Admin/UserDetails/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

  /**
 * API lấy danh sách người dùng sắp xếp asc
 */
  async getUsersSortAsc(pageNumber,pageSize,property) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/User/SortAsc?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },  
  
    /**
   * API chuyển tiền cho người dùng khác
   */
  async transferMoneyUser(info) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/User/Transfer`,info,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  }, 

/**
   * API cộng tiền cho user by admin
   */
  async plusMoneyUser(id,amount) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/User/Admin/PlusMoneyByUserId?userId=${id}&amount=${amount}`,{headers:{Authorization: window.localStorage.getItem("id-token")}})
    return res;
  }, 
/**
   * API trừ tiền cho user by admin
   */
  async minusMoneyUser(id,amount) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/User/Admin/MinusMoneyByUserId?userId=${id}&amount=${amount}`,{headers:{Authorization: window.localStorage.getItem("id-token")}})
    return res;
  }, 


    /**
   * API chuyển tiền cho user
   */
    async getUsersSortDesc(pageNumber,pageSize,property) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/User/SortDesc?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    }, 

  /**
 * API thay đổi quyền kế toán
 */
async changeRoleAccountant(userId,roleAccountant) {
  await this.checkTokenValid();
  var res = await axios.put(`${apiUrl}/User/UpdateIsAccountant?userId=${userId}&isAccountant=${roleAccountant}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
  /**
 * API thay đổi quyền affiliate
 */
  async changeRoleAffiliate(userId,roleAffiliate) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/User/UpdateIsAffiliate?userId=${userId}&isAffiliate=${roleAffiliate}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

      /**
 * API thay đổi quyền support
 */
      async changeRoleSupport(userId,roleSupport) {
        await this.checkTokenValid();
        var res = await axios.put(`${apiUrl}/User/UpdateIsSupport?userId=${userId}&isSupport=${roleSupport}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
        return res;
      },
/// *** Deposit
/**
 * API lấy danh sách giao dịch bởi người dùng
 */
async getAllDepositHistory(id,pageNumber,pageSize) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/DepositHistory?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
 * API lấy danh sách giao dịch bởi người dùng và search
 */
async getAllDepositHistorySearch(id,pageNumber,pageSize,keyword) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/DepositHistory?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
 * API tất cả danh sách giao dịch không phân trang
 */
async getAllDepositHistoryNoPaging() {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/DepositHistory/GetAllDeposit`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
 * API tất cả danh sách giao dịch theo user không phân trang
 */
async getAllDepositHistoryByUserNoPaging(userId) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/DepositHistory/GetAllDepositByUser?userId=${userId}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
 * API lấy danh sách giao dịch bởi admin
 */
async getAllDepositHistoryByAdmin(pageNumber,pageSize) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/DepositHistory/GetAllByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
/**
 * API lấy danh sách giao dịch bởi admin
 */
async getAllDepositHistoryByAdminSearch(pageNumber,pageSize,keyword) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/DepositHistory/GetAllByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

  /**
 * API get all deposit by user filter
 */
  async getAllDepositHistoryByUserFilter(pageNumber,pageSize,status,dateStart,dateEnd,idUser,keyword,property,modeSort) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/DepositHistory/FilterDepositAllPagedListByUser?userId=${idUser}&pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&start=${dateStart}&end=${dateEnd}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
},
  /**
* API get all deposit by user filter status
*/
  async getAllDepositHistoryByUserFilterStatus(pageNumber,pageSize,status,idUser,keyword,property,modeSort) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/DepositHistory/FilterDepositAllPagedListByUser?userId=${idUser}&pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
},
  /**
* API get all deposit by user filter
*/
  async getAllDepositHistoryByAdminFilter(pageNumber,pageSize,status,dateStart,dateEnd,keyword,property,modeSort) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/DepositHistory/FilterDepositAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&start=${dateStart}&end=${dateEnd}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
},
  /**
* API get all deposit by user filter status
*/
  async getAllDepositHistoryByAdminFilterStatus(pageNumber,pageSize,status,keyword,property,modeSort) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/DepositHistory/FilterDepositAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
},

  /**
 * API  lấy thông tin depositHistory theo id
 */
  async getDepositHistoryById(id) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/DepositHistory/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },
  /**
 * API  tạo hóa đơn depositHistory
 */
  async addDepositHistory(deposit) {
    await this.checkTokenValid();
    var res = await axios.post(`${apiUrl}/DepositHistory`,deposit,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

   /**
 * API cập nhật thông tin depositHistory theo id
 */
   async updateDepositHistory(depositHistory) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/DepositHistory/Update`,depositHistory,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

 /**
 * API get all deposit sort desc theo user
 */
 async getDepositSortDescByUser(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/DepositHistory/SortDesc?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
* API get all deposit sort desc theo admin
*/
async getDepositSortDescByAdmin(pageNumber,pageSize,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/DepositHistory/SortDescByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
* API get all deposit sort asc theo user
*/
async getDepositSortAscByUser(pageNumber,pageSize,id,property) {
await this.checkTokenValid();
var res = await axios.get(`${apiUrl}/DepositHistory/SortAsc?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
return res;
},  

/**
* API get all deposit sort asc theo admin
*/
async getDepositSortAscByAdmin(pageNumber,pageSize,property) {
await this.checkTokenValid();
var res = await axios.get(`${apiUrl}/DepositHistory/SortAscByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
return res;
},



  /// *** Ads

    /**
 * API update status ads by admin
 */
    async updateStatusAdsByAdsId(adsId,status) {
      await this.checkTokenValid();
      var res = await axios.put(`${apiUrl}/Ads/UpdateStatusAd?adsId=${adsId}&status=${status}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

/**
 * API update status nhiều ads by admin
 */
async updateStatusManyAdsByAdsId(adsId,status) {
  await this.checkTokenValid();
  var res = await axios.put(`${apiUrl}/Ads/UpdateStatusManyAd?status=${status}`,adsId,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

  /**
 * API get all ads by admin
 */
  async getAllAdsByAdmin(pageNumber,pageSize) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/Ads/GetAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },
  /**
 * API lấy thông tin ads theo id
 */
  async getAdsById(id) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/Ads/GetAdById/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },
    /**
 * API lấy thông tin ads theo id
 */
    async GetAdByIdByUser(id) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/GetAdByIdByUser/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },


  /**
 * API get all ads by user filter status ads
 */
  async getAllAdsByUserFilterStatusAds(pageNumber,pageSize,id,statusAds) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/Ads/FilterAdsStatusPagedList?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&statusAds=${statusAds}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
},  

  /**
 * API get all ads by user filter status ads
 */
  async getAllAdsByUserFilterStatusAdsNoPaging(id,statusAds) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/Ads/FilterAdsStatusNoPagingByUser/${id}?statusAds=${statusAds}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
}, 

/**
 * API get all ads by admin filter status ads
 */
async getAllAdsByAdminFilterStatusAds(pageNumber,pageSize,statusAds) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Ads/FilterAdsStatusPagedListByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&statusAds=${statusAds}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  

/**
* API get all ads by admin filter status ads
*/
async getAllAdsByAdminFilterStatusAdsNoPaging(statusAds) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Ads/FilterAdsStatusNoPagingByAdmin?statusAds=${statusAds}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
}, 

    /**
 * API get all ads by user filter
 */
    async getAllAdsByUserFilter(pageNumber,pageSize,id,status,dateStart,dateEnd,keyword,property,modeSort) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/FilterAdsPagedList?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&start=${dateStart}&end=${dateEnd}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },

    /**
 * API get all ads by user filter
 */
    async getAllAdsByUserFilterNoPaging(id,status,dateStart,dateEnd,keyword) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/FilterAdsNoPaging?userId=${id}&status=${status}&start=${dateStart}&end=${dateEnd}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },
    /**
 * API get all ads by user filter status
 */
    async getAllAdsByUserFilterStatus(pageNumber,pageSize,id,status,keyword,property,modeSort) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/FilterAdsPagedList?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },

   /**
 * API get all ads by user filter status
 */
   async getAllAdsByUserFilterStatusNoPaging(id,status,keyword) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/Ads/FilterAdsNoPaging?userId=${id}&status=${status}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
},
      /**
 * API get all ads by admin filter
 */
    async getAllAdsByAdminFilter(pageNumber,pageSize,status,dateStart,dateEnd,keyword,property,modeSort) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/FilterAdsAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&start=${dateStart}&end=${dateEnd}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },

   /**
 * API get all ads by admin filter
 */
   async getAllAdsByAdminFilterNoPaging(status,dateStart,dateEnd,keyword) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/Ads/FilterAdsAllNoPaging?status=${status}&start=${dateStart}&end=${dateEnd}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
},
  /**
 * API get all ads by admin filter status
 */
    async getAllAdsByAdminFilterStatus(pageNumber,pageSize,status,keyword,property,modeSort) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/FilterAdsAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },
  /**
 * API get all ads by admin filter status
 */
  async getAllAdsByAdminFilterStatusNoPaging(status,keyword) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/Ads/FilterAdsAllNoPaging?status=${status}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },


  
  /**
 * API lấy thông tin ads theo id
 */
    async getAllAdsByUserId(id) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/GetAdsByUserId/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },
  /**
 * API lấy thông tin ads theo id
 */
  async getAllAdsByUserIdSearchNoPaging(id,keyword) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/Ads/GetAdsByUserId/${id}?keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    if(currentRoute == 'SignIn'){
      window.localStorage.removeItem('id-token')
    }
    return res;
  },
  /**
 * API lấy thông tin ads theo id
 */
    async getAllAdsByUserNoPaging(id) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/GetAllByUserNoPaging?userId=${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

    /**
 * API lấy thông tin ads theo id
 */
    async getAllAdsByAdminNoPaging() {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/GetAllByAdminNoPaging`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

       /**
 * API lấy thông tin ads theo id
 */
       async getAdByUserIdAndFacebookAdsId(facebookAdsId) {
        await this.checkTokenValid();
        var res = await axios.get(`${apiUrl}/Ads/GetAdByUserIdAndFacebookAdsId/${facebookAdsId}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
        return res;
      },

        /**
 * API lấy thông tin ads theo id
 */
    async getAllAdsByAdminSearchNoPaging(keyword) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/Search?keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },
  /**
 * API cập nhật thông tin tài khoản ads
 */
  async updateAds(ad) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/Ads/Update`,ad,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

   /**
 * API cập nhật thông tin tài khoản ads customer
 */
   async updateAdsCustomer(adId,customer) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/Ads/UpdateCustomer?adsId=${adId}&customer=${customer}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

     /**
 * API cập nhật thông tin tài khoản ads topupFee
 */
    async updateAdsTopupFee(adId,topupFee) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/Ads/UpdateTopupFee?adsId=${adId}&topupFee=${topupFee}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
    },

         /**
 * API cập nhật thông tin tài khoản ads topupFee
 */
    async updateBatchAdsTopupFee(listAdId,topupFee) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/Ads/UpdateBatchTopupFee?topupFee=${topupFee}`,listAdId,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

   /**
 * API cập nhật thông tin nhiều tài khoản ads customer
 */
   async updateBatchAdsCustomer(listAds,customer) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/Ads/UpdateAllCustomer?customer=${customer}`,listAds,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

   /**
 * API cấp tài khoản ads
 */
   async provideAccountAds(ad) {
    await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/Ads/ProvideAccount`,ad,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },
     /**
 * API cấp user cho nhiều tài khoản ads 
 */
     async provideAccountManyAds(listAdsId,idUser) {
      await this.checkTokenValid();
      var res = await axios.put(`${apiUrl}/Ads/ProvideAllAccount?userIdProvide=${idUser}`,listAdsId,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

         /**
 * API thu hồi nhiều tài khoản ads
 */
    async returnManyAds(listAdsId) {
      await this.checkTokenValid();
    var res = await axios.put(`${apiUrl}/Ads/ReturnAllAccount`,listAdsId,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },
    /**
 * API thêm tài khoản ads
 */
    async addAds(ad) {
      await this.checkTokenValid();
      var res = await axios.post(`${apiUrl}/Ads`,ad,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },
        /**
 * API thêm tài khoản ads
 */
    async importManyAds(ad) {
      await this.checkTokenValid();
      var res = await axios.post(`${apiUrl}/Ads/ImportCSV`,ad,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

            /**
 * API thêm tài khoản ads
 */
    async checkDuplicateAdsId(ad) {
      await this.checkTokenValid();
      var res = await axios.post(`${apiUrl}/Ads/CheckDuplicateAdsId`,ad,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },
   /**
 * API tìm kiếm ads theo facebookAdsId,name,shareStatus
 */
   async getAdsSearch(pageNumber,pageSize,keyword) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/Ads/GetAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },
    /**
 * API get all ads by user
 */
  async getAllAdsByUser(pageNumber,pageSize,id) {
    await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/GetAdsByUserIdPagedList?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },
  /**
 * API get all search ads by user
 */
     async getAllAdsSearchByUser(pageNumber,pageSize,id,keyword) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/GetAdsByUserIdPagedList?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      if(currentRoute == 'SignIn'){
        window.localStorage.removeItem('id-token')
      }
      return res;
  },

    /**
 * API get all sort desc theo user
 */
    async getAllSortDescByUser(pageNumber,pageSize,id,property) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/SortDesc?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },

    /**
 * API get all sort desc theo admin
 */
    async getAllSortDescByAdmin(pageNumber,pageSize,property) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/Ads/SortDescByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },

   /**
 * API get all sort asc theo user
 */
   async getAllSortAscByUser(pageNumber,pageSize,id,property) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/Ads/SortAsc?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
},  
  
 /**
 * API get all sort asc theo admin
 */
 async getAllSortAscByAdmin(pageNumber,pageSize,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Ads/SortAscByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
  /// *** TopUpOrder
  /**
 * API thêm topupOrder
 */
    async addTopUpOrder(topUpOrder) {
      await this.checkTokenValid();
      var res = await axios.post(`${apiUrl}/TopUpOrder`,topUpOrder,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },


     /**
 * API sửa topupOrder
 */
     async updateTopUpOrder(topUpOrder,id) {
      await this.checkTokenValid();
      var res = await axios.put(`${apiUrl}/TopUpOrder/UpdateTopupOrder/${id}`,topUpOrder,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    }, 

    


         /**
 * API sửa topupOrder
 */
      async deleteTopUpOrder(id) {
      await this.checkTokenValid();
      var res = await axios.delete(`${apiUrl}/TopUpOrder/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    }, 

       /**
 * API thêm nhiều topup
 */
       async importManyTopup(topup) {
        await this.checkTokenValid();
        var res = await axios.post(`${apiUrl}/TopUpOrder/AddTopupBatch`,topup,{headers:{Authorization: window.localStorage.getItem("id-token")} })
        return res;
      },

  /**
 * API thêm topupOrder custom
 */
  async addTopUpOrderCustom(topUpOrder) {
    await this.checkTokenValid();
    var res = await axios.post(`${apiUrl}/TopUpOrder/AddCustomTopUpOrders`,topUpOrder,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

      /**
 * API thêm nhiều topupOrder cùng 1 lúc
 */
      async addManyTopUpOrder(listAds,amount) {
        await this.checkTokenValid();
        var res = await axios.post(`${apiUrl}/TopUpOrder/AddBatchTopUpOrders?amount=${amount}`,listAds,{headers:{Authorization: window.localStorage.getItem("id-token")} })
        return res;
      },
         /**
 * API thêm nhiều topupOrder cùng 1 lúc theo file
 */
      async addBatchTopUpOrdersFile(listTopup,totalAmount) {
      await this.checkTokenValid();
      var res = await axios.post(`${apiUrl}/TopUpOrder/AddBatchTopUpOrdersFile?totalAmount=${totalAmount}`,listTopup,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },
  /**
 * API lấy danh sách TopUpOrder theo người dùng
 */
async getAllTopUpOrder(pageNumber,pageSize,id) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},    
  /**
 * API lấy danh sách TopUpOrder theo người dùng và search
 */
  async getAllTopUpOrderSearch(pageNumber,pageSize,id,keyword) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/TopUpOrder?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },   
 /**
 * API lấy tất cả danh sách TopUpOrder không phân trang
 */
 async getAllTopUpOrderNoPagingByAdmin() {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/GetAllNoPaging`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},    

  /**
 * API lấy danh sách TopUpOrder theo ngày 
 */
  async getAllTopUpOrderByDate(id,date) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/TopUpOrder/GetAllTopUpByUserIdAndDate?userId=${id}&date=${date}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  }, 
  /**
 * API lấy danh sách TopUpOrder theo người dùng không paging
 */
  async getAllTopUpOrderNoPaging(id) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/TopUpOrder/GetAllTopUpByUserId?userId=${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },    
  /**
 * API lấy danh sách TopUpOrder theo admin
 */
  async getAllTopUpOrderByAdmin(pageNumber,pageSize) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/TopUpOrder/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },  



    /**
 * API lấy danh sách TopUpOrder theo admin và tìm kiếm theo code
 */
    async getAllTopUpOrderByAdminSearch(pageNumber,pageSize,keyword) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/TopUpOrder/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    }, 
    

    /**
 * API get all ads by user filter
 */
    async getAllTopUpOrderByUserFilter(pageNumber,pageSize,status,dateStart,dateEnd,idUser,keyword,property,modeSort) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/TopUpOrder/FilterTopUpPagedListByUser?userId=${idUser}&pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&start=${dateStart}&end=${dateEnd}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },

  
    /**
 * API get all ads by user filter status
 */
    async getAllTopUpOrderByUserFilterStatus(pageNumber,pageSize,status,idUser,keyword,property,modeSort) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/TopUpOrder/FilterTopUpPagedListByUser?userId=${idUser}&pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },
          /**
 * API get all ads by user filter
 */
    async getAllTopUpOrderByAdminFilter(pageNumber,pageSize,status,dateStart,dateEnd,keyword,property,modeSort) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/TopUpOrder/FilterTopUpAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&start=${dateStart}&end=${dateEnd}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },
    /**
 * API get all ads by user filter status
 */
    async getAllTopUpOrderByAdminFilterStatus(pageNumber,pageSize,status,keyword,property,modeSort) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/TopUpOrder/FilterTopUpAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
  },
 /**
 * API lấy thông tin ads theo id
 */
 async getTopUpOrderById(id) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
 /**
 * API lấy tất cả topUpOrder theo adsId
 */
 async getAllTopUpOrderByAdsId(id,pageNumber,pageSize) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/GetAllTopUpByAdsId?adsId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},


 /**
 * API cập nhật topup
 */
 async updateTopUp(id,pageNumber,pageSize) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/GetAllTopUpByAdsId?adsId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API lấy tất cả topUpOrder theo adsId
 */
 async getAllTopUpOrderByAdsIdNoPaging(id) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/GetAllTopUpByAdsIdNoPaging?adsId=${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API get all ads by adsId filter
 */
 async getAllTopUpOrderByAdsIdFilter(pageNumber,pageSize,status,dateStart,dateEnd,idUser) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/FilterTopUpByAdsId?adsId=${idUser}&pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&start=${dateStart}&end=${dateEnd}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},


/**
* API get all ads by adsId filter status
*/
async getAllTopUpOrderByAdsIdFilterStatus(pageNumber,pageSize,status,idUser) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/FilterTopUpByAdsId?adsId=${idUser}&pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},


 /**
 * API lấy tất cả topUpOrder theo adsId
 */
 async getAllTopUpOrderByAdsIdSearch(id,pageNumber,pageSize,keyword) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/GetAllTopUpByAdsId?adsId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

  /**
 * API lấy danh sách topUpOrder sắp xếp asc
 */
async getAllTopUpSortAsc(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/SortAsc?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  

  /**
 * API lấy danh sách topUpOrder sắp xếp desc 
 */
async getAllTopUpSortDesc(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/SortDesc?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  

  /**
 * API lấy danh sách topUpOrder sắp xếp asc theo admin
 */
  async getAllTopUpSortAscByAdmin(pageNumber,pageSize,property) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/TopUpOrder/SortAscByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },  
  
    /**
   * API lấy danh sách topUpOrder sắp xếp desc theo admin
   */
  async getAllTopUpSortDescByAdmin(pageNumber,pageSize,property) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/TopUpOrder/SortDescByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },  


    /**
 * API lấy danh sách topUpOrder sắp xếp asc theo adId
 */
async getAllTopUpSortAscByAdId(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/SortAscByAdId?adId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  

  /**
 * API lấy danh sách topUpOrder sắp xếp desc theo adId
 */
async getAllTopUpSortDescByAdId(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrder/SortDescByAdId?adId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  

  /// *** TopUpOrderHistory
    /**
 * API lấy danh sách history của topUpOrder
 */
async getAllTopUpOrderHistory(pageNumber,pageSize,id) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/TopUpOrderHistory?topUpOrderId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  

/// *** Setting
 /**
 * API lấy danh sách các account trong setting
 */
async getAllSettingAccount(pageNumber,pageSize) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Setting/GetAll?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  

/**
 * API lấy danh sách trong setting không phân trang
 */
async getAllSettingAccountNoPaging() {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Setting/GetAllNoPaging`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  
/**
 * API thêm tài khoản thanh toán trong setting
 */
async addAccountSetting(account) {
  await this.checkTokenValid();
  var res = await axios.post(`${apiUrl}/Setting`,account,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  
/**
 * API cập nhật active tài khoản thanh toán trong setting
 */
async updateAccountStatusSetting(status,id) {
  await this.checkTokenValid();
  var res = await axios.put(`${apiUrl}/Setting/${id}`,status,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
}, 


/**
 * API cập nhật ưu tiên cho tài khoản thanh toán trong setting
 */
async updateBankPrioritizeSetting(bankPrioritize,id) {
  await this.checkTokenValid();
  var res = await axios.put(`${apiUrl}/Setting/UpdateBankPrioritize/${id}`,bankPrioritize,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
}, 
/**
 * API xóa tài khoản thanh toán trong setting
 */
async deleteAccountSetting(id) {
  await this.checkTokenValid();
  var res = await axios.delete(`${apiUrl}/Setting/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
}, 
/**
 * API lấy setting theo id
 */
async getSettingById(id) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Setting/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
}, 

/**
 * API lấy setting theo id
 */
async getBankNow() {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Setting/GetBankNow`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
}, 
/**
 * API cập nhật tỉ giá USD sang VND 
 */
async updateCurrencyConvert(currency) {
  await this.checkTokenValid();
  var res = await axios.put(`${apiUrl}/Setting/updateCurrencyConvert`,currency,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
}, 
/// *** AdsHistory
/**
 * API lấy adsHistory theo adsId
 */
async getAdsHistoryByAdsId(id,pageNumber,pageSize) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/AdsHistory/GetAdsHistoryByAdId/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
 * API lấy adsHistory theo adsId and search
 */
async getAdsHistoryByAdsIdSearch(id,pageNumber,pageSize,keyword) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/AdsHistory/GetAdsHistoryByAdId/${id}?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
 * API lấy tất cả adsHistory
 */
async getAllAdsHistory(pageNumber,pageSize) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/AdsHistory/GetAllAdsHistory?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
/**
 * API lấy tất cả adsHistory search
 */
async getAllAdsHistorySearch(pageNumber,pageSize,keyword) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/AdsHistory/GetAllAdsHistory?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},


/**
 * API lấy adsHistory theo adsId không phân trang
 */
async getAdsHistoryByAdsIdNoPaging(id) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/AdsHistory/GetAllByAdId/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
 * API thêm adsHistory 
 */
async addAdsHistoryBy(adsHistory) {
  await this.checkTokenValid();
  var res = await axios.post(`${apiUrl}/AdsHistory/AddAdsHistory`,adsHistory,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

  /**
 * API lấy danh sách adsHistory sắp xếp asc
 */
async getAdsHistorySortAsc(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/AdsHistory/SortAsc?adId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  

  /**
 * API lấy danh sách adsHistory sắp xếp desc 
 */
async getAdsHistorySortDesc(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/AdsHistory/SortDesc?adId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  

 /**
 * API lấy danh sách adsHistory sắp xếp asc by admin
 */
 async getAdsHistorySortAscByAdmin(pageNumber,pageSize,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/AdsHistory/SortAscByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  

  /**
 * API lấy danh sách adsHistory sắp xếp desc by admin
 */
async getAdsHistorySortDescByAdmin(pageNumber,pageSize,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/AdsHistory/SortDescByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},  
 /// *** DisplayProperties
 
/**
 * API lấy tất cả DisplayProperties theo userId 
 */
async getDisplayPropertiesByUserId(id) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/DisplayProperties/${id}`)
  return res;
},

/**
 * API thêm DisplayProperties theo userId 
 */
async addDisplayPropertiesByUserId(DisplayProperty) {
  await this.checkTokenValid();
  var res = await axios.post(`${apiUrl}/DisplayProperties`,DisplayProperty)
  return res;
},

/**
 * API cập nhật DisplayProperties theo userId 
 */
async updateDisplayPropertiesByUserId(id,DisplayProperty) {
  await this.checkTokenValid();
  var res = await axios.put(`${apiUrl}/DisplayProperties/${id}`,DisplayProperty)
  return res;
},

/**
 * API cập nhật DisplayProperties theo userId 
 */
async updateDisplayPropertiesUserByUserId(id,DisplayProperty) {
  await this.checkTokenValid();
  var res = await axios.put(`${apiUrl}/DisplayProperties/updateUser/${id}`,DisplayProperty)
  return res;
},

 /// *** Notification

 /**
 * API lấy tất cả các notification 
 */
async getAllNotification(userId) {
  var res = await axios.get(`${apiUrl}/Notification/GetAllNoPaging?userId=${userId}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API lấy tất cả các notification chưa đọc
 */
 async getAllNotificationNotRead(userId) {
  var res = await axios.get(`${apiUrl}/Notification/GetAllNoPagingNotRead?userId=${userId}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
 * API cập nhật notification theo id 
 */
async updateNotificationById(notification) {
  var res = await axios.put(`${apiUrl}/Notification/Update`,notification,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},



/**
 * API thêm notification vào các tài khoản admin
 */
async addNotificationToAdmin(notification) {
  var res = await axios.post(`${apiUrl}/Notification`,notification,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},


 /// *** MediateAdUser

  /**
 * API lấy tất cả các MediateAdUser 
 */
async getAllMediateAdUser() {
  var res = await axios.get(`${apiUrl}/MediateAdUser`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

  /**
 * API lấy tất cả các MediateAdUser theo adsId
 */
  async getAllMediateAdUserByAdsId(adsId) {
    var res = await axios.get(`${apiUrl}/MediateAdUser/${adsId}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

    /**
 * API xóa MediateAdUser theo Id
 */
    async deleteMediateAdUserById(id) {
      var res = await axios.delete(`${apiUrl}/MediateAdUser/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

        /**
 * API cập nhật status MediateAdUser theo Id
 */
    async updateMediateAdUserById(id,status) {
      var res = await axios.put(`${apiUrl}/MediateAdUser/${id}?status=${status}`,status,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

      /**
 * API cập nhật nhiều status MediateAdUser theo Id
 */
      async updateAllMediateAdUserById(id,status) {
        var res = await axios.put(`${apiUrl}/MediateAdUser/updateByAdsId/${id}?status=${status}`,status,{headers:{Authorization: window.localStorage.getItem("id-token")} })
        return res;
      },

      /**
 * API xóa MediateAdUser theo Id
 */
      async deleteAllMediateAdUser(adsId) {
        var res = await axios.delete(`${apiUrl}/MediateAdUser/deleteByAdsId/${adsId}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
        return res;
      },


 /// *** Withdraw

 /**
 * API thêm withdraw của user
 */
async addWithdraw(withdraw) {
  var res = await axios.post(`${apiUrl}/Withdraw`,withdraw,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API lấy tất cả withdraw của user
 */
 async getAllWithdrawByUser(pageNumber,pageSize,userId) {
  var res = await axios.get(`${apiUrl}/Withdraw/GetAllWithdrawsByUser?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API lấy withdraw theo id
 */
 async getWithdrawById(id) {
  var res = await axios.get(`${apiUrl}/Withdraw/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API cập nhật withdraw 
 */
 async updateWithdraw(withdraw) {
  var res = await axios.put(`${apiUrl}/Withdraw/update`,withdraw,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},


 /**
 * API lấy tất cả withdraw của user và search theo fbadsId
 */
 async getAllWithdrawByUserSearch(pageNumber,pageSize,userId,keyword) {
  var res = await axios.get(`${apiUrl}/Withdraw/GetAllWithdrawsByUser?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},


 /**
 * API lấy tất cả withdraw của addmin
 */
 async getAllWithdrawByAdmin(pageNumber,pageSize) {
  var res = await axios.get(`${apiUrl}/Withdraw/GetAllWithdrawsByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},


 /**
 * API lấy tất cả withdraw của admin và search theo userId hoặc fbadsid
 */
 async getAllWithdrawByAdminSearch(pageNumber,pageSize,keyword) {
  var res = await axios.get(`${apiUrl}/Withdraw/GetAllWithdrawsByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API get all withdraw sort desc theo user
 */
 async getWithdrawSortDescByUser(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Withdraw/SortDesc?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API get all withdraw sort asc theo user
 */
 async getWithdrawSortAscByUser(pageNumber,pageSize,id,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Withdraw/SortAsc?userId=${id}&pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

/**
 * API get all withdraw sort desc theo user
 */
async getWithdrawSortDescByAdmin(pageNumber,pageSize,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Withdraw/SortDescByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API get all withdraw sort asc theo user
 */
 async getWithdrawSortAscByAdmin(pageNumber,pageSize,property) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/Withdraw/SortAscByAdmin?pageNumber=${pageNumber}&pageSize=${pageSize}&property=${property}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},



 /// *** MediateAccountantUser
/**
 * API tạo MediateAccountantUser
 */
async createMediateAccountantUser(mediateAccountantUser) {
  await this.checkTokenValid();
  var res = await axios.post(`${apiUrl}/MediateAccountantUser`,mediateAccountantUser,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},



 /**
 * API get all MediateAccountantUser By Accountant
 */
 async getAllMediateAccountantUserByAccountant(pageNumber,pageSize,idAccountant) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/MediateAccountantUser/GetUserByAccountant?accountantId=${idAccountant}&pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},

 /**
 * API get all MediateAccountantUser By Accountant search
 */
 async getAllMediateAccountantUserByAccountantSearch(pageNumber,pageSize,idAccountant,keyword) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/MediateAccountantUser/GetUserByAccountant?accountantId=${idAccountant}&pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},


 /// *** BillLog

  /**
 * API get all BillLog 
 */
  async getAllBillLog(pageNumber,pageSize) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/BillLog/GetAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },
    /**
 * API get all BillLog Search
 */
  async getAllBillLogSearch(pageNumber,pageSize,keyword) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/BillLog/GetAllPagedList?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },
    /**
 * API get BillLog by id
 */
    async getBillLogById(id) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/BillLog/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },





      /**
 * API get all BillLog fIlter
 */
    async getAllBillLogFilter(pageNumber,pageSize,status,statusAccount,start,end,keyword,property,modeSort) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/BillLog/GetAllPagedListFilter?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&statusAccount=${statusAccount}&start=${start}&end=${end}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },
/**
 * API get all BillLog fIlter status
 */
    async getAllBillLogFilterStatus(pageNumber,pageSize,status,statusAccount,keyword,property,modeSort) {
    await this.checkTokenValid();
    var res = await axios.get(`${apiUrl}/BillLog/GetAllPagedListFilter?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&statusAccount=${statusAccount}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

          /**
 * API get all CardManager 
 */
    async getTotalAmountByDate(date,endDate) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/BillLog/GetTotalAmountByDate?selectedDate=${date}&endDate=${endDate}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

          /**
 * API get all CardManager Search
 */ 
    async getTotalAmountByDateSearch(date,endDate,keyword) {
      await this.checkTokenValid();
      var res = await axios.get(`${apiUrl}/BillLog/GetTotalAmountByDate?selectedDate=${date}&endDate=${endDate}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

        /**
 * API update status billLog by admin
 */
        async updateStatusBillLogByBillLogId(billLogId,status) {
          await this.checkTokenValid();
          var res = await axios.put(`${apiUrl}/BillLog/UpdateStatusBillLog?billLogId=${billLogId}&status=${status}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
          return res;
        },

        /**
 * API update status nhiều billLog by admin
 */
async updateStatusManyBillLogByBillLogId(billLogId,status) {
  await this.checkTokenValid();
  var res = await axios.put(`${apiUrl}/BillLog/UpdateStatusManyBillLog?status=${status}`,billLogId,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
   /**
 * API lấy tất cả billlog gần nhất
 */
   async GetAllLastUpdateBillLog() {
    var res = await axios.get(`${apiUrl}/BillLog/GetAllLastUpdate`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },


 /// *** CardInfo

   /**
 * API lấy tất cả cardInfo
 */
   async getAllCardInfo() {
    var res = await axios.get(`${apiUrl}/CardInfo/GetAll`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

          /**
 * API thêm cardInfo
 */
          async importManyCardInfo(cardInfo) {
            await this.checkTokenValid();
            var res = await axios.post(`${apiUrl}/CardInfo/AddBatchCardInfo`,cardInfo,{headers:{Authorization: window.localStorage.getItem("id-token")} })
            return res;
          },

                   /**
 * API xóa card
 */
      async deleteCard(id) {
        await this.checkTokenValid();
        var res = await axios.delete(`${apiUrl}/CardInfo/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
        return res;
      }, 


/// *** BankFeeCard
/**
 * API get all BillLog fIlter 
 */
async getAllBankFeeCardFilter(pageNumber,pageSize,status,start,end,keyword,property,modeSort) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/BankFeeCard/GetAllPagedListFilter?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&start=${start}&end=${end}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
/**
 * API get all BillLog fIlter status
 */
async getAllBankFeeCardFilterStatus(pageNumber,pageSize,status,keyword,property,modeSort) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/BankFeeCard/GetAllPagedListFilter?pageNumber=${pageNumber}&pageSize=${pageSize}&status=${status}&keyword=${keyword}&property=${property}&modeSort=${modeSort}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
 /**
 * API lấy thông tin bankFeeCard theo id
 */
 async getBankFeeCardById(id) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/BankFeeCard/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
 /**
 * API cập nhật thông tin bankFeeCard
 */
 async updateBankFeeCard(bankFeeCard) {
  await this.checkTokenValid();
  var res = await axios.put(`${apiUrl}/BankFeeCard/Update`,bankFeeCard,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
  /**
 * API thêm bankFeeCard custom
 */
  async addBankFeeCardCustom(bankFeeCard) {
    await this.checkTokenValid();
    var res = await axios.post(`${apiUrl}/BankFeeCard/AddCustom`,bankFeeCard,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },
   /**
 * API thêm nhiều bankFeeCard
 */
   async importManyBankFeeCard(bankFeeCard) {
    await this.checkTokenValid();
    var res = await axios.post(`${apiUrl}/BankFeeCard/AddBatch`,bankFeeCard,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

/// *** Commission
  /**
 * API lấy tất cả các Commission theo adsId
 */
  async getAllCommissionByUserId(userId) {
    var res = await axios.get(`${apiUrl}/Commission/GetAllByUserId/${userId}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
    return res;
  },

     /**
 * API thêm Commission
 */
     async addCommission(commission) {
      await this.checkTokenValid();
      var res = await axios.post(`${apiUrl}/Commission/AddCommission`,commission,{headers:{Authorization: window.localStorage.getItem("id-token")} })
      return res;
    },

       /**
 * API thêm nhiều Commission
 */
   async importManyCommission(commission,userId) {
    await this.checkTokenValid();
    var res = await axios.post(`${apiUrl}/Commission/AddBatchCommission?userId=${userId}`,commission,{headers:{Authorization: window.localStorage.getItem("id-token")}, timeout: 600000, })
    return res;
  },

 /**
 * API xoá Commission
 */
 async deleteCommission(id) {
  await this.checkTokenValid();
  var res = await axios.delete(`${apiUrl}/Commission/DeleteCommission/${id}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
}, 


/// *** Detail Commission
/**
 * API get all Commission  
 */
async getAllCommissionPagedList(userId,pageNumber,pageSize,keyword) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/DetailCommission/GetAllPageList?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
/// *** AdsSpendHistory
async getAllAdsSpendPagedList(pageNumber,pageSize,keyword) {
  await this.checkTokenValid();
  var res = await axios.get(`${apiUrl}/AdsSpendHistory/GetAllPageList?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}`,{headers:{Authorization: window.localStorage.getItem("id-token")} })
  return res;
},
 

};


