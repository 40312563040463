<template>
  <div v-if="this.isAdmin == false">Bạn không có quyền vào trang này</div>
  <!--begin::Content wrapper-->
  <div v-else class="d-flex flex-column flex-column-fluid">
    <!--begin::Toolbar-->
    <div
      id="kt_app_toolbar"
      class="app-toolbar py-3 py-lg-6"
      style="padding-top: 0px !important; padding-bottom: 10px !important"
    >
      <!--begin::Toolbar container-->
      <div
        id="kt_app_toolbar_container"
        class="app-container d-flex flex-stack"
      >
        <!--begin::Page title-->
        <div
          class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
        >
          <!--begin::Title-->
          <h1
            class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
          >
            {{ this.$NOLIMITResource["VN"].CardManager.Title }}
          </h1>
          <!--end::Title-->
        </div>
        <!--end::Page title-->
      </div>
      <!--end::Toolbar container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
      <!--begin::Content container-->
      <div id="kt_app_content_container" class="app-container">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card header-->
          <div
            class="card-header border-0 pt-6"
            style="align-items: center; padding-top: 0px !important"
          >
            <!--begin::Card title-->
            <div class="card-title">
              <!--begin::Search-->
              <div class="d-flex align-items-center position-relative my-1">
                <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                  <span
                    @click="onEnterSearchFirst"
                    class="path1"
                    style="cursor: pointer; z-index: 2"
                  ></span>
                  <span class="path2"></span>
                </i>
                <input
                  v-on:keyup.enter="onEnterSearchFirst"
                  v-model="inputValue"
                  type="text"
                  class="form-control form-control-solid w-350px ps-12"
                  :placeholder="
                    this.$NOLIMITResource['VN'].CardManager.TextSearch
                  "
                />
              </div>
              <!--end::Search-->
            </div>

            <!--begin::Card toolbar-->
            <div class="card-toolbar" style="margin-left: auto">
              <!--begin::Toolbar-->
              <div class="content__toolbar--table">
                
                <a @click="openModalAllCardLastUpdate()" id="selectedall__toolbar">Hạn mức tín dụng</a>
              </div>
              <div class="content__toolbar--table">
  
                   <a @click="exportCSV()" id="selectedall__toolbar">Tải về CSV</a>
                </div>

              <div
                class="form-check form-switch form-check-custom form-check-solid me-10"
              >
                <input
                  class="form-check-input h-30px w-50px"
                  type="checkbox"
                  id="flexSwitch30x50"
                  v-model="this.switch1"
                />
                <label class="form-check-label" for="flexSwitch30x50">
                  Tổng của các số tiền chuyển đổi
                </label>
              </div>
              <div
                class="form-check form-switch form-check-custom form-check-solid me-10"
              >
                <input
                  class="form-check-input h-30px w-50px"
                  type="checkbox"
                  id="flexSwitch30x50"
                  v-model="this.switch"
                />
                <label class="form-check-label" for="flexSwitch30x50">
                  Số tiền chuyển đổi
                </label>
              </div>

              <div
                class="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
              >
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-300px w-md-325px"
                  data-kt-menu="true"
                  id="kt-toolbar-filter"
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-4 text-gray-900 fw-bold">Filter Options</div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Separator-->
                  <div class="separator border-gray-200"></div>
                  <!--end::Separator-->
                  <!--begin::Content-->
                  <div class="px-7 py-5">
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fs-5 fw-semibold mb-3"
                        >Month:</label
                      >
                      <!--end::Label-->
                      <!--begin::Input-->
                      <select
                        class="form-select form-select-solid fw-bold"
                        data-kt-select2="true"
                        data-placeholder="Select option"
                        data-allow-clear="true"
                        data-kt-customer-table-filter="month"
                        data-dropdown-parent="#kt-toolbar-filter"
                      >
                        <option></option>
                        <option value="aug">August</option>
                        <option value="sep">September</option>
                        <option value="oct">October</option>
                        <option value="nov">November</option>
                        <option value="dec">December</option>
                      </select>
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="mb-10">
                      <!--begin::Label-->
                      <label class="form-label fs-5 fw-semibold mb-3"
                        >Payment Type:</label
                      >
                      <!--end::Label-->
                      <!--begin::Options-->
                      <div
                        class="d-flex flex-column flex-wrap fw-semibold"
                        data-kt-customer-table-filter="payment_type"
                      >
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="all"
                            checked="checked"
                          />
                          <span class="form-check-label text-gray-600"
                            >All</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="visa"
                          />
                          <span class="form-check-label text-gray-600"
                            >Visa</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid mb-3"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="mastercard"
                          />
                          <span class="form-check-label text-gray-600"
                            >Mastercard</span
                          >
                        </label>
                        <!--end::Option-->
                        <!--begin::Option-->
                        <label
                          class="form-check form-check-sm form-check-custom form-check-solid"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="payment_type"
                            value="american_express"
                          />
                          <span class="form-check-label text-gray-600"
                            >American Express</span
                          >
                        </label>
                        <!--end::Option-->
                      </div>
                      <!--end::Options-->
                    </div>
                    <!--end::Input group-->
                    <!--begin::Actions-->
                    <div class="d-flex justify-content-end">
                      <button
                        type="reset"
                        class="btn btn-light btn-active-light-primary me-2"
                        data-kt-menu-dismiss="true"
                        data-kt-customer-table-filter="reset"
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        data-kt-menu-dismiss="true"
                        data-kt-customer-table-filter="filter"
                      >
                        Apply
                      </button>
                    </div>
                    <!--end::Actions-->
                  </div>
                  <!--end::Content-->
                </div>
                <!--end::Menu 1-->
                <!--begin::Filter-->
                <button
                  @click="toggleMenuFilter"
                  type="button"
                  class="btn btn-light-primary me-3"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i class="ki-duotone ki-filter fs-2">
                    <span class="path1"></span>
                    <span class="path2"></span> </i
                  >Lọc ngày
                </button>
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-300px w-md-325px show-dropfilter"
                  :class="{ show: this.onShowMenuFilter == true }"
                  data-kt-menu="true"
                  id="kt-toolbar-filter"
                  data-select2-id="select2-data-kt-toolbar-filter"
                  style=""
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-4 text-gray-900 fw-bold">
                      Tùy chọn bộ lọc
                    </div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Separator-->

                  <div class="separator border-gray-200"></div>
                  <!--end::Separator-->
                  <form action="" @submit.prevent="onFilterSearchFirst">
                    <!--begin::Content-->
                    <div
                      class="px-7 py-5"
                      data-select2-id="select2-data-128-13oj"
                    >
                      <!--begin::Input group-->
                      <div>
                        <label class="form-label fs-5 fw-semibold mb-3"
                          >Ngày bắt đầu:</label
                        >
                        <v-date-picker
                          class="inline-block h-full"
                          v-model="dateStart"
                          :timezone="timezone2"
                        >
                          <template v-slot="{ inputValue, togglePopover }">
                            <div class="flex items-center">
                              <input
                                @click="togglePopover()"
                                :value="inputValue"
                                class="form-control form-control-solid flatpickr-input active"
                                name="calendar_event_end_date"
                                placeholder="Ngày bắt đầu"
                                id="kt_calendar_datepicker_end_date"
                                type="text"
                                readonly="readonly"
                                style="margin-bottom: 20px"
                              />
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div>
                        <label class="form-label fs-5 fw-semibold mb-3"
                          >Ngày kết thúc:</label
                        >
                        <v-date-picker
                          class="inline-block h-full"
                          v-model="dateEnd"
                          :timezone="timezone2"
                        >
                          <template v-slot="{ inputValue, togglePopover }">
                            <div class="flex items-center">
                              <input
                                @click="togglePopover()"
                                :value="inputValue"
                                class="form-control form-control-solid flatpickr-input active"
                                name="calendar_event_end_date"
                                placeholder="Ngày kết thúc"
                                id="kt_calendar_datepicker_end_date"
                                type="text"
                                readonly="readonly"
                                style="margin-bottom: 20px"
                              />
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                      <!--end::Input group-->

                      <!--begin::Actions-->
                      <div class="d-flex justify-content-end">
                        <button
                          @click="onResetFilter"
                          type="reset"
                          class="btn btn-light btn-active-light-primary me-2"
                          data-kt-menu-dismiss="true"
                          data-kt-customer-table-filter="reset"
                        >
                          Đặt lại
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          data-kt-menu-dismiss="true"
                          data-kt-customer-table-filter="filter"
                        >
                          Lọc
                        </button>
                      </div>
                      <!--end::Actions-->
                    </div>
                    <!--end::Content-->
                  </form>
                </div>
                <!--end::Menu 1-->
                <!--end::Filter-->
              </div>
              <!--end::Toolbar-->
              <!--begin::Group actions-->
              <div
                class="d-flex justify-content-end align-items-center d-none"
                data-kt-customer-table-toolbar="selected"
              >
                <div class="fw-bold me-5">
                  <span
                    class="me-2"
                    data-kt-customer-table-select="selected_count"
                  ></span
                  >Selected
                </div>
                <button
                  type="button"
                  class="btn btn-danger"
                  data-kt-customer-table-select="delete_selected"
                >
                  Delete Selected
                </button>
              </div>
              <!--end::Group actions-->
            </div>

            <!--end::Card toolbar-->
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body pt-0" style="margin-top: 20px">
            <!--begin::Table-->
            <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_customers_table"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                  <th class="min-w-125px">Số thẻ</th>
                  <th class="min-w-125px">Tên chủ thẻ</th>
                  <th class="min-w-125px">Tên ngân hàng</th>
                  <th class="min-w-125px">Đơn vị tiền tệ</th>
                  <th
                    v-for="billLog in billLogs.dates"
                    :key="billLog.billLogId"
                    class="min-w-125px"
                  >
                    {{ formattedDate(billLog) }}
                  </th>
                  <!-- <template v-for="(column, index) in columns" :key="index" >
                        <th class="min-w-125px" :class="{
                          sorting_desc: this.sortingNumber == index,
                          sorting_asc: this.sortingAsc == index,
                        }" :style="{ 'width': column.width + 'px !important'}">
                          
                            <div @mousedown="startResize($event, index)"><span
                              @click="
                                onSorting(index, column.property, 'changeSort')
                              "
                              >{{ column.label }}</span
                            ></div>
                          
                        </th>
                      </template> -->
                </tr>
              </thead>
              <div class="loading" v-if="onLoading">
                <span class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
              </div>
              <div id="noData" v-if="!this.onLoading && this.hasData == false">
                Chưa có dữ liệu giao dịch
              </div>
              <tbody
                v-if="!this.onLoading && this.hasData == true"
                class="fw-semibold text-gray-600"
              >
                <tr
                  v-for="billLog in billLogs.cardAmounts"
                  :key="billLog.billLogId"
                >
                  <td>{{ billLog.cardNumber }}</td>
                  <td>{{ billLog.accountHolder }}</td>
                  <td>{{ billLog.bankName }}</td>
                  <td>{{ billLog.currency }}</td>

                  <td
                    v-for="date in billLogs.dates"
                    :key="date"
                    class="min-w-125px"
                  >
                    <span
                      v-for="dailyAmount in billLog.dailyAmounts"
                      :key="dailyAmount.date"
                    >
                      <span v-if="isSameDate(date, dailyAmount.date)">
                        <span v-if="this.switch1 == false">
                          {{
                            formatCurrency(
                              this.switch
                                ? dailyAmount.amountConverted
                                : dailyAmount.totalAmount
                            )
                          }}
                          <span
                            v-if="
                              billLog.currency === 'USD' && this.switch == false
                            "
                          >
                            ({{
                              formatCurrency(
                                this.switch
                                  ? dailyAmount.amountConverted *
                                      dailyAmount.exchangeRate
                                  : dailyAmount.totalAmount *
                                      this.currencyConvert.value
                              )
                            }}
                            VND)
                          </span>
                        </span>
                        <span v-else>
                          <span v-if="dailyAmount.amountConverted != 0">
                          {{
                            formatCurrency(
                              this.switch
                                ? dailyAmount.amountConverted
                                : dailyAmount.totalAmount
                            )
                          }}
                          <span
                            v-if="
                              billLog.currency === 'USD' && this.switch == false
                            "
                          >
                            ({{
                              formatCurrency(
                                this.switch
                                  ? dailyAmount.amountConverted *
                                      dailyAmount.exchangeRate
                                  : dailyAmount.totalAmount *
                                      this.currencyConvert.value
                              )
                            }}
                            VND)
                          </span>
                          
                        </span>
                        </span>
                      </span>
                    </span>
                  </td>

                  <!-- <td v-for="dailyAmount in billLog.dailyAmounts" :key="dailyAmount.date">{{ formatCurrency(dailyAmount.totalAmount) }}</td> -->

                  <!-- <td class="text-end" style="display: table-cell;min-width: 100px !important;">
                      <a
                        @click="openModalEmailHtml(billLog.idBillLog)"
                        
                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                        >Chi tiết</a>
                    </td> -->
                </tr>
                <tr id="total">
                  <td
                    class="fix-data-footer"
                    colspan="4"
                    style="font-weight: bold"
                  >
                    <img
                      src="./../../../../public/frontend/media/logos/logo3.png"
                      style="width: 30px; height: auto"
                    />
                   
                    <span>
                        Tổng: {{ formatCurrency(this.totalAll/ this.currencyConvert.value) }} USD
                      </span>
                       <p style="margin-left: 77px;">{{ formatCurrency(this.totalAll) }} VND</p>

                  </td>

                  <td v-for="total in listTotal" :key="total">
                    <span v-if="this.switch == false && this.switch1 == false">
                      <p>
                        {{
                          formatCurrency(total / this.currencyConvert.value)
                        }}
                        USD
                      </p>
                      {{ formatCurrency(total) }} VND
                    </span>
                    <span
                      v-else-if="this.switch == true && this.switch1 == false"
                    >
                      <p>{{ formatCurrency(total) }} USD</p></span
                    >
                    <span
                      v-else-if="this.switch == false && this.switch1 == true"
                    >
                      <p>
                        {{
                          formatCurrency(total / this.currencyConvert.value)
                        }}
                        USD
                      </p>
                      {{ formatCurrency(total) }} VND
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="row">
                  <div
                    class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
                  >
                    <div class="dataTables_length" id="kt_customers_table_length">
                      <label>
                        <v-select v-model="this.pageSize" id="styleSelect" :options="numberPerPage" label="value" ></v-select>
                      </label>
                    </div>
                  </div>
                  <div
                    class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
                  >
                    <div
                      class="dataTables_paginate paging_simple_numbers"
                      id="kt_customers_table_paginate"
                    >
                      <ul v-if="this.totalPage >= 4" class="pagination">
                        <li
                          class="paginate_button page-item previous"
                          :class="{ disabled: this.pageNumber == 1 }"
                          id="kt_customers_table_previous"
                        >
                          <a
                            @click="prevPage"
                            
                            aria-controls="kt_customers_table"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                            ><i class="previous"></i
                          ></a>
                        </li>
                        <li
                          v-for="index in 4"
                          :key="index"
                          class="paginate_button page-item"
                          :class="{ active: pageNumber == this.firstPage + index }"
                        >
                          <a
                            @click="selectPage(this.firstPage + index)"
                            
                            aria-controls="kt_customers_table"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link"
                            >{{ this.firstPage + index }}</a
                          >
                        </li>
                        <li
                          class="paginate_button page-item next"
                          :class="{ disabled: this.pageNumber == this.totalPage }"
                          id="kt_customers_table_next"
                        >
                          <a
                            @click="nextPage"
                            
                            aria-controls="kt_customers_table"
                            data-dt-idx="5"
                            tabindex="0"
                            class="page-link"
                            ><i class="next"></i
                          ></a>
                        </li>
                      </ul>
                      <ul v-if="this.totalPage < 4" class="pagination">
                        <li
                          class="paginate_button page-item previous"
                          :class="{ disabled: this.pageNumber == 1 }"
                          id="kt_customers_table_previous"
                        >
                          <a
                            @click="prevPage"
                            
                            aria-controls="kt_customers_table"
                            data-dt-idx="0"
                            tabindex="0"
                            class="page-link"
                            ><i class="previous"></i
                          ></a>
                        </li>
                        <li
                          v-for="index in totalPage"
                          :key="index"
                          class="paginate_button page-item"
                          :class="{ active: pageNumber == this.firstPage + index }"
                        >
                          <a
                            @click="selectPage(this.firstPage + index)"
                            
                            aria-controls="kt_customers_table"
                            data-dt-idx="1"
                            tabindex="0"
                            class="page-link"
                            >{{ this.firstPage + index }}</a
                          >
                        </li>
                        <li
                          class="paginate_button page-item next"
                          :class="{ disabled: this.pageNumber == this.totalPage }"
                          id="kt_customers_table_next"
                        >
                          <a
                            @click="nextPage"
                            aria-controls="kt_customers_table"
                            data-dt-idx="5"
                            tabindex="0"
                            class="page-link"
                            ><i class="next"></i
                          ></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div> -->
            <!--end::Table-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
         <!--begin::Last - Update Card-->
 <div
          class="modal fade show"
          :class="{ block: onShowModalCardLastUpdate }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content" style="width:160%">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="openModalAddBatchCardInfo"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold"> Hạn mức tín dụng thẻ</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalAllCardLastUpdate"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>                                                                                                                                                                     
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                  <table
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_customers_table"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                  <th class="min-w-125px">Chủ thẻ</th>
                  <th class="min-w-125px">Hạn mức còn lại</th>
                  <th class="min-w-125px">Gần nhất</th>
                </tr>
              </thead>
              <div class="loading" v-if="onLoadingCardNameCheck">
                <span class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
              </div>
              <div id="noData" v-if="!this.onLoadingCardNameCheck && this.hasDataCardNameCheck == false">
                {{ this.$NOLIMITResource["VN"].EmptyData }}
              </div>
              <tbody
                v-if="!this.onLoadingCardNameCheck && this.hasDataCardNameCheck == true"
                class="fw-semibold text-gray-600"
              >
                <tr
                  v-for="item in listLastUpdateBillLog"
                  :key="item.id"
                >
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ item.accountHolder }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ this.formatCurrency(item.amountRemain) }}</a
                    >
                  </td>
                  <td>
                    <a
                      class="text-gray-800 text-hover-primary mb-1"
                      >{{ item.time }}</a
                    >
                  </td>
                </tr>
              </tbody>
            </table>
                    
                     
                 
                 
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalAllCardLastUpdate"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  Đóng
                  </button>
                  <!--end::Button-->
               
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          
        </div>
        <!--end::Modal - Last - Update Card-->

        <!--begin::Modal - Email - HTML-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalEmailHtml }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content" style="width: 160%">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="closeModalEmailHtml"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold">Email HTML</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalEmailHtml"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    <div v-html="emailHtmlCurrent"></div>
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">Đóng</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
        </div>
        <!--end::Modal - Email - HTML-->
      </div>
      <!--end::Content container-->
    </div>

    <!--end::Content-->
  </div>
</template>
    <style scoped>
.table thead th:last-child {
  top: 0px;
}
.table thead th:last-child,
.table tbody td:last-child {
  position: relative;
  display: table-cell;
}
.table {
  overflow: scroll;
}
.loading {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
tr td {
  text-align: start;
}
.menu-sub-dropdown {
  position: absolute;
}
.block {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
}
.show-dropfilter {
  z-index: 107;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  top: 90px;
  right: 20px;
  text-align: start;
}
table tr#total:hover td {
  background-color: #fff !important;
}
.table tr#total {
  position: sticky;
  bottom: 0px;
  left: 0;
  background: #fff;
  z-index: 4;
}
.table tr#total {
  position: sticky;
}
.fix-data-footer {
  position: sticky;
  left: 0px;
  background: #fff;
  z-index: 10;
}
</style>
    <script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import router from "@/router/router.js";
export default {
  name: "CardManager",
  data() {
    return {
      onLoadingCardNameCheck:true,
      hasDataCardNameCheck:true,
      listLastUpdateBillLog:{},
      onShowModalCardLastUpdate:false,
      totalAll:0,
      switch1: false,
      switch: false,
      currencyConvert: {
        value: 0,
      },
      listTotal: [],
      totalAmountCard: 0,
      isShowModalEmailHtml: false,
      currentIndex: null,
      currentProperty: "",
      modeSort: "",
      sortingAsc: null,
      sortingNumber: null,
      isResizing: false,
      initialX: 0,
      initialWidth: 0,
      resizingCol: null,
      columns: [
        { label: "Số thẻ", width: 800, property: "CardNumber" },
        { label: "Tên ngân hàng", width: 800, property: "BankName" },
      ],
      totalStatusSuccess: 0,
      totalStatusProcessing: 0,
      totalStatusNotSuccess: 0,
      emailHtmlCurrent: "",
      numberPerPage: [10, 50, 100, 500],
      onLoading: true,
      billLogs: [],
      onShowMenu: false,
      currenOnshowId: 0,
      isShowModal: false,
      currentBalanceUser: 0,
      currentTotalMoneyUser: 0,
      isShowModalUpdateUser: false,
      changeMoney: 0,
      formMode: "",
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
      firstPage: 0,
      inputValue: "",
      userId: 0,
      isAdmin: true,
      hasData: true,
      status: "",
      dateStart: null,
      dateEnd: null,
      timezoneIndex: 19,
      timezoneIndex1: 1,
      timezones: [
        "Pacific/Midway", // -11
        "Pacific/Niue", // -11
        "Pacific/Honolulu", // -10
        "Pacific/Pago_Pago", // -11
        "America/Anchorage", // -9
        "America/Los_Angeles", // -8
        "America/Tijuana", // -8
        "America/Denver", // -7
        "America/Phoenix", // -7
        "America/Chicago", // -6
        "America/Mexico_City", // -6
        "America/Regina", // -6
        "America/New_York", // -5
        "America/Caracas", // -4.5
        "America/Halifax", // -4
        "America/Argentina/Buenos_Aires", // -3
        "America/Sao_Paulo", // -3
        "Atlantic/Azores", // -1
        "Atlantic/Cape_Verde", // -1
        "UTC", // 0
        "Europe/London", // +0
        "Europe/Paris", // +1
        "Europe/Istanbul", // +3
        "Europe/Moscow", // +3
        "Asia/Dubai", // +4
        "Asia/Yekaterinburg", // +5
        "Asia/Almaty", // +6
        "Asia/Colombo", // +6
        "Asia/Bangkok", // +7
        "Asia/Ho_Chi_Minh", // +7
        "Asia/Jakarta", // +7
        "Asia/Taipei", // +8
        "Asia/Singapore", // +8
        "Asia/Kuala_Lumpur", // +8
        "Asia/Hong_Kong", // +8
        "Asia/Ulaanbaatar", // +8
        "Asia/Pyongyang", // +9
        "Asia/Tokyo", // +9
        "Australia/Darwin", // +9.5
        "Australia/Adelaide", // +10
        "Australia/Sydney", // +10
        "Pacific/Guam", // +10
        "Australia/Brisbane", // +10
        "Asia/Vladivostok", // +10
        "Pacific/Fiji", // +12
        "Pacific/Tongatapu", // +13
        "Pacific/Apia", // +13
      ],
    };
  },
  computed: {
    ...mapGetters("listModule", [
      "onShowMenuFilter",
      "formatCurrency",
      "formatDateTime",
      "convertToEndOfDay",
    ]),
    timezone2() {
      return this.timezones[this.timezoneIndex];
    },
    timezone1() {
      return this.timezones[this.timezoneIndex1];
    },
    attributes() {
      return [
        {
          highlight: true,
          dates: this.dateStart,
        },
      ];
    },
  },
  created() {
    this.$apiService
      .getUserProfile()
      .then((res) => {
        this.userId = res.data.data.userId;
        if (res.data.data.isAdmin == false && res.data.data.isInternalAccountant != true) {
          this.isAdmin = false;
          router.push({ name: "ErrorNotFound" });
        }

        this.$apiService
          .getTotalAmountByDate("", "")
          .then((res) => {
            setTimeout(() => (this.onLoading = false), 1000);
            this.billLogs = res.data;
            if (res.data.length == 0) {
              this.hasData = false;
            }
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
      });
    this.$apiService.getSettingById(4).then((res) => {
      this.currencyConvert.value = res.data.value;
    });
  },
  watch: {
    pageSize() {
      this.reDisplay();
    },
    billLogs() {
      this.totalAll = 0;
      this.listTotal = new Array(this.billLogs.dates.length).fill(0);
      for (let i = 0; i < this.billLogs.dates.length; i++) {
        const currentDate = this.billLogs.dates[i];
        for (let j = 0; j < this.billLogs.cardAmounts.length; j++) {
          const cardAmounts = this.billLogs.cardAmounts[j];
          for (let k = 0; k < cardAmounts.dailyAmounts.length; k++) {
            const dailyAmount = cardAmounts.dailyAmounts[k];
            if (
              new Date(currentDate).toISOString() ===
              new Date(dailyAmount.date).toISOString()
            ) {
              if (this.switch == false && this.switch1 == false) {
                if (cardAmounts.currency == "USD") {
                  this.listTotal[i] +=
                    dailyAmount.totalAmount * this.currencyConvert.value;
                } else {
                  this.listTotal[i] += dailyAmount.totalAmount;
                }
              } else if (this.switch == true && this.switch1 == false) {
                this.listTotal[i] += dailyAmount.amountConverted;
              } else if (this.switch == false && this.switch1 == true) {
                if (
                  dailyAmount.amountConverted != 0 &&
                  dailyAmount.exchangeRate != 0
                ) {
                  if (cardAmounts.currency == "USD") {
                    this.listTotal[i] +=
                      dailyAmount.totalAmount * this.currencyConvert.value;
                  } else {
                    this.listTotal[i] += dailyAmount.totalAmount;
                  }
                }
              }
            }
          }
        }
      }
      for (let i = 0; i < this.listTotal.length; i++) {
        this.totalAll +=this.listTotal[i];
      }
    },
    switch() {
      this.totalAll = 0;
      if (this.switch == true) {
        this.switch1 = false;
      }

      this.listTotal = new Array(this.billLogs.dates.length).fill(0);
      for (let i = 0; i < this.billLogs.dates.length; i++) {
        const currentDate = this.billLogs.dates[i];
        for (let j = 0; j < this.billLogs.cardAmounts.length; j++) {
          const cardAmounts = this.billLogs.cardAmounts[j];
          for (let k = 0; k < cardAmounts.dailyAmounts.length; k++) {
            const dailyAmount = cardAmounts.dailyAmounts[k];
            if (
              new Date(currentDate).toISOString() ===
              new Date(dailyAmount.date).toISOString()
            ) {
              if (this.switch == false && this.switch1 == false) {
                if (cardAmounts.currency == "USD") {
                  this.listTotal[i] +=
                    dailyAmount.totalAmount * this.currencyConvert.value;
                } else {
                  this.listTotal[i] += dailyAmount.totalAmount;
                }
              } else if (this.switch == true && this.switch1 == false) {
                this.listTotal[i] += dailyAmount.amountConverted;
              } else if (this.switch == false && this.switch1 == true) {
                if (
                  dailyAmount.amountConverted != 0 &&
                  dailyAmount.exchangeRate != 0
                ) {
                  if (cardAmounts.currency == "USD") {
                    this.listTotal[i] +=
                      dailyAmount.totalAmount * this.currencyConvert.value;
                  } else {
                    this.listTotal[i] += dailyAmount.totalAmount;
                  }
                }
              }
            }
          }
        }
      }
      for (let i = 0; i < this.listTotal.length; i++) {
        this.totalAll +=this.listTotal[i];
      }
    },
    switch1() {
      this.totalAll = 0;
      if (this.switch1 == true) {
        this.switch = false;
      }

      this.listTotal = new Array(this.billLogs.dates.length).fill(0);
      for (let i = 0; i < this.billLogs.dates.length; i++) {
        const currentDate = this.billLogs.dates[i];
        for (let j = 0; j < this.billLogs.cardAmounts.length; j++) {
          const cardAmounts = this.billLogs.cardAmounts[j];
          for (let k = 0; k < cardAmounts.dailyAmounts.length; k++) {
            const dailyAmount = cardAmounts.dailyAmounts[k];
            if (
              new Date(currentDate).toISOString() ===
              new Date(dailyAmount.date).toISOString()
            ) {
              if (this.switch == false && this.switch1 == false) {
                if (cardAmounts.currency == "USD") {
                  this.listTotal[i] +=
                    dailyAmount.totalAmount * this.currencyConvert.value;
                } else {
                  this.listTotal[i] += dailyAmount.totalAmount;
                }
              } else if (this.switch == true && this.switch1 == false) {
                this.listTotal[i] += dailyAmount.amountConverted;
              } else if (this.switch == false && this.switch1 == true) {
                if (
                  dailyAmount.amountConverted != 0 &&
                  dailyAmount.exchangeRate != 0
                ) {
                  if (cardAmounts.currency == "USD") {
                    this.listTotal[i] +=
                      dailyAmount.totalAmount * this.currencyConvert.value;
                  } else {
                    this.listTotal[i] += dailyAmount.totalAmount;
                  }
                }
              }
            }
          }
        }
      }
      for (let i = 0; i < this.listTotal.length; i++) {
        this.totalAll +=this.listTotal[i];
      }
    },
  },
  methods: {
    ...mapActions("listModule", ["toggleMenuFilter"]),
    ...mapMutations("listModule", ["closeMenuFilter"]),
    choosePageSize(event) {
      this.pageSize = event.target.value;
      this.reDisplay();
    },
    selectPage(index) {
      this.pageNumber = index;
      if (this.totalPage >= 4) {
        if (index == this.totalPage) {
          this.firstPage = index - 4;
        } else if (index >= 3) {
          this.firstPage = index - 3;
        } else {
          this.firstPage = 0;
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
        this.onFilterSearch();
      } else if (this.sortingNumber != null || this.sortingAsc != null) {
        this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
      } else {
        this.onEnterSearch();
      }
    },
    reDisplay() {
      this.hasData = true;
      this.$apiService
        .getTotalAmountByDate("", "")
        .then((res) => {
          this.onLoading = true;
          setTimeout(() => (this.onLoading = false), 1000);
          this.billLogs = res.data;
          if (res.data.length == 0) {
            this.hasData = false;
          }
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    /**
     * Chọn next trang tiếp theo trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    nextPage() {
      if (this.pageNumber != this.totalPage) {
        this.pageNumber += 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
        this.onFilterSearch();
      } else if (this.sortingNumber != null || this.sortingAsc != null) {
        this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
      } else {
        this.onEnterSearch();
      }
    },
    /**
     * Chọn prev trang trước đó trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    prevPage() {
      if (this.pageNumber != 1) {
        this.pageNumber -= 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
        this.onFilterSearch();
      } else if (this.sortingNumber != null || this.sortingAsc != null) {
        this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
      } else {
        this.onEnterSearch();
      }
    },

    onEnterSearch() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.$apiService
          .getTotalAmountByDateSearch("", "", this.inputValue)
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.billLogs = res.data;
            if (res.data.length == 0) {
              this.hasData = false;
            }
          });
      }
    },

    onEnterSearchFirst() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.$apiService
          .getTotalAmountByDateSearch("", "", this.inputValue)
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.billLogs = res.data;
            if (res.data.length == 0) {
              this.hasData = false;
            }
          });
      }
    },
    onFilterSearch() {
      this.hasData = true;
      if (this.dateStart == null && this.inputValue == "") {
        this.reDisplay();
      } else {
        this.$apiService
          .getTotalAmountByDateSearch(
            this.dateStart.toISOString(),
            this.dateEnd.toISOString(),
            this.inputValue
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.billLogs = res.data;
            if (res.data.length == 0) {
              this.hasData = false;
            }
          });
      }
      this.closeMenuFilter();
    },
    onFilterSearchFirst() {
      this.hasData = true;
      if (this.dateStart == null && this.inputValue == "") {
        this.reDisplay();
      } else {
        this.pageNumber = 1;
        this.firstPage = 0;
        this.$apiService
          .getTotalAmountByDateSearch(
            this.dateStart.toISOString(),
            this.dateEnd.toISOString(),
            this.inputValue
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.billLogs = res.data;
            if (res.data.length == 0) {
              this.hasData = false;
            }
          });
      }
      this.closeMenuFilter();
    },

    onResetFilter() {
      this.status = "";
      this.dateStart = null;
      this.dateEnd = null;
    },
    startResize(event, index) {
      if (event.offsetX > event.target.offsetWidth - 10) {
        this.isResizing = true;
        this.initialX = event.clientX;
        this.initialWidth = this.columns[index].width;
        this.resizingCol = index;

        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);
      }
    },
    handleMouseMove(event) {
      if (this.isResizing && this.resizingCol !== null) {
        const delta = event.clientX - this.initialX;
        const newWidth = Math.max(50, this.initialWidth + delta);
        this.columns[this.resizingCol] = {
          ...this.columns[this.resizingCol],
          width: newWidth,
        };
      }
    },
    handleMouseUp() {
      this.isResizing = false;
      this.initialX = 0;
      this.initialWidth = 0;
      this.resizingCol = null;

      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("mouseup", this.handleMouseUp);
    },
    onSorting(index, property, mode) {
      this.currentIndex = index;
      this.currentProperty = property;
      if (
        this.dateStart == null ||
        this.dateEnd == null ||
        this.dateEnd < this.dateStart
      ) {
        if (this.sortingNumber == index && mode == "changeSort") {
          this.sortingNumber = null;
          this.sortingAsc = index;
          this.$apiService
            .getAllbillLogByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.status,
              this.inputValue,
              property,
              "ASC"
            )
            .then((res) => {
              this.modeSort = "ASC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.billLogs = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingNumber == index && mode == "selectPage") {
          this.sortingNumber = null;
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllbillLogByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.status,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.billLogs = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingAsc == index && mode == "changeSort") {
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllbillLogByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.status,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.billLogs = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingAsc == index && mode == "selectPage") {
          this.sortingAsc = null;
          this.sortingNumber = null;
          this.sortingAsc = index;
          this.$apiService
            .getAllbillLogByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.status,
              this.inputValue,
              property,
              "ASC"
            )
            .then((res) => {
              this.modeSort = "ASC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.billLogs = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else {
          this.sortingNumber = null;
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllbillLogByAdminFilterStatus(
              this.pageNumber,
              this.pageSize,
              this.status,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.billLogs = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        }
      } else {
        const dateEndConvert = this.convertToEndOfDay(this.dateEnd);
        if (this.sortingNumber == index && mode == "changeSort") {
          this.sortingNumber = null;
          this.sortingAsc = index;
          this.$apiService
            .getAllbillLogByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.status,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              property,
              "ASC"
            )
            .then((res) => {
              this.modeSort = "ASC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.billLogs = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingNumber == index && mode == "selectPage") {
          this.sortingNumber = null;
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllbillLogByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.status,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.billLogs = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingAsc == index && mode == "changeSort") {
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllbillLogByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.status,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.billLogs = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else if (this.sortingAsc == index && mode == "selectPage") {
          this.sortingAsc = null;
          this.sortingNumber = null;
          this.sortingAsc = index;
          this.$apiService
            .getAllbillLogByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.status,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              property,
              "ASC"
            )
            .then((res) => {
              this.modeSort = "ASC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.billLogs = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        } else {
          this.sortingNumber = null;
          this.sortingAsc = null;
          this.sortingNumber = index;
          this.$apiService
            .getAllbillLogByAdminFilter(
              this.pageNumber,
              this.pageSize,
              this.status,
              this.dateStart.toISOString(),
              dateEndConvert,
              this.inputValue,
              property,
              "DESC"
            )
            .then((res) => {
              this.modeSort = "DESC";
              this.onLoading = true;
              setTimeout(() => {
                this.onLoading = false;
              }, 1000);
              this.billLogs = res.data.data;
              if (res.data.data.length == 0) {
                this.hasData = false;
              }
              this.totalPage = res.data.pageData.pageCount;
            })
            .catch((error) => {
              console.error("Lỗi khi lấy dữ liệu:", error);
            });
        }
      }
    },
    exportCSV() {
      let data = [];
      for (let i = 0; i < this.billLogs.cardAmounts.length; i++) {
        const cardAmounts = this.billLogs.cardAmounts[i];
        const item = {
          "Số thẻ": this.billLogs.cardAmounts[i].cardNumber,
          "Tên chủ thẻ": this.boDauXuongDong(this.billLogs.cardAmounts[i].accountHolder),
          "Tên ngân hàng": this.billLogs.cardAmounts[i].bankName,
          "Đơn vị tiền tệ": this.billLogs.cardAmounts[i].currency,
        };
         // Duyệt qua danh sách các ngày
    for (let j = 0; j < this.billLogs.dates.length; j++) {
      const currentDate = this.billLogs.dates[j];
      let amountForDate = 0;

      // Kiểm tra dailyAmounts có giá trị cho ngày này không
      for (let k = 0; k < cardAmounts.dailyAmounts.length; k++) {
        const dailyAmount = cardAmounts.dailyAmounts[k];

        if (new Date(currentDate).toISOString() === new Date(dailyAmount.date).toISOString()) {
          if (this.switch == false && this.switch1 == false) {
            // Trường hợp hiển thị totalAmount và chuyển đổi theo VND nếu là USD
            amountForDate = cardAmounts.currency === "USD" 
              ? dailyAmount.totalAmount * this.currencyConvert.value 
              : dailyAmount.totalAmount;
          } else if (this.switch == true && this.switch1 == false) {
            // Trường hợp hiển thị amountConverted
            amountForDate = dailyAmount.amountConverted;
          } else if (this.switch == false && this.switch1 == true) {
            // Trường hợp đặc biệt với switch1 = true
            if (dailyAmount.amountConverted !== 0 && dailyAmount.exchangeRate !== 0) {
              amountForDate = cardAmounts.currency === "USD" 
                ? dailyAmount.totalAmount * this.currencyConvert.value 
                : dailyAmount.totalAmount;
            }
          }
          break; // Đã tìm thấy giá trị cho ngày này, dừng lại
        }
      }
      const dateTitle = this.formattedDate(currentDate)
      // Đặt giá trị của ngày vào trong item với key là ngày
      item[dateTitle] = amountForDate;
    }
        data.push(item);
      }

      const csvContent1 = this.convertToCSV(data);
      var BOM = "\uFEFF";
      var csvContent = BOM + csvContent1;
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "export_card.csv");
      link.click();
    },
    convertToCSV(data) {
      const headers = Object.keys(data[0]);
      const rows = data.map((obj) => headers.map((header) => obj[header]));
      const headerRow = headers.join(",");
      const csvRows = [headerRow, ...rows.map((row) => row.join(","))];
      return csvRows.join("\n");
    },
    openModalEmailHtml(id) {
      this.$apiService.getBillLogById(id).then((res) => {
        this.emailHtmlCurrent = res.data.data.emailHtml;
      });
      this.isShowModalEmailHtml = true;
    },
    closeModalEmailHtml() {
      this.isShowModalEmailHtml = false;
    },
    formattedDate(dateIn) {
      const date = new Date(dateIn);
      // Định dạng ngày thành dd-MM-yyyy
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    },
    isSameDate(date1, date2) {
      return (
        new Date(date1).toISOString().split("T")[0] ===
        new Date(date2).toISOString().split("T")[0]
      );
    },
    boDauXuongDong(inputString) {
      if (inputString == null) {
        return ''; 
      }
      var chuoiKetQua = inputString.replace(/\r/g, ''); 
      return chuoiKetQua;
    },
    openModalAllCardLastUpdate(){
      this.$apiService.GetAllLastUpdateBillLog().then((res)=>{
        setTimeout(() => {
                this.onLoadingCardNameCheck = false;
            }, 2000);
        this.listLastUpdateBillLog = res.data;
        if(res.data.length == 0){
                this.hasDataCardNameCheck = false;
              }
      })
      this.onShowModalCardLastUpdate= true;
    },
    closeModalAllCardLastUpdate(){
      this.onShowModalCardLastUpdate = false;
    },
    
  },
};
</script>
    