<template>
  <div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Toolbar-->
    <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
      <!--begin::Toolbar container-->
      <div
        id="kt_app_toolbar_container"
        class="app-container container-xxl d-flex flex-stack"
      >
        <!--begin::Page title-->
        <div
          class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
        >
          <!--begin::Title-->
          <h1
            class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
          >
         {{  this.$NOLIMITResource[this.dataUser.language].ProfileSetting.Title }}
          </h1>
          <!--end::Title-->
        </div>
        <!--end::Page title-->
      </div>
      <!--end::Toolbar container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-xxl">
      <!--begin::Navbar-->
      <div class="card mb-5 mb-xl-10">
        <div class="card-body pt-9 pb-0">
          <!--begin::Details-->
          <div class="d-flex flex-wrap flex-sm-nowrap">
            <!--begin: Pic-->
            <div class="me-7 mb-4">
              <div
                class="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative"
              >
                <div style="width: 160px;height: 160px;background-color: #fff8dd; display: flex; align-items: center; justify-content: center;">
                    <h1 style="color: #f6c000; font-size: 50px  ;">{{ this.firstUsername }}</h1>
                </div>
                <div
                  class="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"
                ></div>
              </div>
            </div>
            <!--end::Pic-->
            <!--begin::Info-->
            <div class="flex-grow-1">
              <!--begin::Title-->
              <div
                class="d-flex justify-content-between align-items-start flex-wrap mb-2"
              >
                <!--begin::User-->
                <div class="d-flex flex-column">
                  <!--begin::Name-->
                  <div class="d-flex align-items-center mb-2">
                    <a
                      
                      class="text-gray-900 text-hover-primary fs-2 fw-bold me-1"
                      >{{ this.dataUser.name }}</a
                    >
                    <a >
                      <i class="ki-duotone ki-verify fs-1 text-primary">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </i>
                    </a>
                  </div>
                  <!--end::Name-->
                  <!--begin::Info-->
                  <div class="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                    <a
                      v-if="this.dataUser.isAdmin == true"
                      
                      class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                    >
                      <i class="ki-duotone ki-profile-circle fs-4 me-1">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span> </i
                      > {{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.Admin }}</a
                    >
                    <a
                      v-else
                      
                      class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                    >
                      <i class="ki-duotone ki-profile-circle fs-4 me-1">
                        <span class="path1"></span>
                        <span class="path2"></span>
                        <span class="path3"></span> </i
                      >{{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.User }}</a
                    >

                    <a
                      
                      class="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2 "
                    >
                      <i class="ki-duotone ki-sms fs-4">
                        <span class="path1"></span>
                        <span class="path2"></span> </i
                      >{{ this.dataUser.email }}</a
                    >

                    
                  </div>
                  <!--end::Info-->
                </div>
                <!--end::User-->
              </div>
              <!--end::Title-->
              <!--begin::Stats-->
              <div class="d-flex flex-wrap flex-stack">
                <!--begin::Wrapper-->
                <div class="d-flex flex-column flex-grow-1 pe-8">
                  <!--begin::Stats-->
                  <div class="d-flex flex-wrap">
                      

                    <!--begin::Stat-->
                    <div
                      class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                    >
                      <!--begin::Number-->
                      <div class="d-flex align-items-center">
                        <i
                          class="ki-duotone ki-arrow-up fs-3 text-success me-2"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </i>
                        <div class="fs-2 fw-bold">{{ formattedBalance }}</div>
                      </div>
                      <!--end::Number-->
                      <!--begin::Label-->
                      <div class="fw-semibold fs-6 text-gray-500"> {{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.AmountInWallet }}</div>
                      <!--end::Label-->
                    </div>
                    <!--end::Stat-->



                    <!--begin::Stat-->
                    <div
                      class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                    >
                      <!--begin::Number-->
                      <div class="d-flex align-items-center">
                        <i
                          class="ki-duotone ki-arrow-up fs-3 text-success me-2"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                        </i>
                        <div class="fs-2 fw-bold">{{ formattedBalanceUSD }} USD</div>
                      </div>
                      <!--end::Number-->
                      <!--begin::Label-->
                      <div class="fw-semibold fs-6 text-gray-500"> {{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.AmountInWalletUSD }}</div>
                      <!--end::Label-->
                    </div>
                    <!--end::Stat-->

     
                    <button v-if="dataUser.isAccountant == true || dataUser.isSupport == true" @click="openModalTransferMoney" type="button" class="btn btn-primary" style="height: 70px">
                      {{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.TransferOtherUser }}
                    </button>
                    
                  </div>
                  <!--end::Stats-->
                  
                </div>
                <!--end::Wrapper-->
              </div>
              <!--end::Stats-->
            </div>
            <!--end::Info-->
          </div>

        <!--begin::Modal - tranfer - money -->
        <div
          class="modal fade show"
          :class="{ block: isShowModalTransferMoney }"
          id="kt_modal_new_card"
          role="dialog"
          tabindex="-1"
          aria-modal="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Modal header-->
              <div class="modal-header">
                <!--begin::Modal title-->
                <h2>{{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.TransferOtherUser }}</h2>

                <div
                  @click="closeModalTransferMoney"
                  class="btn btn-sm btn-icon btn-active-color-primary"
                  data-bs-dismiss="modal"
                >
                  <i class="ki-duotone ki-cross fs-1">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
              </div>
              <!--end::Modal header-->
              <!--begin::Modal body-->
              <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                <!--begin::Form-->
                <form
                  id="kt_modal_new_card_form"
                  class="form"
                  action=""
                  @submit.prevent="addTransferMoney"
                >
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.UsernameReceiveMoney }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].ProfileSetting.TitleUsernameReceiveMoney"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="infoTransfer.recipientUsername"
                    />
                  </div>
                  <!--end::Input group-->
                 
                  <!--begin::Input group-->
                  <div class="d-flex flex-column mb-7 fv-row">
                    <!--begin::Label-->
                    <label
                      class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                    >
                      <span> {{ this.$NOLIMITResource[this.dataUser.language].Amount }}</span>
                      <span
                        class="ms-1"
                        data-bs-toggle="tooltip"
                        :title="this.$NOLIMITResource[this.dataUser.language].ProfileSetting.TitleAmountTransfer"
                      >
                        <i
                          class="ki-duotone ki-information-5 text-gray-500 fs-6"
                        >
                          <span class="path1"></span>
                          <span class="path2"></span>
                          <span class="path3"></span>
                        </i>
                      </span>
                    </label>
                    <!--end::Label-->
                    <input
                      type="text"
                      class="form-control form-control-solid"
                      placeholder=""
                      name="card_name"
                      v-model="infoTransfer.amount"
                    />
                  </div>
                  <!--end::Input group-->
                 
                  <!--begin::Actions-->
                  <div class="text-center pt-15">
                    <button
                      @click="closeModalTransferMoney"
                      type="reset"
                      id="kt_modal_new_card_cancel"
                      class="btn btn-light me-3"
                    >
                    {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                    </button>
                    <button
                      type="submit"
                      id="kt_modal_new_card_submit"
                      class="btn btn-primary"
                    >
                      <span
                        class="indicator-label"
                        >{{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.TransferAmount }}</span
                      >
                      <span class="indicator-progress"
                        >Please wait...
                        <span
                          class="spinner-border spinner-border-sm align-middle ms-2"
                        ></span
                      ></span>
                    </button>
                  </div>
                  <!--end::Actions-->
                </form>
                <!--end::Form-->
              </div>
              <!--end::Modal body-->
            </div>
            <!--end::Modal content-->
          </div>
          <!--end::Modal dialog-->
        </div>
        <!--end::Modal - tranfer - money-->


          <!--end::Details-->
          <!--begin::Navs-->
          <ul
            class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold"
          >
            <!--begin::Nav item-->
            <li class="nav-item mt-2">
              <router-link
                class="nav-link text-active-primary ms-0 me-10 py-5 active"
                to="/"
                > {{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.Info }}</router-link
              >
            </li>
            <!--end::Nav item-->
            <!--begin::Nav item-->
            <li class="nav-item mt-2" v-if="this.dataUser.isAffiliate == true">
                <router-link
                  class="nav-link text-active-primary ms-0 me-10 py-5"
                  to="/userCommissions"
                  > {{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.Commission }}</router-link
                >
              </li>
              <!--end::Nav item-->
           
          </ul>
          <!--begin::Navs-->
        </div>
      </div>
      <!--end::Navbar-->
      <!--begin::Basic info-->
      <div class="card mb-5 mb-xl-10">
        <!--begin::Card header-->
        <div
          class="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          data-bs-target="#kt_account_profile_details"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <!--begin::Card title-->
          <div class="card-title m-0">
            <h3 class="fw-bold m-0"> {{ this.$NOLIMITResource[this.dataUser.language].ProfileSetting.InfoDetail }}</h3>
          </div>
          <!--end::Card title-->
        </div>
        <!--begin::Card header-->
        <!--begin::Content-->
        <div id="kt_account_settings_profile_details" class="collapse show">
          <!--begin::Form-->
          <form
            id="kt_account_profile_details_form"
            class="form"
            action=""
            @submit.prevent="onSubmit"
          >
            <!--begin::Card body-->
            <div class="card-body border-top p-9">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-semibold fs-6"
                  > {{ this.$NOLIMITResource[this.dataUser.language].FullName }}:</label
                >
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <input
                    type="text"
                    v-model="dataUserUpdate.name"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-semibold fs-6"
                  >Email</label
                >
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <input
                    type="text"
                    name="email"
                    class="form-control form-control-lg form-control-solid"
                    v-model="dataUserUpdate.email"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-semibold fs-6"
                  > {{ this.$NOLIMITResource[this.dataUser.language].UserManagement.PhoneNumber }}:</label
                >
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <input
                    type="text"
                    name="phone"
                    class="form-control form-control-lg form-control-solid"
                    v-model="dataUserUpdate.phone"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-semibold fs-6"
                  >Facebook</label
                >
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <input
                    type="text"
                    name="facebook"
                    class="form-control form-control-lg form-control-solid"
                    v-model="dataUserUpdate.facebook"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-semibold fs-6"
                  >Telegram</label
                >
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <input
                    type="text"
                    name="telegram"
                    class="form-control form-control-lg form-control-solid"
                    v-model="dataUserUpdate.telegram"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-semibold fs-6"
                  >Zalo</label
                >
                <!--end::Label-->
                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <input
                    type="text"
                    name="zalo"
                    class="form-control form-control-lg form-control-solid"
                    
                    v-model="dataUserUpdate.zalo"
                  />
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Card body-->
            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
              <button
                @click="updateProfile"
                type="submit"
                class="btn btn-primary"
                id="kt_account_profile_details_submit"
              >
              {{ this.$NOLIMITResource[this.dataUser.language].Save }}
              </button>
            </div>
            <!--end::Actions-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Basic info-->
      
    </div>
    <!--end::Content container-->
  </div>
</template>
<style scoped>
.col-form-label {
  display: flex;
  justify-content: flex-start;
}
.image-input-outline {
  background-image: url("./../../../../public/frontend/media/svg/avatars/blank.svg");
}
.block {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }
#kt_signin_email {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}
</style>
<script>
import { mapActions } from "vuex";
import { storage } from "./../../../components/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
export default {
  name: "UserProfile",
  data() {
    return {
      currencyConvert: 0,
      infoTransfer:{},
      isShowModalTransferMoney:false,
      avatar: "",
      balanceHistory: [], 
      dataUser: {
        totalMoney: 0,
        topUpFee: 0,
        disscount: 0,
        balance: 0,
      },
      dataUserUpdate: {
        totalMoney: 0,
        topUpFee: 0,
        disscount: 0,
        balance: 0,
      },
      countInterval: null,
      duration: 2000, // Thời gian (ms) để đếm từ 0 đến giá trị mới
      steps: 100, // Số bước để thực hiện hiệu ứng đếm
      animationValue: {
        totalMoney: 0,
        topUpFee: 0,
        disscount: 0,
        balance: 0,
      },
      errors:{
				email: "",
				phone: "",
			},	
      isValidate:false,
      firstUsername:"",
    };
  },

  created() {

    this.$apiService
      .getUserProfile()
      .then((res) => {
        if(res.data.data.language == "VN" || res.data.data.language == "EN"){
                    this.dataUser = { ...res.data.data };
                }else{
                    this.dataUser = { ...res.data.data };
                    this.dataUser.language = "VN";
                }
        this.dataUserUpdate = { ...res.data.data };
        this.avatar = this.dataUser.avatar;
        this.$apiService
          .getBalanceHistoryById(this.dataUser.userId)
          .then((res) => {
            this.balanceHistory = res.data.slice(0,10);
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
          this.getFirstUsername();
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
      });

       this.$apiService.getSettingById(4).then((res) => {
      this.currencyConvert = res.data.value;
    });
  },
  computed: {
    formattedTotalMoney() {
      return `${this.formatCurrency(this.dataUser.totalMoney)}`;
    },
    formattedTopUpFee() {
      return `${this.dataUser.topUpFee}%`;
    },
    formattedDisscount() {
      return `%${this.dataUser.disscount.toLocaleString()}`;
    },
    formattedBalance() {
      return `${this.formatCurrency(this.dataUser.balance)}`;
    },
    formattedBalanceUSD() {
      return `${this.roundTwoDecimals(this.dataUser.balance/this.currencyConvert)}`;
    },
  },
  methods: {
    ...mapActions("listModule", ["toggleMenuFilter", "toggleModalDisplayRow","swalSuccess","swalError","swalWarning","toggleFilterStatus","setStatusAds"]),
    reDisplay(){
      this.$apiService
      .getUserProfile()
      .then((res) => {
        if(res.data.data.language == "VN" || res.data.data.language == "EN"){
                    this.dataUser = { ...res.data.data };
                }else{
                    this.dataUser = { ...res.data.data };
                    this.dataUser.language = "VN";
                }
        this.dataUserUpdate = { ...res.data.data };
        this.avatar = this.dataUser.avatar;
   
        this.$apiService
          .getBalanceHistoryById(this.dataUser.userId)
          .then((res) => {
            this.balanceHistory = res.data;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      })
      .catch((error) => {
        console.error("Lỗi khi lấy dữ liệu:", error);
      });
    },
    upload() {
      const time = new Date().getTime();
      const storageRef = ref(
        storage,
        `avatar/${time}_${this.$refs.myfile.files[0].name}`
      );
      const uploadTask = uploadBytesResumable(
        storageRef,
        this.$refs.myfile.files[0]
      );
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          console.log(snapshot);
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            this.avatar = downloadURL;
            
          });
        }
      );
    },
    getFirstUsername() {
      if (this.dataUser.username.length > 0) {
        this.firstUsername = this.dataUser.username.charAt(0).toUpperCase();
      } else {
        this.firstUsername = '';
      }
    },
    updateProfile() {
      this.$apiService
        .updateUserProfile(this.dataUserUpdate)
        .then(() => {
          this.reDisplay();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].ProfileSetting.SuccessTransfer)
        })
        .catch((error) => {
          console.error("Lỗi khi lấy cập nhật dữ liệu:", error);
        });
    },

    formatCurrency(number) {
      const formattedCurrency = number.toLocaleString("en-US");
      return formattedCurrency;
      },
      openModalTransferMoney(){
        this.isShowModalTransferMoney = true;
      },
      closeModalTransferMoney(){
        this.isShowModalTransferMoney = false;
      },

      addTransferMoney(){
       
        this.$apiService.transferMoneyUser(this.infoTransfer).then(()=>{
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].ProfileSetting.SuccessUpdateInfo)
          this.isShowModalTransferMoney = false;
        }).catch((error) => {
          this.swalError(error.response.data.message)
        });
      },
      roundTwoDecimals(number) {
      return Math.floor(number * 100) / 100;
    }
  },
};
</script>
