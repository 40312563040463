<template>
  <div id="kt_app_content_container" class="app-container container-xxl" >
    <!--begin::Card-->
    <div class="card" style="padding-top: 10px;">
      <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <!--begin::Title-->
          <h1 class="page-heading d-flex text-gray-900 fw-bold fs-0 flex-column justify-content-center my-0" style="font-size: 30px;">Thông báo</h1>
          <!--end::Title-->
        </div>
        <!--end::Page title-->
      </div>

      <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack" style="margin: 15px 0px">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-row justify-content-center flex-wrap me-3">
          <!--begin::Title-->
          <button class="btn-notification" :class="{'btn-active': this.onListAll == true}" @click="openListAll"> Tất cả</button>
          <button class="btn-notification" :class="{'btn-active': this.onListAll == false}" @click="closeListAll">Chưa đọc</button>
          <!--end::Title-->
        </div>
        <!--end::Page title-->
      </div>

      <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <!--begin::Title-->
          <h2 class="page-heading d-flex text-gray-900 fs-0 flex-column justify-content-center my-0" style="font-weight: 500;">Trước đó</h2>
          <!--end::Title-->
        </div>
        <!--end::Page title-->
      </div>
      <!--begin::Card body-->
      <div class="card-body pt-0">
        <!--begin::Table-->
        <div
          id="kt_customers_table_wrapper"
          class="dataTables_wrapper dt-bootstrap4 no-footer"
        >
          <div class="table-responsive">
            <table
              class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
              id="kt_customers_table"
            >
              
              <tbody class="fw-semibold text-gray-600">

                <tr class="odd" v-for="(notification,index) in listNotification" :key="index" style="cursor: pointer;">
                  <td>
                    <div class="symbol symbol-35px me-4">
                        <span class="symbol-label bg-light-primary">
                          <i
                            class="ki-duotone ki-abstract-28 fs-2 text-primary"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                        </span>
                      </div>
                  </td>
                  <td class="message">
                    <a 
                      class="text-gray-800 text-hover-primary mb-1" :class="{'not-reading-message': notification.readingStatus == 'Chưa đọc'}"
                      >{{ notification.message }}</a
                    >
                  </td>
                  <td>
                    <div :class="{'not-reading':notification.readingStatus == 'Chưa đọc'}">

                    </div>
                  </td>
                </tr>
                
              </tbody>
            </table>
          </div>
          
        </div>
        <!--end::Table-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Card-->
  </div>
</template>

<style scoped>
.not-reading-message{
  font-weight: bold;
}
.table {
    overflow: initial !important;
    height: auto !important;
    font-size: 20px !important;
}
.container-xxl{
  max-width: 700px;
}
.not-reading{
  width: 15px;
  height: 15px;
  background: #0866ff;
  border-radius: 50px;
}
.message{
  min-width: 475px;
  padding-right: 0px;
  text-align: start;
}
.btn-notification:hover{
  border-radius: 30px;
  background-color: #e4e6eb;
}
.btn-notification{
  margin-right: 10px;
  font-weight: bold;
  border: none;
  background-color: #fff;
  height: 45px;
  font-size: 18px;
  width: 100px;
}
.btn-active,.btn-active:hover{
  border-radius: 30px;
  background-color: #ebf5ff;
  color:#0064d1;
}

</style>

<script>
  export default {
    name: "ListNotification",
    data() {
      return {
        listNotification:[],
        onListAll:true,
      }
    },
    created() {
      this.$apiService.getUserProfile().then((res) => {
      this.userCurrent = res.data.data;
      this.$apiService
        .getAllNotification(res.data.data.userId)
        .then((response) => {
          this.listNotification = response.data.data;
          
        });
    }).catch(()=>{

    });
    },
    methods: {
      openListAll(){
        this.onListAll = true;
        this.reDisplay();
      },
      closeListAll(){
        this.onListAll = false;
        this.onListNotRead();
      },
      reDisplay(){
        this.$apiService.getUserProfile().then((res) => {
        this.userCurrent = res.data.data;
        this.$apiService
          .getAllNotification(res.data.data.userId)
          .then((response) => {
            this.listNotification = response.data.data;
            
          });
      }).catch(()=>{
        console.log("")
      });
      },
      onListNotRead(){
        this.$apiService.getUserProfile().then((res) => {
        this.userCurrent = res.data.data;
        this.$apiService
          .getAllNotificationNotRead(res.data.data.userId)
          .then((response) => {
            this.listNotification = response.data.data;
            
          });
      }).catch(()=>{
        console.log("")
      });
      }
    },
  }
</script>