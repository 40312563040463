<template style="overflow:hidden">
  <!--begin::Content wrapper-->
  <div class="d-flex flex-column flex-column-fluid">
    <!--begin::Toolbar-->
    <div
      id="kt_app_toolbar"
      class="app-toolbar py-3 py-lg-6"
      style="padding-top: 0px !important; padding-bottom: 10px !important"
    >
      <!--begin::Toolbar container-->
      <div
        id="kt_app_toolbar_container"
        class="app-container d-flex flex-stack"
      >
        <!--begin::Page title-->
        <div
          class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
        >
          <!--begin::Title-->
          <h1
            class="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0"
          >
          {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Title }}
          </h1>
          <!--end::Title-->
        </div>
        <!--end::Page title-->
      </div>
      <!--end::Toolbar container-->
    </div>
    <!--end::Toolbar-->
    <!--begin::Content-->
    <div id="kt_app_content" class="app-content flex-column-fluid">
      <!--begin::Content container-->
      <div id="kt_app_content_container" class="app-container">
        <!--begin::Card-->
        <div class="card">
          <!--begin::Card header-->
          <div
            class="card-header border-0 pt-6"
            style="align-items: center; padding-top: 0px !important"
          >
            <!--begin::Card title-->
            <div class="card-title">
              <!--begin::Search-->
              <div class="d-flex align-items-center position-relative my-1">
                <i class="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                  <span
                    @click="onEnterSearchFirst"
                    class="path1"
                    style="cursor: pointer; z-index: 2"
                  ></span>
                  <span class="path2"></span>
                </i>
                <input
                  v-on:keyup.enter="onEnterSearchFirst"
                  v-model="inputValue"
                  type="text"
                  class="form-control form-control-solid w-350px ps-12"
                  :placeholder="this.$NOLIMITResource[this.dataUser.language].AdsAccount.SearhText"
                />
              </div>
              <!--end::Search-->
            </div>
            <!--begin::Card title-->
            <!--begin::Card toolbar-->
            <div class="card-toolbar">
              <!--begin::Toolbar-->
              <div class="content__toolbar--table">
                <a id="selected__toolbar"
                  >{{ this.$NOLIMITResource[this.dataUser.language].Selected }} <span>{{ countSelected }}</span></a
                >

                <a @click="openAllModalTopUpAds()" class="selectedall__toolbar"
                  >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.TopupAll }}</a
                >
                <a @click="openModalUpdateAllCustomer()" class="selectedall__toolbar"
                  >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.CustomizeSelectedCustomers }}</a
                >
                <a @click="confirmUpdateAllAdsStatus()" class="selectedall__toolbar">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RequestUpdateAll }}</a>
                <a  v-if="this.isAdmin == true" @click="openAllModalTopUpFile()" class="selectedall__toolbar">{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.TopupByFile }}</a>

                <a @click="exportCSV()" class="selectedall__toolbar">{{ this.$NOLIMITResource[this.dataUser.language].DownloadCSV }}</a>
              </div>
              <i
                @click="toggleModalDisplayRow"
                class="ki-outline ki-gear fs-2x me-3"
              ></i>
              <div
                class="d-flex justify-content-end"
                data-kt-customer-table-toolbar="base"
                data-select2-id="select2-data-129-1zlu"
              >
                <!--begin::Filter-->
                <button
                  @click="toggleMenuFilter"
                  type="button"
                  class="btn btn-light-primary me-3"
                  data-kt-menu-trigger="click"
                  data-kt-menu-placement="bottom-end"
                >
                  <i class="ki-duotone ki-filter fs-2">
                    <span class="path1"></span>
                    <span class="path2"></span> </i
                  >{{ this.$NOLIMITResource[this.dataUser.language].FilterSearch }}
                </button>
                <!--begin::Menu 1-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-300px w-md-325px show-dropfilter"
                  :class="{ show: this.onShowMenuFilter == true }"
                  data-kt-menu="true"
                  id="kt-toolbar-filter"
                  data-select2-id="select2-data-kt-toolbar-filter"
                  style=""
                 
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-4 text-gray-900 fw-bold">
                      {{ this.$NOLIMITResource[this.dataUser.language].FilterOptions }}
                    </div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Separator-->

                  <div class="separator border-gray-200"></div>
                  <!--end::Separator-->
                  <form action="" @submit.prevent="onFilterSearchFirst">
                    <!--begin::Content-->
                    <div
                      class="px-7 py-5"
                      data-select2-id="select2-data-128-13oj"
                    >
                      <!--begin::Input group-->
                      <div>
                        <label class="form-label fs-5 fw-semibold mb-3"
                          > {{ this.$NOLIMITResource[this.dataUser.language].StartDate }}</label
                        >
                        <v-date-picker
                          class="inline-block h-full"
                          v-model="dateStart"
                          :timezone="timezone"
                        >
                          <template v-slot="{ inputValue, togglePopover }">
                            <div class="flex items-center">
                              <input
                                @click="togglePopover()"
                                :value="inputValue"
                                class="form-control form-control-solid flatpickr-input active"
                                name="calendar_event_end_date"
                                :placeholder="this.$NOLIMITResource[this.dataUser.language].StartDate"
                                id="kt_calendar_datepicker_end_date"
                                type="text"
                                readonly="readonly"
                                style="margin-bottom: 20px"
                              />
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                      <!--end::Input group-->
                      <!--begin::Input group-->
                      <div>
                        <label class="form-label fs-5 fw-semibold mb-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].EndDate }}</label
                        >
                        <v-date-picker
                          class="inline-block h-full"
                          v-model="dateEnd"
                          :timezone="timezone1"
                        >
                          <template v-slot="{ inputValue, togglePopover }">
                            <div class="flex items-center">
                              <input
                                @click="togglePopover()"
                                :value="inputValue"
                                class="form-control form-control-solid flatpickr-input active"
                                name="calendar_event_end_date"
                                :placeholder="this.$NOLIMITResource[this.dataUser.language].EndDate"
                                id="kt_calendar_datepicker_end_date"
                                type="text"
                                readonly="readonly"
                                style="margin-bottom: 20px"
                              />
                            </div>
                          </template>
                        </v-date-picker>
                      </div>
                      <!--end::Input group-->

                      <!--begin::Input group-->
                      <div class="mb-10">
                        <!--begin::Label-->
                        <label class="form-label fs-5 fw-semibold mb-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].Status }}:</label
                        >
                        <!--end::Label-->
                        <!--begin::Options-->
                        <div
                          class="d-flex flex-column flex-wrap fw-semibold"
                          data-kt-customer-table-filter="payment_type"
                        >
                          <!--begin::Option-->
                          <label
                            class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="payment_type"
                              value="Đang sử dụng"
                              v-model="this.status"
                            />
                            <div>
                              <span class="form-check-label text-gray-600"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusInUse }} </span
                              ><br />
                              <span
                                class="form-check-label text-gray-600"
                                style="font-size: 12px"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }}
                                {{ this.totalStatusUsing }}</span
                              >
                            </div>
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label
                            class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="payment_type"
                              value="Đã thu hồi"
                              v-model="this.status"
                            />
                            <div>
                              <span class="form-check-label text-gray-600"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusRecalled }}</span
                              ><br />
                              <span
                                class="form-check-label text-gray-600"
                                style="font-size: 12px"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }}
                                {{ this.totalStatusReturn }}</span
                              >
                            </div>
                          </label>
                          <!--end::Option-->
                           <!--begin::Option-->
                           <label
                            class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="payment_type"
                              value="Chờ thu hồi"
                              v-model="this.status"
                            />
                            <div>
                              <span class="form-check-label text-gray-600"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWaitingForRecall }}</span
                              ><br />
                              <span
                                class="form-check-label text-gray-600"
                                style="font-size: 12px"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }}
                                {{ this.totalStatusWaittingReturn }}</span
                              >
                            </div>
                          </label>
                          <!--end::Option-->
                        </div>
                        <!--end::Options-->
                      </div>
                      <!--end::Input group-->
                      <!--begin::Actions-->
                      <div class="d-flex justify-content-end">
                        <button
                          @click="onResetFilter"
                          type="reset"
                          class="btn btn-light btn-active-light-primary me-2"
                          data-kt-menu-dismiss="true"
                          data-kt-customer-table-filter="reset"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].Reset }}
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          data-kt-menu-dismiss="true"
                          data-kt-customer-table-filter="filter"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].Filter }}
                        </button>
                      </div>
                      <!--end::Actions-->
                    </div>
                    <!--end::Content-->
                  </form>
                </div>
                <!--end::Menu 1-->

                <!--begin::Menu 2-->
                <div
                  class="menu menu-sub menu-sub-dropdown w-300px w-md-325px show-dropfilter-2"
                  :class="{ show: this.onShowMenuFilterStatus == true }"
                  data-kt-menu="true"
                  id="kt-toolbar-filter"
                  data-select2-id="select2-data-kt-toolbar-filter"
                  style=""
                  
                >
                  <!--begin::Header-->
                  <div class="px-7 py-5">
                    <div class="fs-4 text-gray-900 fw-bold">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusAds }}
                    </div>
                  </div>
                  <!--end::Header-->
                  <!--begin::Separator-->

                  <div class="separator border-gray-200"></div>
                  <!--end::Separator-->
                  <form action="" @submit.prevent="onFilterStatusAds">
                    <!--begin::Content-->
                    <div
                      class="px-7 py-5"
                      data-select2-id="select2-data-128-13oj"
                    >
                      <!--begin::Input group-->
                      <div class="mb-10">
                        <!--begin::Options-->
                        <div
                          class="d-flex flex-column flex-wrap fw-semibold"
                          data-kt-customer-table-filter="payment_type"
                        >
                          <!--begin::Option-->
                          <label
                            class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="payment_type"
                              value="1"
                              v-model="this.statusAds"
                            />
                            <div>
                              <span class="form-check-label text-gray-600"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWork }}</span
                              ><br />
                              <span
                                class="form-check-label text-gray-600"
                                style="font-size: 12px"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }}
                                {{ this.totalStatusAdsUsing }}</span
                              >
                            </div>
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label
                            class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="payment_type"
                              value="2"
                              v-model="this.statusAds"
                            />
                            <div>
                              <span class="form-check-label text-gray-600"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusDisable }}</span
                              ><br />
                              <span
                                class="form-check-label text-gray-600"
                                style="font-size: 12px"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }}
                                {{ this.totalStatusAdsDisable }}</span
                              >
                            </div>
                          </label>
                          <!--end::Option-->
                          <!--begin::Option-->
                          <label
                            class="form-check form-check-sm form-check-custom form-check-solid mb-7 me-5"
                          >
                            <input
                              class="form-check-input"
                              type="radio"
                              name="payment_type"
                              value="3"
                              v-model="this.statusAds"
                            />
                            <div>
                              <span class="form-check-label text-gray-600"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusPaymentRequired }}</span
                              ><br />
                              <span
                                class="form-check-label text-gray-600"
                                style="font-size: 12px"
                                >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.NumberOfAccounts }}
                                {{ this.totalStatusAdsPayRequired }}</span
                              >
                            </div>
                          </label>
                          <!--end::Option-->
                         
                        </div>
                        <!--end::Options-->
                      </div>
                      <!--end::Input group-->
                      <!--begin::Actions-->
                      <div class="d-flex justify-content-end">
                        <button
                          @click="onResetFilterStatusAds"
                          type="reset"
                          class="btn btn-light btn-active-light-primary me-2"
                          data-kt-menu-dismiss="true"
                          data-kt-customer-table-filter="reset"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].Reset }}
                        </button>
                        <button
                          type="submit"
                          class="btn btn-primary"
                          data-kt-menu-dismiss="true"
                          data-kt-customer-table-filter="filter"
                        >
                        {{ this.$NOLIMITResource[this.dataUser.language].Filter }}
                        </button>
                      </div>
                      <!--end::Actions-->
                    </div>
                    <!--end::Content-->
                  </form>
                </div>
                <!--end::Menu 2-->
                <!--end::Filter-->
              </div>
              <!--end::Toolbar-->
              <!--begin::Group actions-->
              <div
                class="d-flex justify-content-end align-items-center d-none"
                data-kt-customer-table-toolbar="selected"
              >
                <div class="fw-bold me-5">
                  <span
                    class="me-2"
                    data-kt-customer-table-select="selected_count"
                  ></span
                  >Selected
                </div>
                <button
                  type="button"
                  class="btn btn-danger"
                  data-kt-customer-table-select="delete_selected"
                >
                  Delete Selected
                </button>
              </div>
              <!--end::Group actions-->
            </div>
            <!--end::Card toolbar-->
          </div>
          <!--end::Card header-->
          <!--begin::Card body-->
          <div class="card-body pt-0" style="margin-top: 20px;">
            <!--begin::Table-->
            <table
             
              class="table align-middle table-row-dashed fs-6 gy-5"
              id="kt_customers_table"
            >
              <thead>
                <tr
                  class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
                >
                  <th class="fix-data-0">
                    <div class="form-check">
                      <input
                        v-model="isCheckedAll"
                        class="form-check-input"
                        @click="selectAllRecord"
                        type="checkbox"
                        :class="{ active__record: onSelectAllRecord }"
                        id="flexCheckChecked"
                        :checked="this.selectAllRecord === true"
                      />
                      <label for="flexCheckChecked"></label>
                    </div>
                  </th>
                  <th
                    style="cursor: pointer"
                    @click="toggleFilterStatus()"
                    v-if="this.displayRow.adsStatus"
                    class="fix-data-1"
                  >
                    {{ this.$NOLIMITResource[this.dataUser.language].Status}}
                  </th>
                  <th v-if="this.displayRow.account" class="fix-data-2">
                    {{ this.$NOLIMITResource[this.dataUser.language].Account}}
                  </th>
                  <th>
                    {{ this.$NOLIMITResource[this.dataUser.language].TopupFee}}
                  </th>
                  <!-- <th v-if="this.displayRow.notes" class="min-w-125px" :style="{ 'min-width': this.columns[0].width + 'px !important'}"><div @mousedown="startResize($event,0)">{{ this.columns[0].label }}</div></th>
                    <th v-if="this.displayRow.lastUpdate" class="min-w-125px" :style="{ 'min-width': this.columns[16].width + 'px !important'}"><div @mousedown="startResize($event,16)">Ngày cập nhật gần nhất</div></th> -->
                  <template v-for="(column, index) in columns" :key="index">
                    <th
                      v-if="shouldDisplay(index)"
                      class="min-w-125px"
                      :class="{
                        sorting_desc: this.sortingNumber == index,
                        sorting_asc: this.sortingAsc == index,
                      }"
                      :style="{ 'min-width': column.width + 'px !important' }"
                    >
                      <template v-if="shouldDisplay(index)">
                        <div @mousedown="this.startResize($event, index)">
                          <span
                            @click="
                              onSorting(index, column.property, 'changeSort')
                            "
                            >{{ column.label }}</span
                          >
                        </div>
                      </template>
                    </th>
                  </template>
                  <th class="text-end min-w-70px"> {{ this.$NOLIMITResource[this.dataUser.language].Feature }}</th>
                </tr>
              </thead>
              <div class="loading-1" v-if="onLoading">
                <span class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
              </div>
              <div
                style="margin-top: 20px"
                v-if="!this.onLoading && this.hasData == false"
              >
              {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Nodata }}
              </div>
              <tbody 
                v-if="!this.onLoading && this.hasData == true"
                class="fw-semibold text-gray-600"
              >
                <tr
                  :class="{
                    active__record:
                      (selectRecord && recordActiveId == ad.adsId) ||
                      onSelectAllRecord,
                  }"
                  v-for="ad in ads"
                  :key="ad.adsId"
                >
                  <td class="fix-data-0">
                    <div class="form-check">
                      <input
                        :id="ad.adsId"
                        type="checkbox"
                        :value="ad.adsId"
                        name="check"
                        :class="{ count__record: isChecked(ad.adsId) }"
                        class="form-check-input"
                        v-model="checkboxChecked"
                      />
                      <label :for="ad.adsId"></label>
                    </div>
                  </td>
                  <td class="fix-data-1" v-if="this.displayRow.adsStatus">
                    <a class="text-gray-600 text-hover-primary mb-1">
                      <div
                        v-if="ad.adsStatus == '2'"
                        :class="`badge badge-light-danger`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusDisable }}
                      </div>
                      <div
                        v-if="ad.adsStatus == '1'"
                        :class="`badge badge-light-success`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWork }}
                      </div>
                     
                      <div
                        v-if="ad.adsStatus == '3'"
                        :class="`badge badge-light-primary`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusPaymentRequired }}
                      </div>
                      <div v-if="ad.adsStatus == '0'" :class="`badge badge-light-info`">
                        {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusUpdateting }}
                      </div>
                    </a>
                  </td>
                  <td class="fix-data-2" v-if="this.displayRow.account">
                    <a class="text-gray-600 text-hover-primary mb-1">{{
                      ad.name
                    }}</a
                    ><br />
                    <a @dblclick="copyText(ad.facebookAdsId)" class="copytext text-gray-800 text-hover-primary mb-1">{{
                      ad.facebookAdsId
                    }}</a>
                  </td>
                  <td>{{ ad.topupFee }}</td>
                  <td v-if="this.displayRow.notes">{{ ad.notes }}</td>
                  <td v-if="this.displayRow.shareStatus">
                    <a class="text-gray-600 text-hover-primary mb-1">
                      <div
                        v-if="ad.shareStatus == 'Đã thu hồi'"
                        :class="`badge badge-light-danger`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusRecalled }}
                      </div>
                      <div
                        v-if="ad.shareStatus == 'Đang sử dụng'"
                        :class="`badge badge-light-success`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusInUse }}
                      </div>
                      <div
                        v-if="ad.shareStatus == 'Chờ thu hồi'"
                        :class="`badge badge-light-warning`"
                      >
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWaitingForRecall }}
                      </div>
                    </a>
                  </td>
                  <td v-if="this.displayRow.lastTopupDate">
                    {{ formatDateTime(ad.lastTopupDate) }}
                  </td>
                  <td v-if="this.displayRow.createDate">
                    {{ formatDateTime(ad.createDate) }}
                  </td>
                  <td v-if="this.displayRow.spendingCap">
                    {{ formatCurrency(ad.spendingCap) }}
                  </td>
                  <!-- <td v-if="this.displayRow.remainingAmount">
                    {{ formatCurrency(ad.remainingAmount) }}
                  </td> -->
                  <td v-if="this.displayRow.customerNow">
                    {{ ad.customerNow }}
                  </td>
                  <td v-if="this.displayRow.supporter">{{ ad.supporter }}</td>
                  <td v-if="this.displayRow.returnDate">
                    {{ formatDateTime(ad.returnDate) }}
                  </td>
                  <td v-if="this.displayRow.paymentMethod">
                    {{ ad.paymentMethod }}
                  </td>
                  <td v-if="this.displayRow.timezone">{{ ad.timezone }}</td>
                  <td v-if="this.displayRow.currency">{{ ad.currency }}</td>
                  <td v-if="this.displayRow.nationallity">
                    {{ ad.nationallity }}
                  </td>
                  <td v-if="this.displayRow.idBusinessPartner">
                    {{ ad.idBusinessPartner }}
                  </td>
                  <td v-if="this.displayRow.dailyAmountTopUp">
                    {{ formatCurrency(ad.dailyAmountTopUp) }}
                  </td>
                  <td v-if="this.displayRow.totalAmountTopUp">
                    {{ formatCurrency(ad.totalAmountTopUp) }}
                  </td>
                  <td v-if="this.displayRow.lastUpdate">
                    {{ formatDateTime(ad.lastUpdate) }}
                  </td>

                  <td
                    class="text-end"
                    :class="{
                      active__update__combobox:
                        onShowMenu && currenOnshowId === ad.adsId,
                    }"
                  >
                    <a
                      @click="toggleMenuItem(ad.adsId)"
                      class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary show menu-dropdown"
                      data-kt-menu-trigger="click"
                      data-kt-menu-placement="bottom-end"
                      > {{ this.$NOLIMITResource[this.dataUser.language].SeeDetail }} <i class="ki-duotone ki-down fs-5 ms-1"></i
                    ></a>
                    <!--begin::Menu-->
                    <div
                      class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4"
                      :class="{
                        show: onShowMenu && currenOnshowId === ad.adsId,
                      }"
                      data-kt-menu="true"
                      @mouseleave="closeMenuItem"
                    >
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <a @click="openModalTopUpAds(ad.adsId)" class="menu-link px-3"
                          > {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.CreateTopup }}
 </a
                        >
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <!--begin::Menu item-->
                        <router-link
                          :to="{
                            name: 'TopUpListByAds',
                            query: { idAds: ad.adsId },
                          }"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.TopupHistory }}</router-link
                        >
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <!--begin::Menu item-->
                        <router-link
                          :to="{
                            name: 'AdsHistory',
                            query: { idAds: ad.adsId },
                          }"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.AdsHistory }}</router-link
                        >
                      </div>
                      <!--end::Menu item-->

                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <!--begin::Menu item-->
                        <a
                          @click="confirmReturnAccount(ad.adsId,this.idUser)"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.RequestReturn }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                      <!--begin::Menu item-->
                      <div class="menu-item px-3">
                        <!--begin::Menu item-->
                        <a
                          @click="openUpdateCustomer(ad.adsId)"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.CustomerCustomization }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                         <!--begin::Menu item-->
                         <div class="menu-item px-3">
                        <!--begin::Menu item-->
                        <a
                          @click="confirmRefund(ad.adsId)"
                          class="menu-link px-3"
                          >{{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Refund }}</a
                        >
                      </div>
                      <!--end::Menu item-->
                    </div>
                    <!--end::Menu-->
                  </td>
                </tr>
                <tr id="total">
                   <td class="fix-data-footer"  colspan="3" style="font-weight: bold"> <img src="./../../../../public/frontend/media/logos/logo3.png" style="width:30px;height:auto"/>  {{this.adsAll.length}}  {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Ads }}</td> 
                   <td></td>
                   <td v-if="this.displayRow.notes"></td>
                   <td v-if="this.displayRow.shareStatus"></td>
                   <td v-if="this.displayRow.lastTopupDate"></td>
                    <td v-if="this.displayRow.createDate"></td>
                    <td v-if="this.displayRow.spendingCap">{{formatCurrency(totalSpencap)}} đ</td>
                    <td v-if="this.displayRow.customerNow"></td>
                    <td v-if="this.displayRow.supporter"></td>
                    <td v-if="this.displayRow.returnDate"></td>
                    <td v-if="this.displayRow.paymentMethod">                    
                    </td>          
                    <td v-if="this.displayRow.timezone"></td>
                    <td v-if="this.displayRow.currency"></td>
                    <td v-if="this.displayRow.nationallity"></td>              
                    <td v-if="this.displayRow.idBusinessPartner"></td>
                    <td v-if="this.displayRow.dailyAmountTopUp"></td>
                    <td v-if="this.displayRow.totalAmountTopUp"></td>
                    <td v-if="this.displayRow.lastUpdate"></td>
                </tr>
              </tbody>

            </table>
            <div class="row">
              <div
                class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"
              >
                <div class="dataTables_length" id="kt_customers_table_length">
                  <label>
                    <v-select
                      :calculate-position="withPopper"
                      v-model="this.pageSize"
                      id="styleSelect"
                      :options="numberPerPage"
                      label="value"
                    ></v-select>
                  </label>
                </div>
              </div>
              <div
                class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"
              >
                <div
                  class="dataTables_paginate paging_simple_numbers"
                  id="kt_customers_table_paginate"
                >
                  <ul v-if="this.totalPage >= 4" class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{ disabled: this.pageNumber == 1 }"
                      id="kt_customers_table_previous"
                    >
                      <a
                        @click="prevPage"
                        aria-controls="kt_customers_table"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link"
                        ><i class="previous"></i
                      ></a>
                    </li>
                    <li
                      v-for="index in 4"
                      :key="index"
                      class="paginate_button page-item"
                      :class="{ active: pageNumber == this.firstPage + index }"
                    >
                      <a
                        @click="selectPage(this.firstPage + index)"
                        aria-controls="kt_customers_table"
                        data-dt-idx="1"
                        tabindex="0"
                        class="page-link"
                        >{{ this.firstPage + index }}</a
                      >
                    </li>
                    <li
                      class="paginate_button page-item next"
                      :class="{ disabled: this.pageNumber == this.totalPage }"
                      id="kt_customers_table_next"
                    >
                      <a
                        @click="nextPage"
                        aria-controls="kt_customers_table"
                        data-dt-idx="5"
                        tabindex="0"
                        class="page-link"
                        ><i class="next"></i
                      ></a>
                    </li>
                  </ul>
                  <ul v-if="this.totalPage < 4" class="pagination">
                    <li
                      class="paginate_button page-item previous"
                      :class="{ disabled: this.pageNumber == 1 }"
                      id="kt_customers_table_previous"
                    >
                      <a
                        @click="prevPage"
                        aria-controls="kt_customers_table"
                        data-dt-idx="0"
                        tabindex="0"
                        class="page-link"
                        ><i class="previous"></i
                      ></a>
                    </li>
                    <li
                      v-for="index in totalPage"
                      :key="index"
                      class="paginate_button page-item"
                      :class="{ active: pageNumber == this.firstPage + index }"
                    >
                      <a
                        @click="selectPage(this.firstPage + index)"
                        aria-controls="kt_customers_table"
                        data-dt-idx="1"
                        tabindex="0"
                        class="page-link"
                        >{{ this.firstPage + index }}</a
                      >
                    </li>
                    <li
                      class="paginate_button page-item next"
                      :class="{ disabled: this.pageNumber == this.totalPage }"
                      id="kt_customers_table_next"
                    >
                      <a
                        @click="nextPage"
                        aria-controls="kt_customers_table"
                        data-dt-idx="5"
                        tabindex="0"
                        class="page-link"
                        ><i class="next"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
         <!--begin::Modal - User - Update - Customer-->
         <div
          class="modal fade show"
          :class="{ block: onShowModalCustomer }" 
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="updateCustomer"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold">Khách hàng tùy chỉnh</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeUpdateCustomer"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>                                                                                                                                                                     
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>Nội dung</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          title="Bạn có thể thêm thông tin doanh nghiệp để dễ quản lý"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="customerInput"
                      />
                      <div v-if="this.errors.userId != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                        <div data-field="userId" data-validator="notEmpty">{{ this.errors.userId }}</div>
                      </div>
                    </div>
                    <!--end::Input group-->
                     
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeUpdateCustomer"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                    Hủy bỏ
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">Lưu tùy chỉnh</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          
        </div>
        <!--end::Modal - User - Update - Customer-->

 <!--begin::Modal - User - Update - Batch - Customer-->
 <div
          class="modal fade show"
          :class="{ block: onShowModalAllCustomer }" 
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="updateBatchCustomer"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold">Khách hàng tùy chỉnh</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalUpdateAllCustomer"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>                                                                                                                                                                     
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>Nội dung</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          title="Bạn có thể thêm thông tin doanh nghiệp để dễ quản lý"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="customerBatchInput"
                      />
                      <div v-if="this.errors.userId != ''" class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback">
                        <div data-field="userId" data-validator="notEmpty">{{ this.errors.userId }}</div>
                      </div>
                    </div>
                    <!--end::Input group-->
                     
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalUpdateAllCustomer"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                    Hủy bỏ
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">Lưu tùy chỉnh</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
          
        </div>
        <!--end::Modal - User - Update - Batch - Customer-->


      <!--begin::Modal - Topup - File -->
      <div
          class="modal fade show"
          :class="{ block: isShowModalTopUpFile }" 
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
          style="z-index: 110 !important"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered modal-topup-file">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="addBatchTopUpOrdersFile"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header" style="border-bottom: none;">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold" style="font-size:17px;position: relative;padding-right: 20px;padding-bottom: 7px;" >TẠO ĐƠN TOPUP HÀNG LOẠT TÀI KHOẢN THEO FILE
                  <span  class="bullet-custom position-absolute z-index-2 bottom-0 w-100 style-line-title rounded"></span>
                </h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeAllModalTopUpFile"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>                                                                                                                                                                     
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <div class="content__toolbar--table" style="text-align: start;margin-left: 25px;">

                <a class="text-info-topupfile text-success" 
                  >Thành công: {{ countSuccessFileTopup }}</a
                >
                <a class="text-info-topupfile text-danger"
                  >Thất bại: {{countFaileFileTopup}}</a
                >
                <a class="text-info-topupfile text-primary">Tổng tiền: {{ formatCurrency(totalMoneyTopupFile) }}</a>
                <a class="text-info-topupfile text-primary">Tổng phí: {{ formatCurrency(totalFeeTopupFile) }}</a>


                <span class="badge badge-light-success fs-base button-export-topupfile" >                                
                    <i class="ki-duotone ki-file-right   fs-5 text-success ms-n1 icon-export-topupfile"><span class="path1"></span><span class="path2"></span></i> 
                   
                  <label for="csv_file" id="label_csv_file">Nhập file</label>
                   <input type="file" id="csv_file" @change="loadCSV($event)" name="csv_file"/>
                </span>

                <span class="badge badge-light-success fs-base button-export-topupfile" @click="exportCSVFormTopupFile">                                
                    <i class="ki-duotone ki-file-left   fs-5 text-success ms-n1 icon-export-topupfile"><span class="path1"></span><span class="path2"></span></i> 
                   File mẫu
                </span>

                <a @click="reloadCSV"  style="margin-bottom:8px" class="btn btn-sm btn-icon btn-light btn-active-light-primary" data-bs-toggle="tooltip" data-bs-dismiss="click" data-bs-placement="top" aria-label="Reload" data-bs-original-title="Reload" data-kt-initialized="1">
                     <i class="ki-duotone ki-arrows-circle fs-2"><span class="path1"></span><span class="path2"></span></i>    
                </a>
              </div>

            
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    
                  <table 
             
             class="table align-middle table-row-dashed fs-6 gy-5 table-topupfile"
             id="kt_customers_table"
           >
             <thead>
               <tr
                 class="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0"
               >
                
                 <template v-for="(column, index) in columnsTopupFile" :key="index">
                   <th
                     v-if="shouldDisplay(index)"
                     class="min-w-125px"
                     :class="{
                       sorting_desc: this.sortingNumber == index,
                       sorting_asc: this.sortingAsc == index,
                     }"
                     :style="{ 'min-width': column.width + 'px !important' }"
                   >
                     <template v-if="shouldDisplay(index)">
                       <div @mousedown="this.startResize($event, index)">
                         <span
                           @click="
                             onSorting(index, column.property, 'changeSort')
                           "
                           >{{ column.label }}</span
                         >
                       </div>
                     </template>
                   </th>
                 </template>
               </tr>
             </thead>
             <div class="loading-1" v-if="onLoadingFile == true">
               <span class="spinner-border text-primary" role="status">
                 <span class="visually-hidden">Loading...</span>
               </span>
             </div>
           
             <tbody 
               v-if="!this.onLoadingFile"
               class="fw-semibold text-gray-600 tbody-topupFile"
             >
               <tr
                 :class="{
                   active__record:
                     (selectRecord && recordActiveId == ad.adsId) ||
                     onSelectAllRecord,
                 }"
                v-for="(csv,key) in filteredCsv" :key="key"
               >
                <td>{{ key+1 }}</td> 
               <td v-for="(value, key) in parse_header" :key="key">{{ csv[value] }}</td>
               <td>{{ csv.currency }}</td>
               <td>{{ csv.fee }}</td>
               <td>{{ csv.feeAmount }}</td>
               <td>{{ csv.status }}</td>
               <td>{{ csv.note }}</td>
               </tr>
             </tbody>

           </table>
                     
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeAllModalTopUpFile"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                    Hủy bỏ
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">Tạo đơn topup</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->  
              </form>
              <!--end::Form-->
            </div>
          </div>
          
        </div>
        <!--end::Modal - Topup - File -->

        <!--begin::Modal - User - Update-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalUpdateAds }"
          id="kt_modal_add_customer"
          tabindex="-1"
          aria-hidden="true"
        >
          <!--begin::Modal dialog-->
          <div class="modal-dialog modal-dialog-centered mw-650px">
            <!--begin::Modal content-->
            <div class="modal-content">
              <!--begin::Form-->
              <form
                v-if="this.formModeTopup === 'TopUp'"
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="addTopUpOrder"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold">TopUp Order</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalUpdateAds"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.TheAmountAddedToTheAds }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].AdsAccount.TheAmountAddedToTheAds"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="topUpOrder.amount"
                      />
                    </div>
                    <!--end::Input group-->

                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>{{ this.$NOLIMITResource[this.dataUser.language].TopupFee }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].AdsAccount.TopupFeeTitle"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="formattedFee"
                        readonly
                      />
                    </div>
                    <!--end::Input group-->
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>{{this.$NOLIMITResource[this.dataUser.language].AdsAccount.TotalAmountNeedPayment}}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].AdsAccount.TotalAmountPaymentTitle"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        readonly
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="formatAmountDisplay"
                      />
                    </div>
                    <div
                      class="fv-row mb-7 fv-plugins-icon-container fv-plugins-bootstrap5-row-valid"
                      data-select2-id="select2-data-133-r1eg"
                    >
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span>{{this.$NOLIMITResource[this.dataUser.language].Currency}}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].Currency"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--begin::Input-->
                      <input
                        readonly
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="this.adsCurrency"
                      />
                      <!--end::Input-->
                      <div
                        class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"
                      ></div>
                    </div>
                    <!--end::Input group-->
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalUpdateAds"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  {{this.$NOLIMITResource[this.dataUser.language].Cancel}}
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">{{this.$NOLIMITResource[this.dataUser.language].Add}}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
              <!--begin::Form-->
              <form
                v-else
                class="form"
                id="kt_modal_add_customer_form"
                action=""
                @submit.prevent="addAllTopUpOrder"
              >
                <!--begin::Modal header-->
                <div class="modal-header" id="kt_modal_add_customer_header">
                  <!--begin::Modal title-->
                  <h2 class="fw-bold"> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.AddManyTopupTitle }}</h2>
                  <!--end::Modal title-->
                  <!--begin::Close-->
                  <div
                    @click="closeModalUpdateAds"
                    id="kt_modal_add_customer_close"
                    class="btn btn-icon btn-sm btn-active-icon-primary"
                  >
                    <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                    </i>
                  </div>
                  <!--end::Close-->
                </div>
                <!--end::Modal header-->
                <!--begin::Modal body-->
                <div class="modal-body py-10 px-lg-17">
                  <!--begin::Scroll-->
                  <div
                    class="scroll-y me-n7 pe-7"
                    id="kt_modal_add_customer_scroll"
                    data-kt-scroll="true"
                    data-kt-scroll-activate="{default: false, lg: true}"
                    data-kt-scroll-max-height="auto"
                    data-kt-scroll-dependencies="#kt_modal_add_customer_header"
                    data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
                    data-kt-scroll-offset="300px"
                  >
                    <!--begin::Input group-->
                    <div class="d-flex flex-column mb-7 fv-row">
                      <!--begin::Label-->
                      <label
                        class="d-flex align-items-center fs-6 fw-semibold form-label mb-2"
                      >
                        <span> {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.TheAmountAddedToTheAds }}</span>
                        <span
                          class="ms-1"
                          data-bs-toggle="tooltip"
                          :title="this.$NOLIMITResource[this.dataUser.language].AdsAccount.TheAmountAddedToTheAds"
                        >
                          <i
                            class="ki-duotone ki-information-5 text-gray-500 fs-6"
                          >
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                        </span>
                      </label>
                      <!--end::Label-->
                      <input
                        type="text"
                        class="form-control form-control-solid"
                        placeholder=""
                        name="card_name"
                        v-model="topUpOrder.amount"
                      />
                    </div>
                    <!--end::Input group-->
                   
                     
                  </div>
                  <!--end::Scroll-->
                </div>
                <!--end::Modal body-->
                <!--begin::Modal footer-->
                <div class="modal-footer flex-center">
                  <!--begin::Button-->
                  <button
                    @click="closeModalUpdateAds"
                    type="reset"
                    id="kt_modal_add_customer_cancel"
                    class="btn btn-light me-3"
                  >
                  {{ this.$NOLIMITResource[this.dataUser.language].Cancel }}
                  </button>
                  <!--end::Button-->
                  <!--begin::Button-->
                  <button
                    type="submit"
                    id="kt_modal_add_customer_submit"
                    class="btn btn-primary"
                  >
                    <span class="indicator-label">{{ this.$NOLIMITResource[this.dataUser.language].AddAll }}</span>
                    <span class="indicator-progress"
                      >Please wait...
                      <span
                        class="spinner-border spinner-border-sm align-middle ms-2"
                      ></span
                    ></span>
                  </button>
                  <!--end::Button-->
                </div>
                <!--end::Modal footer-->
              </form>
              <!--end::Form-->
            </div>
          </div>
        </div>
        <!--end::Modal - User - Update-->

        <!--begin::Modal - Pickrow-->
        <div
          class="modal fade show"
          :class="{ block: isShowModalPickRow }"
        ></div>
        <div
          id="kt_drawer_chat"
          class="bg-body drawer drawer-end modal-pick-properties"
          :class="{ 'drawer-on': isShowModalPickRow }"
          data-kt-drawer="true"
          data-kt-drawer-name="chat"
          data-kt-drawer-activate="true"
          data-kt-drawer-overlay="true"
          data-kt-drawer-width="{default:'300px', 'md': '500px'}"
          data-kt-drawer-direction="end"
          data-kt-drawer-toggle="#kt_drawer_chat_toggle"
          data-kt-drawer-close="#kt_drawer_chat_close"
          style="z-index: 50000"
        >
          <!--begin::Messenger-->
          <div
            class="card w-100 border-0 rounded-0"
            id="kt_drawer_chat_messenger"
          >
            <!--begin::Card header-->
            <div class="card-header pe-5" id="kt_drawer_chat_messenger_header">
              <!--begin::Title-->
              <div class="card-title">
                <!--begin::User-->
                <div class="d-flex justify-content-center flex-column me-3">
                  <a
                    href="#"
                    class="fs-4 fw-bold text-gray-900 text-hover-primary me-1 lh-1"
                    > {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ChooseDisplayColumns }}
 </a
                  >
                </div>
                <!--end::User-->
              </div>
              <!--end::Title-->
              <!--begin::Card toolbar-->
              <div class="card-toolbar">
                <!--begin::Menu-->
                <div class="me-0">
                  <!--begin::Menu 3-->
                  <div
                    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3"
                    data-kt-menu="true"
                  >
                    <!--begin::Heading-->
                    <div class="menu-item px-3">
                      <div
                        class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase"
                      >
                        Contacts
                      </div>
                    </div>
                    <!--end::Heading-->
                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class="menu-link px-3"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_users_search"
                        >Add Contact</a
                      >
                    </div>
                    <!--end::Menu item-->
                    <!--begin::Menu item-->
                    <div class="menu-item px-3">
                      <a
                        href="#"
                        class="menu-link flex-stack px-3"
                        data-bs-toggle="modal"
                        data-bs-target="#kt_modal_invite_friends"
                        >Invite Contacts
                        <span
                          class="ms-2"
                          data-bs-toggle="tooltip"
                          aria-label="Specify a contact email to send an invitation"
                          data-bs-original-title="Specify a contact email to send an invitation"
                          data-kt-initialized="1"
                        >
                          <i class="ki-duotone ki-information fs-7">
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i> </span
                      ></a>
                    </div>
                    <!--end::Menu item-->
                    <!--begin::Menu item-->
                    <div
                      class="menu-item px-3"
                      data-kt-menu-trigger="hover"
                      data-kt-menu-placement="right-start"
                    >
                      <a href="#" class="menu-link px-3">
                        <span class="menu-title">Groups</span>
                        <span class="menu-arrow"></span>
                      </a>
                      <!--begin::Menu sub-->
                      <div class="menu-sub menu-sub-dropdown w-175px py-4">
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="#"
                            class="menu-link px-3"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Coming soon"
                            data-kt-initialized="1"
                            >Create Group</a
                          >
                        </div>
                        <!--end::Menu item-->
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="#"
                            class="menu-link px-3"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Coming soon"
                            data-kt-initialized="1"
                            >Invite Members</a
                          >
                        </div>
                        <!--end::Menu item-->
                        <!--begin::Menu item-->
                        <div class="menu-item px-3">
                          <a
                            href="#"
                            class="menu-link px-3"
                            data-bs-toggle="tooltip"
                            data-bs-original-title="Coming soon"
                            data-kt-initialized="1"
                            >Settings</a
                          >
                        </div>
                        <!--end::Menu item-->
                      </div>
                      <!--end::Menu sub-->
                    </div>
                    <!--end::Menu item-->
                    <!--begin::Menu item-->
                    <div class="menu-item px-3 my-1">
                      <a
                        href="#"
                        class="menu-link px-3"
                        data-bs-toggle="tooltip"
                        data-bs-original-title="Coming soon"
                        data-kt-initialized="1"
                        >Settings</a
                      >
                    </div>
                    <!--end::Menu item-->
                  </div>
                  <!--end::Menu 3-->
                </div>
                <!--end::Menu-->
                <!--begin::Close-->
                <div
                  class="btn btn-sm btn-icon btn-active-color-primary"
                  id="kt_drawer_chat_close"
                >
                  <i
                    @click="toggleModalDisplayRow"
                    class="ki-duotone ki-cross-square fs-2"
                  >
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                </div>
                <!--end::Close-->
              </div>
              <!--end::Card toolbar-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <form action="" @submit.prevent="onSubmitModalPickRow">
              <div class="card-body" id="kt_drawer_chat_messenger_body">
                <!--begin::Messages-->

                <div
                  class="scroll-y me-n5 pe-5"
                  data-kt-element="messages"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="true"
                  data-kt-scroll-height="auto"
                  data-kt-scroll-dependencies="#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer"
                  data-kt-scroll-wrappers="#kt_drawer_chat_messenger_body"
                  data-kt-scroll-offset="0px"
                  style="height: calc(100vh - 200px); overflow: scroll"
                >
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.notes"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].Note }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.shareStatus"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.SystemStatus }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.lastTopupDate"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.LastTopup }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.createDate"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].CreatedDate }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.spendingCap"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.SpenCap }}
                    </label>
                  </div>
                  <!-- <div class="form-check">
                    <input
                      v-model="this.checkRow.remainingAmount"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      Số dư
                    </label>
                  </div> -->

                  <div class="form-check">
                    <input
                      v-model="this.checkRow.customerNow"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Customer }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.supporter"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Supporter }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.returnDate"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.ReturnDate }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.paymentMethod"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.PaymentMethod }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.timezone"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Timezone }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.currency"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].Currency }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.nationallity"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.Nation }}
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      v-model="this.checkRow.idBusinessPartner"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.PartnerBusinessID }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.dailyAmountTopUp"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.DepositAmountToday }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.totalAmountTopUp"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.TotalAmountTopup }}
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      v-model="this.checkRow.lastUpdate"
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="flexCheckChecked"
                      checked
                    />
                    <label class="form-check-label" for="flexCheckChecked">
                      {{ this.$NOLIMITResource[this.dataUser.language].AdsAccount.LastUpdate }}
                    </label>
                  </div>
                </div>
                <!--end::Messages-->
              </div>
              <!--end::Card body-->
              <!--begin::Card footer-->
              <div
                class="card-footer pt-4"
                id="kt_drawer_chat_messenger_footer"
              >
                <!--begin:Toolbar-->
                <div
                  class="d-flex flex-stack"
                  style="justify-content: flex-end"
                >
                  <!--begin::Send-->
                  <button
                    class="btn btn-primary"
                    type="submit"
                    data-kt-element="send"
                  >
                  {{ this.$NOLIMITResource[this.dataUser.language].Save }}
                  </button>
                  <!--end::Send-->
                </div>
                <!--end::Toolbar-->
              </div>
            </form>
            <!--end::Card footer-->
          </div>
          <!--end::Messenger-->
        </div>
        <!--end::Modal - Pickrow-->
      </div>
      <!--end::Content container-->
    </div>
    <!--end::Content-->
  </div>
  
</template>
  <style>

/* .copytext:before{
  content:"Copied";
  position: absolute;
  top:-50px;
  right:0px;
  background:#5c81dc;
  padding:8px 10px;
  border-radius:20px;
  font-size:15px;
} */
/* .copytext:after{
  content:"";
  position:absolute;
  top
}  */
  th{
    color:#000 !important;
  }
.vs__clear {
  display: none !important;
}
.table th {
  height: 40px !important;
}

.table thead {
  position: sticky;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 6;
}
.table td {
  padding: 10px;
}

.vs__dropdown-toggle {
  border: none;
  min-width: 80px;
}

.fix-data-0,
.fix-data-1,
.fix-data-2 {
  z-index: 2;
}

th.sorting_desc:after {
  margin-left: 5px;
  top: 50%;
  content: "\25bc";
}

th.sorting_asc:after {
  margin-left: 5px;
  top: 50%;
  content: "\25b2";
}

th div {
  display: inline;
}
@media only screen and (max-width: 5000px){
  .fix-data-0 {
  position: sticky !important;
  left: 0px;
  top: 0px;
  background: #fff;
}
.fix-data-1 {
  position: sticky !important;
  left: 61px;
  top: 0px;
  background: #fff;
  min-width: 120px !important;
}
.fix-data-2 {
  position: sticky !important;
  left: 182px;
  top: 0px;
  background: #fff;
  min-width: 150px !important;
}
.table thead th:last-child,
.table tbody td:last-child {
  position: sticky;
  right: -1px;
  top: 4px;
  min-width: 150px !important;
  width: 120px;
  display: flex;
  align-items: center;
  z-index: 2;
  border-top: none;
  background: #fff;
  border-bottom: none;
}
 

table.table-topupfile thead th:last-child,
table.table-topupfile tbody td:last-child {
  position: relative !important;
  right: -1px;
  top: 4px;
  min-width: 150px !important;
  width: 120px;
  display: flex;
  align-items: center;
  z-index: 2;
  border-top: none;
  background: #fff;
  border-bottom: none;
}
}

@media only screen and (max-width: 1600px) {
.w-350px{
  width: 350px !important;
}
.modal-pick-properties{
  width: 500px !important;
}

.fix-data-0 {
  position: static !important;
  left: 0px;
  top: 0px;
  background: #fff;
}
.fix-data-1 {
  position: static !important;  
  left: 61px;
  top: 0px;
  background: #fff;
  min-width: 120px !important;
}
.fix-data-2 {
  position: static !important;
  left: 182px;
  top: 0px;
  background: #fff;
  min-width: 150px !important;
}
.table thead th:last-child,
.table tbody td:last-child {
  position: relative;
  right: -1px;
  top: 4px !important;
  min-width: 150px !important;
  width: 120px;
  display: flex;
  align-items: center;
  z-index: 2;
  border-top: none;
  background: #fff;

}
table.table-topupfile thead th:last-child,
table.table-topupfile tbody td:last-child {
  position: relative !important;
  right: -1px;
  top: 4px;
  min-width: 150px !important;
  width: 120px;
  display: flex;
  align-items: center;
  z-index: 2;
  border-top: none;
  background: #fff;
  border-bottom: none;
}

}
@media only screen and (max-width: 480px) {
.w-350px{
  width: 200px !important;
}
}
@media only screen and (max-width: 650px) {
  .modal-pick-properties{
  width: 270px !important;
}
}

@media only screen and (min-width: 1700px) {
.block {
    margin-left: 250px;
}
}
@media only screen and (min-width: 992px) {
  .block {
    margin-left: 250px;
}
}
.selectedall__toolbar {
  color: #1565c0;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
}
#selectedall__toolbar {
  color: #1565c0;
  font-weight: 600;
  margin-right: 25px;
  cursor: pointer;
}
</style>  
  <style scoped>
  .tbody-topupFile td{
    min-width:200px !important;
  }
  input[type="file"]{
    display: none;
  }
  #label_csv_file{
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }
  .button-export-topupfile{
    margin-right: 20px;
    background: #6dcdd0;
    color: #fff;
    padding: 10px;
  }
  .button-export-topupfile:hover{
    background:#94dddf;
  }
  .icon-export-topupfile{
    color: #fff !important;
    margin-right: 5px;
    margin-top: 2px;
  }
  .text-info-topupfile{
    color: #1565c0;
  font-weight: 500;
  margin-right: 70px;
  cursor: pointer;
  }
  .modal-topup-file{
    min-width: 1000px;
  }
  .style-line-title{
    left: 0px;
    height: 3px;
    background-color: #6dcdd0 !important;
  }
   .table tr#total{
  position: sticky;
    bottom: 0px;
    left: 0;
    background: #fff;
    z-index: 4;
}
.table tr#total{
  position: sticky;
}
th:nth-child(1):hover div::after {
  display: none;
}
.fix-data-footer{
    position: sticky;
    left:0px;
    background: #fff;
    z-index: 10;
  }
.fix-data-0 {
  position: sticky;
  left: 0px;
  top: 0px;
  background: #fff;
}
.fix-data-1 {
  position: sticky;
  left: 60px;
  top: 0px;
  background: #fff;
  min-width: 120px !important;
}

.fix-data-2 {
  position: sticky;
  left: 180px;
  top: 0px;
  background: #fff;
  min-width: 150px !important;
}

body {
  overflow: hidden;
}
.vs__dropdown-toggle {
  border: none;
}
.table {
  overflow: scroll;
  height: calc(100vh - 275px);
  display: block;
}

.form-check {
  display: flex;
  /* min-height: 1.5rem; */
  height: 30px;
  padding-left: 2.25rem;
  margin-bottom: 0.125rem;
  align-items: center;
}
.form-check-input {
  margin-right: 10px;
}


.table thead th:last-child {
  height: 60px !important;
  align-items: end;
  top: 20px;
}
.active__update__combobox {
  z-index: 5 !important;
}
.menu {
  left: 6px;
  top: 53px;
  position: relative;
}
td {
  text-align: start;
}
.menu-sub-dropdown {
  position: absolute;
}
.block {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  z-index:10 !important;
}
.loading-1 {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}
#styleSelect {
  background: #f9f9f9 !important;
  border: none !important;
  border-radius: 5px;
}
.v-select .vs__dropdown-toggle {
  border: none !important;
  border-radius: 5px;
  height: 40px;
  color: #4b5675;
}
#vs2__combobox {
  border: none !important;
  height: 40px;
  border-radius: 5px;
  color: #4b5675;
}

.container-xxl {
  max-width: 1200px;
}

.show-dropfilter {
  z-index: 107;
  position: absolute;
  inset: 61px 176px auto auto;
  margin: 0px;
  top: 90px;
  right: 20px;
  text-align: start;
}
.show-dropfilter-2 {
  z-index: 107;
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  top: 130px;
  left: 20px;
  text-align: start;
}
.calendar {
  display: none;
}
.style-chooser.vs__dropdown-menu {
  border: none;
}
.style-chooser.vs__dropdown-menu .vs__actions .vs__clear {
  display: none;
}
.vs__dropdown-toggle {
  width: 80px;
}
table td:first-child,
table th:first-child {
  min-width: 40px;
  padding-left: 18px !important;
}
/* .active__record, .active__record td:last-child,.active__record td:nth-child(3),.active__record td:nth-child(2),.active__record td:nth-child(1){
    background-color: #f9ecca !important;
}
table tr:hover td,table tbody tr:hover td:last-child,table tbody tr:hover td:nth-child(3),table tbody tr:hover td:nth-child(2),table tbody tr:hover td:nth-child(1){
    background-color: #fff8cb !important;
} */
#selected__toolbar {
  margin-right: 16px;
}
#selected__toolbar span {
  font-weight: 700;
}
#unchecked__toolbar {
  color: #e61d1d;
  margin-right: 16px;
  cursor: pointer;
}

@media only screen and (min-width: 1700px) {

.block {
    margin-left: 250px;
    
}
}
@media only screen and (min-width: 992px) {
  .block {
    margin-left: 250px;
}
}
@media only screen and (min-width: 200px) {

.block {
    margin-left: 0px;
    
}


}
</style>
  <script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "AdsAccount",

  data() {
    return {
      dataUser:{language:"VN"},
      listTopupFile:[],
      totalMoneyTopupFile:0,
      totalFeeTopupFile:0,
      countSuccessFileTopup:0,
      countFaileFileTopup:0,
      sttTopupfile: 1,
      channel_name: '',
      channel_fields:[],
      channel_entries:[],
      parse_header:[],
      parse_csv:[],
      sortOrders:{},
      sortkey:'',
      isShowModalTopUpFile:false,
      refundInvoice:{},
      totalSpencap:0,
      totalLimit:0,
      customerBatchInput:"",
      onShowModalAllCustomer:false,
      customerInput:"",
      currentAdsIdCustomer:0,
      onShowModalCustomer:false,
      adsCurrency:"VND",
      currentIndex: null,
      currentProperty: "",
      modeSort:"",
      sortingAsc: null,
      sortingNumber: null,
      totalStatusAdsPayRequired: 0,
      totalStatusAdsDisable: 0,
      totalStatusAdsUsing: 0,
      isAdmin:false,
      isResizing: false,
      initialX: 0,
      initialWidth: 0,
      resizingCol: null,
      columns: [

        { label: this.$NOLIMITResource["VN"].Note, width: 200, property: "Notes" },
        { label: this.$NOLIMITResource["VN"].AdsAccount.SystemStatus, width: 200, property: "ShareStatus" },
        { label: this.$NOLIMITResource["VN"].AdsAccount.LastTopup, width: 200, property: "LastTopupDate" },
        { label: this.$NOLIMITResource["VN"].CreatedDate, width: 200, property: "CreateDate" },
        {
          label: this.$NOLIMITResource["VN"].AdsAccount.SpenCap,
          width: 200,
          property: "SpendingCap",
        },
        // { label: "Số dư", width: 200, property: "RemainingAmount" },
        { label: this.$NOLIMITResource["VN"].AdsAccount.Customer, width: 200, property: "CustomerNow" },
        { label: this.$NOLIMITResource["VN"].AdsAccount.Supporter, width: 200, property: "Supporter" },
        { label: this.$NOLIMITResource["VN"].AdsAccount.ReturnDate, width: 200, property: "ReturnDate" },
        {
          label: this.$NOLIMITResource["VN"].AdsAccount.PaymentMethod,
          width: 200,
          property: "PaymentMethod",
        },
        { label: this.$NOLIMITResource["VN"].AdsAccount.Timezone, width: 200, property: "Timezone" },
        { label:  this.$NOLIMITResource["VN"].Currency, width: 200, property: "Currency" },
        { label: this.$NOLIMITResource["VN"].AdsAccount.Nation, width: 200, property: "Nationallity" },
        {
          label: this.$NOLIMITResource["VN"].AdsAccount.PartnerBusinessID,
          width: 200,
          property: "IdBusinessPartner",
        },
        {
          label: this.$NOLIMITResource["VN"].AdsAccount.DepositAmountToday,
          width: 200,
          property: "DailyAmountTopUp",
        },
        {
          label: this.$NOLIMITResource["VN"].AdsAccount.TotalAmountTopup,
          width: 200,
          property: "TotalAmountTopUp",
        },
        { label: this.$NOLIMITResource["VN"].AdsAccount.LastUpdate, width: 200, property: "LastUpdate" },
      ],
      columnsTopupFile: [
        { label: "STT", width: 200, property: "STT" },
        { label: "FacebookAdsId", width: 200, property: "FacebookAdsId" },
        { label: this.$NOLIMITResource["VN"].Amount, width: 200, property: "Amount" },
        { label: this.$NOLIMITResource["VN"].Currency, width: 200, property: "Currency" },
        {
          label: this.$NOLIMITResource["VN"].TopupFee,
          width: 200,
          property: "TopupFee",
        },
        // { label: "Số dư", width: 200, property: "RemainingAmount" },
        { label: this.$NOLIMITResource["VN"].AdsAccount.AmountFee, width: 200, property: "AmountFee" },
        { label: this.$NOLIMITResource["VN"].Status, width: 200, property: "Status" },
        { label: this.$NOLIMITResource["VN"].Note, width: 200, property: "Note" },
        { label: "", width: 20, property: "" },
      ],
      isCheckedAll: false,
      formModeTopup: "",
      numberPerPage: [10, 25, 50, 100],
      status: "",
      timezoneIndex: 20,
      timezoneIndex1: 1,
      timezones: [
        "Pacific/Midway", // -11
        "Pacific/Niue", // -11
        "Pacific/Honolulu", // -10
        "Pacific/Pago_Pago", // -11
        "America/Anchorage", // -9
        "America/Los_Angeles", // -8
        "America/Tijuana", // -8
        "America/Denver", // -7
        "America/Phoenix", // -7
        "America/Chicago", // -6
        "America/Mexico_City", // -6
        "America/Regina", // -6
        "America/New_York", // -5
        "America/Caracas", // -4.5
        "America/Halifax", // -4
        "America/Argentina/Buenos_Aires", // -3
        "America/Sao_Paulo", // -3
        "Atlantic/Azores", // -1
        "Atlantic/Cape_Verde", // -1
        "UTC", // 0
        "Europe/London", // +0
        "Europe/Paris", // +1
        "Europe/Istanbul", // +3
        "Europe/Moscow", // +3
        "Asia/Dubai", // +4
        "Asia/Yekaterinburg", // +5
        "Asia/Almaty", // +6
        "Asia/Colombo", // +6
        "Asia/Bangkok", // +7
        "Asia/Ho_Chi_Minh", // +7
        "Asia/Jakarta", // +7
        "Asia/Taipei", // +8
        "Asia/Singapore", // +8
        "Asia/Kuala_Lumpur", // +8
        "Asia/Hong_Kong", // +8
        "Asia/Ulaanbaatar", // +8
        "Asia/Pyongyang", // +9
        "Asia/Tokyo", // +9
        "Australia/Darwin", // +9.5
        "Australia/Adelaide", // +10
        "Australia/Sydney", // +10
        "Pacific/Guam", // +10
        "Australia/Brisbane", // +10
        "Asia/Vladivostok", // +10
        "Pacific/Fiji", // +12
        "Pacific/Tongatapu", // +13
        "Pacific/Apia", // +13
      ],
      dateStart: null,
      dateEnd: null,
      selectedColor: "blue",
      topUpOrder: {
        fee: 0,
        amountDisplay: 0,
      },
      idUser: 0,
      onLoading: true,
      onLoadingFile: false,
      ads: [],
      onShowMenu: false,
      currenOnshowId: 0,
      adsCurrent: {},
      adsAdd: {},
      currentBalanceUser: 0,
      currentTotalMoneyUser: 0,
      isShowModalUpdateAds: false,
      changeMoney: 0,
      formMode: "",
      pageSize: 10,
      pageNumber: 1,
      totalPage: 0,
      firstPage: 0,
      inputValue: "",
      currency: ["VND", "USD"],
      currentCurrency: "VND",
      currencyConvert: 0,
      topUpFee: 0,
      hasData: true,
      errors: {
        amount: "",
      },
      isValidate: false,
      onShowCalendarStart: false,
      selectRecord: false,
      recordActiveId: 0,
      countSelected: 0,
      onSelectAllRecord: false,
      checkboxChecked: [],
      adsAll: [],
      totalStatusUsing: 0,
      totalStatusReturn: 0,
      totalStatusNotUse: 0,
      totalStatusWaittingReturn: 0,
    };
  },
  updated() {
    if (this.onSelectAllRecord) {
      this.countSelected = this.adsAll.length;
    } else {
      this.countSelected = document.querySelectorAll(".count__record").length;
    }
  },
  computed: {
    filteredCsv() {
      this.reloadCSV()
      return this.parse_csv.filter(item => {
      return !(item.FacebookAdsId == null || item.FacebookAdsId.toString() == "" || item.Amount == "\r");
    });
    },
    ...mapGetters("listModule", [
      "onShowMenuFilter",
      "checkRow",
      "displayRow",
      "isShowModalPickRow",
      "formatCurrency",
      "formatDateTime",
      "convertToEndOfDay",
      "onShowMenuFilterStatus",
      "getStatusAds"
    ]),
    ...mapState('listModule', []),

    statusAds: {
      get() {
        return this.getStatusAds;
      },
      set(value) {
        this.setStatusAds(value);
      }
    },
    formattedFee() {
      return this.topUpOrder.fee + " VND";
    },
    formatAmountDisplay() {
      return this.formatCurrencyValue(this.topUpOrder.amountDisplay) + " VND";
    },
    timezone() {
      return this.timezones[this.timezoneIndex];
    },
    timezone1() {
      return this.timezones[this.timezoneIndex1];
    },
    attributes() {
      return [
        {
          highlight: true,
          dates: this.dateStart,
        },
      ];
    },
  },
  watch: {

    dataUser:{
      handler: function() {
        this.columns =  [

        { label: this.$NOLIMITResource[this.dataUser.language].Note, width: 200, property: "Notes" },
        { label:  this.$NOLIMITResource[this.dataUser.language].AdsAccount.SystemStatus, width: 200, property: "ShareStatus" },
        { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.LastTopup, width: 200, property: "LastTopupDate" },
        { label: this.$NOLIMITResource[this.dataUser.language].CreatedDate, width: 200, property: "CreateDate" },
        {
          label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.SpenCap,
          width: 200,
          property: "SpendingCap",
        },
        // { label: "Số dư", width: 200, property: "RemainingAmount" },
        { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.Customer, width: 200, property: "CustomerNow" },
        { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.Supporter, width: 200, property: "Supporter" },
        { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ReturnDate, width: 200, property: "ReturnDate" },
        {
          label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.PaymentMethod,
          width: 200,
          property: "PaymentMethod",
        },
        { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.Timezone, width: 200, property: "Timezone" },
        { label: this.$NOLIMITResource[this.dataUser.language].Currency, width: 200, property: "Currency" },
        { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.Nation, width: 200, property: "Nationallity" },
        {
          label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.PartnerBusinessID,
          width: 200,
          property: "IdBusinessPartner",
        },
        {
          label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.DepositAmountToday,
          width: 200,
          property: "DailyAmountTopUp",
        },
        {
          label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.TotalAmountTopup,
          width: 200,
          property: "TotalAmountTopUp",
        },
        { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.LastUpdate, width: 200, property: "LastUpdate" },
      ];
      this.columnsTopupFile = [
        { label: "STT", width: 200, property: "STT" },
        { label: "FacebookAdsId", width: 200, property: "FacebookAdsId" },
        { label: this.$NOLIMITResource[this.dataUser.language].Amount, width: 200, property: "Amount" },
        { label: this.$NOLIMITResource[this.dataUser.language].Currency, width: 200, property: "Currency" },
        {
          label: this.$NOLIMITResource[this.dataUser.language].TopupFee,
          width: 200,
          property: "TopupFee",
        },
        // { label: "Số dư", width: 200, property: "RemainingAmount" },
        { label: this.$NOLIMITResource[this.dataUser.language].AdsAccount.AmountFee, width: 200, property: "AmountFee" },
        { label: this.$NOLIMITResource[this.dataUser.language].Status, width: 200, property: "Status" },
        { label: this.$NOLIMITResource[this.dataUser.language].Note, width: 200, property: "Note" },
        { label: "", width: 20, property: "" },
      ];
      },
      deep: true 
    },
    ads(){
      this.totalSpencap = 0;
      this.totalLimit = 0
      for(let i = 0; i<this.ads.length;i++){
        if(this.ads[i].currency == "VND"){
          this.totalSpencap += this.ads[i].spendingCap
          this.totalLimit += this.ads[i].limit
        }else if(this.ads[i].currency == "USD"){
          this.totalSpencap += (this.ads[i].spendingCap * this.currencyConvert)
          this.totalLimit += (this.ads[i].limit * this.currencyConvert)
        }
      }
      
    },
    statusAds(newValue) {
      this.$store.commit('listModule/updateStatusAds', newValue);
    },
    pageSize() {
      this.reDisplay();
    },
    checkboxChecked() {},
    "topUpOrder.amount": function (newAmount) {
      if (this.adsCurrency == "VND") {
        if (!newAmount) {
          // Tính toán fee dựa trên 3% của newAmount
          this.topUpOrder.fee = 0; // Làm tròn đến 2 chữ số thập phân
          this.topUpOrder.amountDisplay = 0; // Làm tròn đến 2 chữ số thập phân
          // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
          // this.topUpOrder.fee = Math.round(newAmount * this.topUpFee);
        } else {
          const amountNumber = parseFloat(newAmount);
          // Tính toán fee dựa trên 3% của newAmount
          this.topUpOrder.fee = (newAmount * this.topUpFee) / 100; // Làm tròn đến 2 chữ số thập phân
          this.topUpOrder.amountDisplay = (
            amountNumber + parseFloat(this.topUpOrder.fee)
          ).toString(); // Làm tròn đến 2 chữ số thập phân
          // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
          // this.topUpOrder.fee = Math.round(newAmount * this.topUpFee);
        }
      } else {
        if (!newAmount) {
          // Tính toán fee dựa trên 3% của newAmount
          this.topUpOrder.fee = 0; // Làm tròn đến 2 chữ số thập phân
          this.topUpOrder.amountDisplay = 0; // Làm tròn đến 2 chữ số thập phân
          // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
          // this.topUpOrder.fee = Math.round(newAmount * this.topUpFee);
        } else {
          const amountNumber = parseFloat(newAmount);
          // Tính toán fee dựa trên 3% của newAmount
          this.topUpOrder.fee =
            ((newAmount * this.topUpFee) / 100) * this.currencyConvert; // Làm tròn đến 2 chữ số thập phân
          this.topUpOrder.amountDisplay = (
            amountNumber * this.currencyConvert +
            parseFloat(this.topUpOrder.fee)
          ).toString(); // Làm tròn đến 2 chữ số thập phân
          // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
          // this.topUpOrder.fee = Math.round(newAmount * this.topUpFee);
        }
      }
    },
    adsCurrency: function () {
      if (this.adsCurrency == "VND") {
        if (!this.topUpOrder.amount) {
          // Tính toán fee dựa trên 3% của newAmount
          this.topUpOrder.fee = 0; // Làm tròn đến 2 chữ số thập phân
          this.topUpOrder.amountDisplay = 0; // Làm tròn đến 2 chữ số thập phân
          // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
          // this.topUpOrder.fee = Math.round(newAmount * this.topUpFee);
        } else {
          const amountNumber = parseFloat(this.topUpOrder.amount);
          // Tính toán fee dựa trên 3% của newAmount
          this.topUpOrder.fee = (this.topUpOrder.amount * this.topUpFee) / 100; // Làm tròn đến 2 chữ số thập phân
          this.topUpOrder.amountDisplay = (
            amountNumber + parseFloat(this.topUpOrder.fee)
          ).toString(); // Làm tròn đến 2 chữ số thập phân
          // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
          // this.topUpOrder.fee = Math.round(newAmount * this.topUpFee);
        }
      } else {
        if (!this.topUpOrder.amount) {
          // Tính toán fee dựa trên 3% của newAmount
          this.topUpOrder.fee = 0; // Làm tròn đến 2 chữ số thập phân
          this.topUpOrder.amountDisplay = 0; // Làm tròn đến 2 chữ số thập phân
          // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
          // this.topUpOrder.fee = Math.round(newAmount * this.topUpFee);
        } else {
          const amountNumber = parseFloat(this.topUpOrder.amount);
          // Tính toán fee dựa trên 3% của newAmount
          this.topUpOrder.fee =
            ((this.topUpOrder.amount * this.topUpFee) / 100) *
            this.currencyConvert; // Làm tròn đến 2 chữ số thập phân
          this.topUpOrder.amountDisplay = (
            amountNumber * this.currencyConvert +
            parseFloat(this.topUpOrder.fee)
          ).toString(); // Làm tròn đến 2 chữ số thập phân
          // Nếu bạn muốn fee là số nguyên, bạn có thể sử dụng:
          // this.topUpOrder.fee = Math.round(newAmount * this.topUpFee);
        }
      }
    },
  },

async created() {
  const userProfile = await this.$apiService
      .getUserProfile();
      // this.dataUser = userProfile.data.data;
      if(userProfile.data.data.language == "VN" || userProfile.data.data.language == "EN"){
          this.dataUser = userProfile.data.data;
      }else{
          this.dataUser = userProfile.data.data;
          this.dataUser.language = "VN";
      }
      this.idUser = userProfile.data.data.userId;
        this.isAdmin = userProfile.data.data.isAdmin;
        await this.$apiService
          .getAllAdsByUser(
            this.pageNumber,
            this.pageSize,
            userProfile.data.data.userId
          )
          .then((res) => {
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
                if (res.data.data.length == 0) {
                  this.hasData = false;
                }
                this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
    await this.$apiService.getAllAdsByUserNoPaging(userProfile.data.data.userId)
    .then((res)=>{
      for (let i = 0; i < res.data.data.length; i++) {
        this.$apiService.getAllTopUpOrderByAdsIdNoPaging(res.data.data[i].adsId)
        .then(()=>{
        
        })
        
      }
    })      
    await this.$apiService
    .getDisplayPropertiesByUserId(userProfile.data.data.userId)
    .then((res) => {
      this.setDisPlayRow(res.data);
    });

    await this.$apiService
    .getAllAdsByUserNoPaging(userProfile.data.data.userId)
    .then((res) => {

      this.totalStatusUsing = 0;
      this.totalStatusReturn = 0;
      this.totalStatusWaittingReturn = 0;
      for (let i = 0; i < res.data.data.length; i++) {
        if (res.data.data[i].shareStatus == "Đang sử dụng") {
          this.totalStatusUsing += 1;
        } else if (res.data.data[i].shareStatus == "Đã thu hồi") {
          this.totalStatusReturn += 1;
        } else if (res.data.data[i].shareStatus == "Chờ thu hồi") {
          this.totalStatusWaittingReturn += 1;
        }  else {
          this.totalStatusNotUse += 1;
        }
      }
      this.adsAll = res.data.data;
    });

    await this.$apiService
      .getAllAdsByUserNoPaging(userProfile.data.data.userId)
      .then((res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].adsStatus == "1") {
            this.totalStatusAdsUsing += 1;
          } else if (res.data.data[i].statusAds == "2") {
            this.totalStatusAdsDisable += 1;
          } else if (res.data.data[i].adsStatus == "3") {
            this.totalStatusAdsPayRequired += 1;
          }
        }
        this.adsAll = res.data.data;
      });
      await this.$apiService.getSettingById(4).then((res) => {
      this.currencyConvert = res.data.value;
    });
  },
  methods: {
    ...mapMutations("listModule", ["setDisPlayRow","closeFilterStatus","onResetFilterStatusAds","closeMenuFilter"]),
    ...mapActions("listModule", ["toggleMenuFilter", "toggleModalDisplayRow","swalSuccess","swalError","swalWarning","toggleFilterStatus","setStatusAds"]),
    withPopper(dropdownList) {
      dropdownList.classList.add("style-chooser");
    },
    validate() {
      this.isValidate = true;
      this.errors = {
        amount: "",
      };
      if (!this.topUpOrder.amount) {
        this.errors.amount = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ValidateEmptyNumber;
        this.isValidate = false;
      } else if (!this.validAmount(this.topUpOrder.amount)) {
        this.errors.amount = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ValidateIsNotNumber;
        this.isValidate = false;
      } else if (this.topUpOrder.amount > 100000000000000000000 ){
        this.errors.amount = this.$NOLIMITResource[this.dataUser.language].AdsAccount.ValidateNumberTooLarge;
        this.isValidate = false;
      }
      return this.isValidate;
    },
    validAmount(amount) {
      var re = /^[0-9]+(\.[0-9]+)?$/;
      return re.test(amount);
    },
    openModalTopUpAds(id) {
      this.topUpOrder.amount = 0;
      this.formModeTopup = "TopUp";
     
      this.$apiService
        .GetAdByIdByUser(id)
        .then((res) => {
          if(res.data.data.shareStatus == "Đã thu hồi"){
            this.swalError({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorAdsRevoked, language: this.dataUser.language})
            this.isShowModalUpdateAds = false;
          }else{
            this.isShowModalUpdateAds = true;
          }
          this.adsCurrent = res.data.data;
          this.adsCurrency = res.data.data.currency;
          this.topUpFee = res.data.data.topupFee;
          if(res.data.data.topupFee == "" || res.data.data.topupFee == null || res.data.data.topupFee == 0){
            this.swalError({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorAdsNoTopupFee, language: this.dataUser.language});
            this.isShowModalUpdateAds = false;
          }
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
    },
    openAllModalTopUpAds() {
      this.formModeTopup = "ManyTopUp";
      this.isShowModalUpdateAds = true;
      this.adsCurrency = "VND";
    },
    closeModalUpdateAds() {
      this.isShowModalUpdateAds = false;
      this.onShowMenu = false;
    },
    addTopUpOrder() {
      if (this.validate()) {
        this.isShowModalUpdateAds = false;
        this.topUpOrder.adsId = this.currenOnshowId;
       
        this.topUpOrder.currency = this.adsCurrency; 
       
        this.$apiService
          .addTopUpOrder(this.topUpOrder)
          .then(() => {
            this.onShowMenu = false;
            this.reDisplay();
            this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessAddTopup)
          })
          .catch((error) => {
            this.swalError({message: error.response.data.message, language: this.dataUser.language})
          });
      } else {
        this.swalError({message:this.errors.amount, language: this.dataUser.language})
      }
    },
    addAllTopUpOrder() {
      if (this.validate()) {
        this.isShowModalUpdateAds = false;
        this.topUpOrder.adsId = this.currenOnshowId;
        this.$apiService
          .addManyTopUpOrder(
            this.checkboxChecked,
            this.topUpOrder.amount,
          )
          .then(() => {
            this.onShowMenu = false;
            this.reDisplay();
            this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessAddBatchTopup)
          })
          .catch((error) => {
            this.swalError({message:error.response.data.message, language: this.dataUser.language})
          });
      } else {
        this.swalError({message:this.errors.amount, language: this.dataUser.language})
      }
    },
    toggleMenuItem(id) {
      this.onShowMenu = !this.onShowMenu;
      this.currenOnshowId = id;
    },
    selectPage(index) {
      this.pageNumber = index;
      if (this.totalPage >= 4) {
        if (index == this.totalPage) {
          this.firstPage = index - 4;
        } else if (index >= 3) {
          this.firstPage = index - 3;
        } else {
          this.firstPage = 0;
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
        this.onFilterSearch();
      } else if (this.statusAds != "") {
        this.onFilterStatusAds();
      } else if (this.sortingNumber != null || this.sortingAsc != null) {
        this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
      } else {
        this.onEnterSearch();
      }
    },
    reDisplay() {
      this.hasData = true;
      this.$apiService
        .getAllAdsByUser(this.pageNumber, this.pageSize, this.idUser)
        .then((res) => {
          this.onLoading = true;
          setTimeout(() => {
            this.onLoading = false;
          }, 1000);
          this.ads = res.data.data;
          if (res.data.data.length == 0) {
            this.hasData = false;
          }
          this.totalPage = res.data.pageData.pageCount;
        })
        .catch((error) => {
          console.error("Lỗi khi lấy dữ liệu:", error);
        });
      this.$apiService.getAllAdsByUserNoPaging(this.idUser).then((res) => {
        this.totalStatusUsing = 0;
        this.totalStatusReturn = 0;
        this.totalStatusWaittingReturn = 0;
        for (let i = 0; i < res.data.data.length; i++) {
          if (res.data.data[i].shareStatus == "Đang sử dụng") {
            this.totalStatusUsing += 1;
          } else if (res.data.data[i].shareStatus == "Đã thu hồi") {
            this.totalStatusReturn += 1;
          } else if (res.data.data[i].shareStatus == "Chờ thu hồi") {
            this.totalStatusWaittingReturn += 1;
          } else {
            this.totalStatusNotUse += 1;
          }
        }
        this.adsAll = res.data.data;
      });
      this.unSelectAllRecord();
    },
    /**
     * Chọn next trang tiếp theo trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    nextPage() {
      if (this.pageNumber != this.totalPage) {
        this.pageNumber += 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
        this.onFilterSearch();
      } else if (this.statusAds != "") {
        this.onFilterStatusAds();
      } else if (this.sortingNumber != null || this.sortingAsc != null) {
        this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
      } else {
        this.onEnterSearch();
      }
    },
    /**
     * Chọn prev trang trước đó trong mục phân trang
     * Author: ndanh (20/09/2023)
     */
    prevPage() {
      if (this.pageNumber != 1) {
        this.pageNumber -= 1;
        if (this.totalPage >= 4) {
          if (this.pageNumber == this.totalPage) {
            this.firstPage = this.pageNumber - 4;
          } else if (this.pageNumber >= 3) {
            this.firstPage = this.pageNumber - 3;
          } else {
            this.firstPage = 0;
          }
        }
      }
      if (this.status != "" || this.dateStart != null || this.dateEnd != null) {
        this.onFilterSearch();
      } else if (this.statusAds != "") {
        this.onFilterStatusAds();
      } else if (this.sortingNumber != null || this.sortingAsc != null) {
        this.onSorting(this.currentIndex, this.currentProperty, "selectPage");
      } else {
        this.onEnterSearch();
      }
    },
    onEnterSearch() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.$apiService
          .getAllAdsByUserFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        this.$apiService
          .getAllAdsByUserFilterStatusNoPaging(this.idUser,this.status, this.inputValue)
          .then((res) => {
            this.adsAll = res.data.data;
          });
      }
    },
    onEnterSearchFirst() {
      this.hasData = true;
      if (this.inputValue == "") {
        this.reDisplay();
      } else {
        this.pageNumber = 1;
        this.firstPage = 0;
        this.$apiService
          .getAllAdsByUserFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
        this.$apiService
          .getAllAdsByUserFilterStatusNoPaging(this.idUser, this.status, this.inputValue)
          .then((res) => {
            this.adsAll = res.data.data;
          });
      }
    },
    onFilterSearch() {
      this.hasData = true;
      if (
        (this.dateStart == null ||
          this.dateEnd == null ||
          this.dateEnd < this.dateStart) &&
        this.status == "" && this.inputValue == ""
      ) {
        this.reDisplay();
      } else if (
        this.dateStart == null ||
        this.dateEnd == null ||
        this.dateEnd < this.dateStart
      ) {
        this.$apiService
          .getAllAdsByUserFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          });
        this.$apiService
          .getAllAdsByUserFilterStatusNoPaging(this.idUser, this.status,this.inputValue)
          .then((res) => {
            this.adsAll = res.data.data;
          });
      } else if (this.status == "") {
        const dateEndConvert = this.convertToEndOfDay(this.dateEnd);
        this.$apiService
          .getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            "",
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          });
        this.$apiService
          .getAllAdsByUserFilterNoPaging(
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
          )
          .then((res) => {
            this.adsAll = res.data.data;
          });
      } else if(this.inputValue == ""){
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          this.$apiService.getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            "",
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          this.$apiService.getAllAdsByUserFilterNoPaging(
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,).then((res)=>{
              this.adsAll = res.data.data;
            })
        }else{
        const dateEndConvert = this.convertToEndOfDay(this.dateEnd);
        this.$apiService
          .getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          });
        this.$apiService
          .getAllAdsByUserFilterNoPaging(
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
          )
          .then((res) => {
            this.adsAll = res.data.data;
          });
      }
    
      this.onShowMenuFilter = false;
    },

    onFilterSearchFirst() {
      this.hasData = true;
      if (
        (this.dateStart == null ||
          this.dateEnd == null ||
          this.dateEnd < this.dateStart) &&
        this.status == "" && this.inputValue == ""
      ) {
        this.reDisplay();
      } else { 
        this.pageNumber = 1;
        this.firstPage = 0;
        if (
        this.dateStart == null ||
        this.dateEnd == null ||
        this.dateEnd < this.dateStart
      ) {
        this.$apiService
          .getAllAdsByUserFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          });
        this.$apiService
          .getAllAdsByUserFilterStatusNoPaging(this.idUser, this.status,this.inputValue)
          .then((res) => {
            this.adsAll = res.data.data;
          });
      } else if (this.status == "") {
        const dateEndConvert = this.convertToEndOfDay(this.dateEnd);
        this.$apiService
          .getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            "",
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          });
        this.$apiService
          .getAllAdsByUserFilterNoPaging(
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
          )
          .then((res) => {
            this.adsAll = res.data.data;
          });
      } else if(this.inputValue == ""){
          const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
          this.$apiService.getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            "",
            this.currentProperty,
            this.modeSort,
          ).then((res)=>{
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if(res.data.data.length == 0){
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          this.$apiService.getAllAdsByUserFilterNoPaging(
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,).then((res)=>{
              this.adsAll = res.data.data;
            })
        }else{
        const dateEndConvert = this.convertToEndOfDay(this.dateEnd);
        this.$apiService
          .getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            this.currentProperty,
            this.modeSort,
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          });
        this.$apiService
          .getAllAdsByUserFilterNoPaging(
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
          )
          .then((res) => {
            this.adsAll = res.data.data;
          });
      }
    }
      this.onShowMenuFilter = false;
    },

    /**
     * Chọn một bản ghi theo checkbox
     * Author: ndanh (20/12/2023)
     * @param {int} id là id của bản ghi được chọn theo checkbox
     * @returns {int} trả về id đang được active
     */
    selectedRecord(id) {
      this.recordActiveId = id;
      this.selectRecord = true;
      return this.recordActiveId;
    },
    /**
     * Chọn tất cả bản ghi theo checkbox
     * Author: ndanh (20/12/2023)
     */
    selectAllRecord() {
      if (this.onSelectAllRecord == false) {
        this.onSelectAllRecord = true;
        this.isCheckedAll = true;
        this.checkboxChecked = [];
        if (this.onSelectAllRecord) {
          for (let i = 0; i < this.adsAll.length; i++) {
            this.checkboxChecked.push(this.adsAll[i].adsId);
          }
        }
      } else {
        this.isCheckedAll = false;
        this.selectRecord = false;
        this.onSelectAllRecord = false;
        this.checkboxChecked = [];
      }
    },
    /**
     * Bỏ chọn tất cả bản ghi theo checkbox
     * Author: ndanh (20/12/2023)
     */
    unSelectAllRecord() {
      this.isCheckedAll = false;
      this.selectRecord = false;
      this.onSelectAllRecord = false;
      this.checkboxChecked = [];
    },
    /**
     * Kiểm trả xem id này có đang được checkbox checked hay không
     * Author: ndanh (20/12/2023)
     * @param {int} id là id được truyền vào để kiểm tra
     * @return {boolean} giá trị trả về true nếu id đang được checked
     */
    isChecked(id) {
      return this.checkboxChecked.includes(id);
    },
    onResetFilter() {
      this.status = "";
      this.dateStart = null;
      this.dateEnd = null;
    },

    choosePageSize(event) {
      this.pageSize = event.target.value;
      this.reDisplay();
    },
    onSubmitModalPickRow() {
      this.setDisPlayRow(this.checkRow);
      this.toggleModalDisplayRow();
      this.$apiService
        .updateDisplayPropertiesByUserId(this.idUser, this.displayRow)
        .then(() => {
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessPickColumns)
        });
    },
    startResize(event, index) {
      if (event.offsetX > event.target.offsetWidth - 10) {
        this.isResizing = true;
        this.initialX = event.clientX;
        this.initialWidth = this.columns[index].width;
        this.resizingCol = index;

        document.addEventListener("mousemove", this.handleMouseMove);
        document.addEventListener("mouseup", this.handleMouseUp);
      }
    },
    handleMouseMove(event) {
      if (this.isResizing && this.resizingCol !== null) {
        const delta = event.clientX - this.initialX;
        const newWidth = Math.max(50, this.initialWidth + delta);
        this.columns[this.resizingCol] = {
          ...this.columns[this.resizingCol],
          width: newWidth,
        };
      }
    },
    handleMouseUp() {
      this.isResizing = false;
      this.initialX = 0;
      this.initialWidth = 0;
      this.resizingCol = null;

      document.removeEventListener("mousemove", this.handleMouseMove);
      document.removeEventListener("mouseup", this.handleMouseUp);
    },
    shouldDisplay(index) {
      switch (index) {
        case 0:
          return this.displayRow.notes;
        case 1:
          return this.displayRow.shareStatus;
        case 2:
          return this.displayRow.lastTopupDate;
        case 3:
          return this.displayRow.createDate;
        case 4:
          return this.displayRow.spendingCap;
        // case 5:
        //   return this.displayRow.remainingAmount;
        case 5:
          return this.displayRow.customerNow;
        case 6:
          return this.displayRow.supporter;
        case 7:
          return this.displayRow.returnDate;
        case 8:
          return this.displayRow.paymentMethod;
        case 9:
          return this.displayRow.timezone;
        case 10:
          return this.displayRow.currency;
        case 11:
          return this.displayRow.nationallity;
        case 12:
          return this.displayRow.idBusinessPartner;
        case 13:
          return this.displayRow.dailyAmountTopUp;
        case 14:
          return this.displayRow.totalAmountTopUp;
        case 15:
          return this.displayRow.lastUpdate;
        // Thêm các trường hợp khác tương ứng
        default:
          return true; // Hiển thị mặc định nếu không có điều kiện nào khớp
      }
    },
    onFilterStatusAds() {
      this.hasData = true;
      if (this.statusAds == "") {
        this.reDisplay();
      } else {
        this.$apiService
          .getAllAdsByUserFilterStatusAds(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.statusAds
          )
          .then((res) => {
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          });

        this.$apiService
          .getAllAdsByUserFilterStatusAdsNoPaging(this.idUser, this.statusAds)
          .then((res) => {
            this.adsAll = res.data.data;
          });
      }
      this.closeFilterStatus();
    },
    onSorting(index, property, mode) {
      this.currentIndex = index;
      this.currentProperty = property;
      if(this.dateStart == null || this.dateEnd == null || this.dateEnd < this.dateStart){

      if (this.sortingNumber == index && mode == "changeSort") {
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getAllAdsByUserFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingNumber == index && mode == "selectPage") {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAllAdsByUserFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "changeSort") {
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAllAdsByUserFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "selectPage") {
        this.sortingAsc = null;
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
          .getAllAdsByUserFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
          .getAllAdsByUserFilterStatus(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }

      this.$apiService.getAllAdsByUserFilterStatusNoPaging(
            this.idUser,
            this.status,
            this.inputValue,
          ).then((res)=>{
              this.adsAll = res.data.data;
            })
    }else{
      const dateEndConvert = this.convertToEndOfDay(this.dateEnd)
      if (this.sortingNumber == index && mode == "changeSort") {
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
        .getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingNumber == index && mode == "selectPage") {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
        .getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "changeSort") {
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
        .getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      } else if (this.sortingAsc == index && mode == "selectPage") {
        this.sortingAsc = null;
        this.sortingNumber = null;
        this.sortingAsc = index;
        this.$apiService
        .getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "ASC"
          )
          .then((res) => {
            this.modeSort = "ASC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });


      } else {
        this.sortingNumber = null;
        this.sortingAsc = null;
        this.sortingNumber = index;
        this.$apiService
        .getAllAdsByUserFilter(
            this.pageNumber,
            this.pageSize,
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,
            property,
            "DESC"
          )
          .then((res) => {
            this.modeSort = "DESC";
            this.onLoading = true;
            setTimeout(() => {
              this.onLoading = false;
            }, 1000);
            this.ads = res.data.data;
            if (res.data.data.length == 0) {
              this.hasData = false;
            }
            this.totalPage = res.data.pageData.pageCount;
          })
          .catch((error) => {
            console.error("Lỗi khi lấy dữ liệu:", error);
          });
      }
      this.$apiService.getAllAdsByUserFilterNoPaging(
            this.idUser,
            this.status,
            this.dateStart.toISOString(),
            dateEndConvert,
            this.inputValue,).then((res)=>{
              this.adsAll = res.data.data;
            })



    }
    },
     confirmReturnAccount(idAds,idUser){
      this.$apiService.GetAdByIdByUser(idAds)
      .then((res)=>{
           if(res.data.data.shareStatus == "Đã thu hồi"){
            this.swalError({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorAdsRevokedCantRevoke, language: this.dataUser.language})
           }else if(res.data.data.shareStatus == "Chờ thu hồi"){
            this.swalError({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorAdsWaitingRevoke, language: this.dataUser.language})
           }else{
             this.swalWarning({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.RequestReturnMessage, language: this.dataUser.language}).then((result)=>{
              if (result.isConfirmed) {
              this.requestReturnAccount(idAds,idUser)
              } else {
                // Thực hiện công việc khi người dùng hủy bỏ
              }
            });
          
           }
           
      })
      
    },
    async confirmRefund(adsId){
      const result = await this.swalWarning({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ConfirmRefund, language: this.dataUser.language});
      if (result.isConfirmed) {
        this.refund(adsId)
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
    },

    refund(adsId){
      this.refundInvoice.adsId = adsId;
      this.$apiService.addWithdraw(this.refundInvoice).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessCreateRequestRefund)
      }).catch((res)=>{
        this.swalError({message:res.response.data.message,language: this.dataUser.language})
      })
    },
    requestReturnAccount(idAds,idUser){
      const notification = {
        message: "Yêu cầu thu hồi tài khoản ads "+idAds + " từ user " + idUser,
        messageEn: "Request to recall ads account "+idAds + " from user " + idUser,
      }
      this.$apiService.addNotificationToAdmin(notification).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessSendRequestRefund)
        this.$apiService.getAdsById(idAds).then((res)=>{
          res.data.data.shareStatus = "Chờ thu hồi";
          this.$apiService.updateAds(res.data.data).then(()=>{
            this.reDisplay()
          })
        })
      })
    },
    closeMenuItem(){
      this.onShowMenu = false;
    },
    copyText(text) {
      const textToCopy = text;
      navigator.clipboard.writeText(textToCopy)
        .then(() => {
    
          // You can also add a notification or any other feedback here
        })
        .catch(err => {
          console.error('Failed to copy text: ', err);
          // Handle error if copying fails
        });
    },
    formatCurrencyValue(value) {
      // Chuyển đổi số thành chuỗi
      let stringValue = value.toString();

      // Chia chuỗi thành mảng các nhóm mỗi 3 chữ số
      let parts = stringValue.split('.');
      let mainPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');

      // Nếu có phần thập phân, thêm vào chuỗi
      let result = parts.length > 1 ? mainPart + ',' + parts[1] : mainPart;

      return result;
    },
    openUpdateCustomer(id){
      this.currentAdsIdCustomer = id;
      this.onShowModalCustomer = true;
      this.$apiService.getAdsById(id).then((res)=>{
          this.customerInput = res.data.data.customerNow;
      })
    },
    closeUpdateCustomer(){
      this.onShowModalCustomer = false;
    },
    updateCustomer(){  
      this.onShowModalCustomer = false;
      this.$apiService.updateAdsCustomer(this.currentAdsIdCustomer,this.customerInput).then(()=>{
        this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessCustomization);
        this.reDisplay();
      }).catch((error)=>{
          this.swalError({message:error.response.data.message,language: this.dataUser.language})
        })
    },
    openModalUpdateAllCustomer(){
      this.onShowModalAllCustomer = true
    },
    closeModalUpdateAllCustomer(){
      this.onShowModalAllCustomer = false
    },

    updateBatchCustomer(){
        this.onShowModalAllCustomer = false;
        this.$apiService
          .updateBatchAdsCustomer(
            this.checkboxChecked,
            this.customerBatchInput
          )
          .then(() => {
            this.reDisplay();
            this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessCustomizationAds)
          })
          .catch((error) => {
            this.swalError({message:error.response.data.message,language: this.dataUser.language})
          });
    },
    async confirmUpdateAllAdsStatus(){
      const result = await this.swalWarning({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ConfirmUpdateAllAds, language: this.dataUser.language});
      if (result.isConfirmed) {
        this.updateAllAdsStatus()
      } else {
        // Thực hiện công việc khi người dùng hủy bỏ
      }
    },
    updateAllAdsStatus(){
      this.$apiService.updateStatusManyAdsByAdsId(this.checkboxChecked,"0")
      .then(()=>{
        this.reDisplay();
          this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessRequestUpdateAd)
      }).catch((error) => {
          this.swalError({message:error.response.data.message,language: this.dataUser.language})
        });
    },
    exportCSV(){
      let data = [];
      for (let i = 0; i < this.ads.length; i++){
        let adsStatus;
        switch (this.ads[i].adsStatus) {
            case '0':
              adsStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusUpdateting;
                break;
            case '1':
              adsStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWork;
                break;
            case '3':
              adsStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusPaymentRequired;
                break;
            case '2':
              adsStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusDisable;
              break;
            default:
              adsStatus = this.$NOLIMITResource[this.dataUser.language].NotDetermined;
              break;
        }

        let shareStatus;
        switch (this.ads[i].shareStatus) {
            case 'Đã thu hồi':
              shareStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusRecalled;
                break;
            case 'Chưa sử dụng':
              shareStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.NotYetUsed;
                break;
            case 'Chờ thu hồi':
              shareStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusWaitingForRecall;
                break;
            case 'Đang sử dụng':
              shareStatus = this.$NOLIMITResource[this.dataUser.language].AdsAccount.StatusInUse;
              break;
            default:
              shareStatus = this.$NOLIMITResource[this.dataUser.language].NotDetermined;
              break;
        }
        let paymentMethod = "";
        if(this.ads[i].paymentMethod != null){
          paymentMethod = this.ads[i].paymentMethod.replace(/\n/g, '');
        }
        let item = {};
          if(this.dataUser.language == "EN"){
             item = {
            'Status': adsStatus,
            'NameAds': this.ads[i].name,
            'FACEBOOKADSID': this.ads[i].facebookAdsId,
            'Note' : this.ads[i].notes,
            'System Status': shareStatus,
            'LastTopup' : this.formatDateTime(this.ads[i].lastTopupDate),
            'Created Date' : this.formatDateTime(this.ads[i].createDate),
            'SpenCap' : this.ads[i].spendingCap,
            'Customer' : this.ads[i].customerNow, 
            'ReturnDate' : this.formatDateTime(this.ads[i].returnDate),
            'Payment Method' : paymentMethod,
            'Timezone' : this.ads[i].timezone,
            'Currency' : this.ads[i].currency,
            'Nation' : this.ads[i].nationallity,
            'Partner Business ID' : this.ads[i].idBusinessPartner,
            'Deposit Amount Today' : this.ads[i].dailyAmountTopUp,
            'Total Amount Topup' : this.ads[i].totalAmountTopUp,
            'Last Update': this.formatDateTime(this.ads[i].lastUpdate),
          }
          }else{
             item = {
            'TRẠNG THÁI': adsStatus,
            'TÊN TÀI KHOẢN': this.ads[i].name,
            'FACEBOOKADSID': this.ads[i].facebookAdsId,
            'GHI CHÚ' : this.ads[i].notes,
            'TRẠNG THÁI Ở HỆ THỐNG': shareStatus,
            'TOPUP GẦN NHẤT' : this.formatDateTime(this.ads[i].lastTopupDate),
            'NGÀY TẠO' : this.formatDateTime(this.ads[i].createDate),
            'GIỚI HẠN CHI TIÊU CÒN LẠI' : this.ads[i].spendingCap,
            'KHÁCH HÀNG' : this.ads[i].customerNow,
            'NGÀY THU HỒI' : this.formatDateTime(this.ads[i].returnDate),
            'PHƯƠNG THỨC THANH TOÁN' : paymentMethod,
            'MÚI GIỜ' : this.ads[i].timezone,
            'ĐƠN VỊ TIỀN TỆ' : this.ads[i].currency,
            'QUỐC GIA' : this.ads[i].nationallity,
            'ID DOANH NGHIỆP ĐỐI TÁC' : this.ads[i].idBusinessPartner,
            'SỐ TIỀN NẠP TRONG NGÀY' : this.ads[i].dailyAmountTopUp,
            'TỔNG SỐ TIỀN ĐÃ TOPUP' : this.ads[i].totalAmountTopUp,
            'NGÀY CẬP NHẬT GẦN NHẤT': this.formatDateTime(this.ads[i].lastUpdate),
          }
          }
        

          data.push(item);
      }
      const csvContent1 = this.convertToCSV(data);
      var BOM = "\uFEFF";
      var csvContent = BOM + csvContent1;
      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download','export_ads.csv');
      link.click();
    },
    convertToCSV(data){
        const headers = Object.keys(data[0]);
        const rows = data.map(obj => headers.map(header => obj[header]));
        const headerRow = headers.join(',');
        const csvRows = [headerRow, ...rows.map(row=> row.join(','))];
        return csvRows.join('\n');
    },
    openAllModalTopUpFile(){
      this.isShowModalTopUpFile = true;
    },
    closeAllModalTopUpFile(){
      this.isShowModalTopUpFile = false;
    },

    sortBy:function(key){
      var vm = this;
      vm.sortkey = key;
      vm.sortOrders[key] = vm.sortOrders[key] * -1
    },
   csvJSON(csv){
    var vm = this;
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",").map(header => header.trim().replace(/\r/g, ''));
    vm.parse_header = headers
    lines[0].split(",").forEach(function (key){
      vm.sortOrders[key] = 1
    })  
    lines.map(function(line, indexLine){
        if(indexLine<1) return
        var obj = {}
        var currentline = line.split(",")
        headers.map(function(header,indexHeader){
          obj[header] = currentline[indexHeader]
        })
          result.push(obj)
    })
    //  result.pop()
    vm.parse_csv = result;
    this.fetchAdCurrency();
    return result
   },

   loadCSV(e){
    this.parse_csv = [];
    this.countSuccessFileTopup = 0;
    this.countFaileFileTopup = 0;
    this.totalFeeTopupFile = 0;
    this.totalMoneyTopupFile = 0;
    this.listTopupFile = [];  // Reset the list of top-ups
    var vm = this
    if(window.FileReader) {
      var reader = new FileReader();
      reader.readAsText(e.target.files[0]);
      reader.onload = function(event) {
        var csv = event.target.result;
        vm.parse_csv = vm.csvJSON(csv);
   
        document.getElementById('csv_file').value = "";
      };
      reader.onerror = function(evt){
        if(evt.target.error.name == "NotReadableError" ){
          this.swalError({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorCantReadThisFile, language: this.dataUser.language})
        }
      };

    }else{
      this.swalError({ message: this.$NOLIMITResource[this.dataUser.language].AdsAccount.ErrorBrowserReadFile, language: this.dataUser.language})
    }
   },
    fetchAdCurrency() {
      this.countSuccessFileTopup = 0;
      this.countFaileFileTopup = 0;
      this.totalFeeTopupFile = 0;
      this.totalMoneyTopupFile = 0;
    for (let i = 0; i < this.parse_csv.length; i++) {
    

      let item = this.parse_csv[i];
      if (item.FacebookAdsId == null || item.FacebookAdsId.toString() == "" || item.Amount == "\r") {
        continue;
      }

       this.$apiService.getAdByUserIdAndFacebookAdsId(item.FacebookAdsId.replace(/[^a-zA-Z0-9]/g, '')).then((res)=>{
        this.parse_csv[i].currency = res.data.data.currency;
        this.parse_csv[i].fee = res.data.data.topupFee;
        let amount = this.parse_csv[i].Amount;

        this.parse_csv[i].feeAmount = (res.data.data.topupFee/100) * parseInt(amount.replace(/\r/, '').trim());
        if(res.data.data.adsStatus == "0" || res.data.data.currency == null){
          this.parse_csv[i].status = "Thất bại"
          this.parse_csv[i].note = "Tài khoản chưa cập nhật không thể topup"
          this.countFaileFileTopup +=1;
        }else
        if(res.data.data.shareStatus == "Đã thu hồi"){
          this.parse_csv[i].status = "Thất bại"
          this.parse_csv[i].note = "Không thể topup tài khoản đã thu hồi"
          this.countFaileFileTopup +=1;
        }else
        if(res.data.data.shareStatus == "Chưa sử dụng"){
          this.parse_csv[i].status = "Thất bại"
          this.parse_csv[i].note = "Không thể topup tài khoản chưa sử dụng"
          this.countFaileFileTopup +=1;
        }else
        if(res.data.data.topupFee == null || res.data.data.topupFee == "" || res.data.data.topupFee == 0){
          this.parse_csv[i].status = "Thất bại"
          this.parse_csv[i].note = "Chưa có phí topup"
          this.countFaileFileTopup +=1;
        }else{
          this.parse_csv[i].status = "Thành công"
          this.countSuccessFileTopup +=1;
          if(res.data.data.currency == "USD"){
            this.totalMoneyTopupFile += (parseInt(amount.replace(/\r/, '').trim()) * this.currencyConvert);
            this.totalFeeTopupFile += (this.parse_csv[i].feeAmount * this.currencyConvert);
          }else{
            this.totalMoneyTopupFile += parseInt(amount.replace(/\r/, '').trim());
            this.totalFeeTopupFile += this.parse_csv[i].feeAmount;
          }
          let topup = {
            AdsId : res.data.data.adsId,
            Amount : parseInt(amount.replace(/\r/, '').trim()),
            Fee : this.parse_csv[i].feeAmount,
          }
          this.listTopupFile.push(topup);
        }
        
       }).catch(() => {
          this.parse_csv[i].status = "Thất bại"
          this.parse_csv[i].note = "Không tìm thấy id này"
    
          this.countFaileFileTopup +=1
        });
        
    }
   
  },
  addBatchTopUpOrdersFile(){

        this.$apiService
          .addBatchTopUpOrdersFile(
            this.listTopupFile,
            this.totalMoneyTopupFile + this.totalFeeTopupFile
          )
          .then(() => {
            this.onShowMenu = false;
            this.reDisplay();
            this.swalSuccess(this.$NOLIMITResource[this.dataUser.language].AdsAccount.SuccessAddBatchTopup)
          })
          .catch((error) => {
            this.swalError({message: error.response.data.message,language: this.dataUser.language})
          });
      
  },

  exportCSVFormTopupFile(){
      let data = [];
          const item = {
            'FacebookAdsId': "'"+211358008629744,
            'Amount': 1,
          }

          data.push(item);
          const item2 = {
            'FacebookAdsId': "'"+241230076292058,
            'Amount': 1,
          }

          data.push(item2);
      
      const csvContent1 = this.convertToCSVFormTopupFile(data);
      var BOM = "\uFEFF";
      var csvContent = BOM + csvContent1;
      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'});
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download','form_topupfile.csv');
      link.click();
    },
    convertToCSVFormTopupFile(data){
        const headers = Object.keys(data[0]);
        const rows = data.map(obj => headers.map(header => obj[header]));
        const headerRow = headers.join(',');
        const csvRows = [headerRow, ...rows.map(row=> row.join(','))];
        return csvRows.join('\n');
    },
    reloadCSV(){
      this.onLoadingFile = true;
          setTimeout(() => {
            this.onLoadingFile = false;
          }, 1000);
    }
  },
};
</script>
  